export enum Color {
	Primary = '#1D4A74',
	Secondary = '#C3A65A',
	Success = '#98BB4C',
	Info = '#007D93',
	Danger = '#C8102F',
	Warning = '#C99403',
	Dark = '#1A1A1A',
	Light = '#00ADB5',
	Muted = '#00ADB5',
	Border = '#00ADB5',
	Inverse = '#00ADB5',
	Shaft = '#00ADB5',
	Background = '#00ADB5',
	PrimaryGold = '#C3A65A',

	White = '#FFFFFF',
	Black = '#000000',
	Gray = '#f5f5f5',

	ComyBlue = '#9dcbec'
}
