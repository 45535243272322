import React, { useState } from 'react';
import { IconButton, Tooltip, ToggleButton, Box } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

interface EditOrConfirmButtonsProps {
	isConfirmed?: boolean;
	onEdit?: () => void;
	onConfirm?: () => void;
	onCancel?: () => void;
	onUpdate?: () => void;
}

const EditOrConfirmButtons: React.FC<EditOrConfirmButtonsProps> = ({
	isConfirmed,
	onEdit,
	onConfirm,
	onCancel,
	onUpdate
}) => {
	const [isEditing, setIsEditing] = useState<boolean>(false);
	return (
		<Box>
			{isEditing && !isConfirmed && (
				<>
					<Tooltip id="tooltipUpdateChanges" title="Update Changes" placement="top">
						<IconButton
							id="updateChangesButton"
							color="info"
							sx={{ border: '1px solid #ccc' }}
							size="small"
							onClick={() => {
								if (onUpdate !== undefined) onUpdate();
								setIsEditing(false);
							}}
						>
							<CheckIcon />
						</IconButton>
					</Tooltip>
					<Tooltip id="tooltipCancelEdit" title="Cancel Edit" placement="top">
						<IconButton
							id="cancelEditButton"
							color="error"
							sx={{ border: '1px solid #ccc', marginLeft: 1 }}
							size="small"
							onClick={() => {
								if (onCancel !== undefined) onCancel();
								setIsEditing(false);
							}}
						>
							<CloseIcon />
						</IconButton>
					</Tooltip>
				</>
			)}
			{!isConfirmed && onEdit && !isEditing && (
				<Tooltip id="tooltipEdit" title="Edit" placement="top">
					<IconButton
						id="editButton"
						color="secondary"
						sx={{ border: '1px solid #ccc' }}
						size="small"
						onClick={() => {
							if (onEdit !== undefined) onEdit();
							setIsEditing(true);
						}}
					>
						<EditOutlinedIcon />
					</IconButton>
				</Tooltip>
			)}
			{!isEditing && (
				<Tooltip id="tooltipConfirm" title={isConfirmed ? 'Confirmed' : 'Confirm'} placement="top">
					<ToggleButton
						id="confirmButton"
						value="check"
						selected={isConfirmed}
						onChange={onConfirm}
						color="success"
						sx={{
							border: '1px solid #ccc',
							marginLeft: 1,
							borderRadius: '45px'
						}}
						size="small"
					>
						<TaskAltIcon />
					</ToggleButton>
				</Tooltip>
			)}
		</Box>
	);
};

export default EditOrConfirmButtons;
