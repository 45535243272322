import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Amplify } from '@aws-amplify/core';
import { useAuthenticator } from '@aws-amplify/ui-react';
import awsmobile from 'src/aws-exports';
import '@aws-amplify/ui-react/styles.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import DefaultLayout from 'src/layouts/DefaultLayout';
import { ApiNoSqlProvider } from 'src/modules/contexts/ApiNoSqlContext';
import { ApiSqlProvider } from 'src/modules/contexts/ApiSqlContext';

Amplify.configure(awsmobile);

const App = () => {
	const { user } = useAuthenticator(context => [context.user]);
	const token = user.getSignInUserSession()?.getIdToken().getJwtToken();
	const nosql_api = process.env.REACT_APP_URL_IDP_API
	const sql_api = process.env.REACT_APP_URL_REPORTING_API
	const pool_id = awsmobile.aws_user_pools_id
	const client_id = awsmobile.aws_user_pools_web_client_id
	// const nosql_api = 'http://localhost:5000'
	// const sql_api = 'https://pcq4e0cc7a.execute-api.ca-central-1.amazonaws.com/Develop'
	// console.log('REACT_APP_URL_IDP_API', nosql_api);
	// console.log('REACT_APP_URL_REPORTING_API', sql_api);
	// update token when it refreshes
	useEffect(() => {
		if (!token) {
			console.warn(`No token found: ${token}`)
		}
	}, [token]);

	return (
		<ApiNoSqlProvider config={{ 
				BASE: nosql_api, 
				HEADERS: { 
					'pool_id': pool_id,
					'client_id': client_id,
					'id_token': token || ''
				} 
			}}>
			<ApiSqlProvider config={{ BASE: sql_api }}>
				<BrowserRouter>
					{/* Wrap the entire application in QueryClientProvider and provide the queryClient */}
					<QueryClientProvider
						client={
							new QueryClient({
								defaultOptions: {
									queries: {
										refetchOnWindowFocus: false,
										retry: false
									}
								}
							})
						}
					>
						{/* <Authenticator loginMechanisms={['email']} components={components}> */}
							<DefaultLayout />
						{/* </Authenticator> */}
					</QueryClientProvider>
				</BrowserRouter>
			</ApiSqlProvider>
		</ApiNoSqlProvider>
	);
};

export default App;
