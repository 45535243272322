import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

interface ConfirmationDialogProps {
	title: string;
	// message: string;
	message: React.ReactNode;
	open: boolean;
	onClose: () => void;
	onCancel?: () => void;
	onConfirm: () => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
	title,
	message,
	open,
	onClose,
	onCancel = () => undefined,
	onConfirm
}) => {
	return (
		<Dialog open={open} onClose={onClose} id="confirmation-dialog">
			<DialogTitle id="confirmation-dialog-title" variant="h3">
				{title}
			</DialogTitle>
			{/* <DialogContent>
				<Typography id="confirmation-dialog-content">{message}</Typography>
			</DialogContent> */}
			<DialogContent>
				{/* Render the message directly, which can now be JSX */}
				<Typography id="confirmation-dialog-content">
					{message}
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={onConfirm} variant="contained" id={`confirm-button-${title.replace(/\s+/g, '')}`}>
					CONFIRM
				</Button>
				<Button onClick={onCancel} variant="outlined" id={`cancel-button-${title.replace(/\s+/g, '')}`}>
					CANCEL
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmationDialog;
