import { ApiError, OpenAPIConfig, ApiSql } from '../generated-api-clients/sql';
import { ApiRequestOptions } from '../generated-api-clients/no-sql/core/ApiRequestOptions';
import { ApiResult } from '../generated-api-clients/no-sql/core/ApiResult';
import {
	FunctionComponent,
	PropsWithChildren,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState
} from 'react';
type RequestFunction<T> = (client: ApiSql) => Promise<T>;
type ApiSqlContextType = {
	client: ApiSql | undefined;
	error: ApiError | undefined;
	performRequest: <T>(requestFunction: RequestFunction<T>) => Promise<T>;
};
const ApiSqlContext = createContext<ApiSqlContextType>({
	client: new ApiSql(), // This should be a real instance or null initially
	error: undefined,
	// Trailing comma is required in `<T,>` to avoid being recognized as JSX by the TS parser
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	performRequest: async <T,>() => {
		throw new Error('performRequest function not implemented');
	}
});
export const ApiSqlProvider: FunctionComponent<PropsWithChildren<{ config?: Partial<OpenAPIConfig> }>> = ({
	children,
	config
}) => {
	const [error, setError] = useState<ApiError | undefined>(undefined);
	const [client, setClient] = useState<ApiSql | undefined>(undefined);
	useEffect(() => {
		if (!client && config) {
			setClient(new ApiSql(config));
		}
	}, [config, client]);
	// Wrap the request function in a useCallback to prevent unnecessary re-renders.
	// This is especially important if the request function is passed as a prop to a child component.
	// Trailing comma is required in `<T,>` to avoid being recognized as JSX by the TS parser
	const performRequest = useCallback(
		<T,>(requestFunction: RequestFunction<T>): Promise<T> => {
			if (client === undefined) {
				throw new Error('SQL API client is not initialized');
			}
			return requestFunction(client).catch((error: unknown) => {
				const processedError =
					error instanceof ApiError
						? error
						: new ApiError(
								{
									method: 'GET',
									url: client ? client.request.config.BASE : ''
								} as ApiRequestOptions,
								{
									ok: false,
									status: 0,
									statusText:
										'An unexpected error occurred. Actual method unknown, but assumed to be GET (due to type-safety enforcement).',
									url: '',
									body: undefined
								} as ApiResult,
								'An unexpected error occurred. Actual method unknown, but assumed to be GET (due to type-safety enforcement).'
						  );
				console.log('ApiSqlContext -> performRequest() -> ApiError:', processedError);
				setError(processedError);
				throw processedError;
			});
		},
		[client]
	);
	const contextValue = useMemo(
		() => ({
			client,
			error,
			performRequest
		}),
		[error, performRequest, client]
	);
	return <ApiSqlContext.Provider value={contextValue}>{children}</ApiSqlContext.Provider>;
};
export const useApiSqlContext = (): ApiSqlContextType => {
	const context = useContext(ApiSqlContext);
	if (context === undefined) {
		throw new Error('useApiSqlContext must be used within a ApiSqlContextProvider');
	}
	return context;
};
