import { useEffect, useState, FunctionComponent, Fragment, useMemo } from 'react';

// Third-party modules
import { Auth } from 'aws-amplify';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { IconButton, Tooltip, Badge, Typography, AppBar, Toolbar, Menu, MenuItem } from '@mui/material';

// Local modules
import { NOTIFICATION_MOCKS } from './constants';
import NotificationDrawer from './NotificationDrawer';
import { SHAKE_ANIMATION_STRING } from 'src/styles';
import { useGetPopulateUser } from 'src/modules/hooks/apis/sql/users';
import { ProfileIcon, LoadingBackdrop } from '@components';

// Create a style element to add the shake animation to the DOM
const styleElement = document.createElement('style');
styleElement.innerHTML = SHAKE_ANIMATION_STRING;
document.head.appendChild(styleElement);

const TopAppBar: FunctionComponent = () => {
	// API hooks
	const {
		data: userAuth,
		error: userAuthError,
		isError: isUserAuthError,
		isLoading: isUserAuthLoading
	} = useGetPopulateUser();

	// Component state
	const [notifications, setNotifications] = useState(NOTIFICATION_MOCKS);
	const [isNotificationOpen, setNotificationOpen] = useState(false);
	const [profileMenuAnchor, setProfileMenuAnchor] = useState<null | HTMLElement>(null);

	// Memoized data
	const hasUnreadNotifications = useMemo(
		() => notifications.some((notification: any) => notification.status === 'unread'),
		[notifications]
	);

	// Side effects
	/* Handle the case that a user cannot be authenticated */
	useEffect(() => {
		// if (isUserAuthError) {
			// TODO: Replace this with a proper error handling mechanism
			// console.log('Authentication Error:', userAuthError);
		// }
	}, [userAuthError, isUserAuthError]);

	if (isUserAuthLoading) {
		return <LoadingBackdrop />;
	}

	return (
		<Fragment>
			<NotificationDrawer
				open={isNotificationOpen}
				onClose={() => setNotificationOpen(false)}
				notifications={notifications}
				onMarkAsRead={(notificationId: number) => {
					const updatedNotifications = notifications.map((notification: any) => {
						if (notification.id === notificationId) {
							return {
								...notification,
								status: 'read'
							};
						}
						return notification;
					});
					setNotifications(updatedNotifications);
				}}
			/>
			<AppBar sx={{ backgroundColor: '#FFFFFF' }}>
				<Toolbar
					sx={{
						display: 'flex',
						flex: 1,
						justifyContent: 'flex-end',
						marginRight: '2%'
					}}
				>
					<Typography variant="h6" color="black" noWrap>
						Welcome, {userAuth?.user.info.first_name}&nbsp;
						{userAuth?.user.info.last_name}
					</Typography>
					<Tooltip title="Profile">
						<IconButton color="inherit" onClick={event => setProfileMenuAnchor(event.currentTarget)}>
							<ProfileIcon color="black" />
						</IconButton>
					</Tooltip>
					<Menu
						id="profile-menu"
						anchorEl={profileMenuAnchor}
						open={Boolean(profileMenuAnchor)}
						onClose={() => setProfileMenuAnchor(null)}
					>
						<MenuItem onClick={() => setProfileMenuAnchor(null)}>Profile</MenuItem>
						<MenuItem
							onClick={async () => {
								try {
									await Auth.signOut();
									console.log('User signed out successfully');
								} catch (error) {
									console.error('Error signing out:', error);
								} finally {
									setProfileMenuAnchor(null);
								}
							}}
						>
							Sign Out
						</MenuItem>
					</Menu>
					<Tooltip title="Notifications">
						<IconButton
							style={{
								animation: hasUnreadNotifications ? 'bell-ring 0.5s ease-in-out infinite' : 'none'
							}}
							onClick={() => setNotificationOpen(true)}
						>
							{hasUnreadNotifications ? (
								<Badge
									badgeContent={
										notifications.filter((notification: any) => notification.status === 'unread')
											.length
									}
									color="error"
								>
									<NotificationsNoneOutlinedIcon sx={{ color: 'black' }} />
								</Badge>
							) : (
								<NotificationsNoneOutlinedIcon sx={{ color: 'black' }} />
							)}
						</IconButton>
					</Tooltip>
				</Toolbar>
			</AppBar>
		</Fragment>
	);
};

export default TopAppBar;
