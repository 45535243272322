type EnvirallyLogoProps = {
  width?: number;
};

const EnvirallyLogo: React.FC<EnvirallyLogoProps> = ({ width }) => (
  <svg
    version="1.1"
    id="Слой_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 2409.91 658.3"
    width={width || 210}
  >
    <g>
      <g>
        <g>
          <path
            className="st2"
            style={{ fill: "#1F4A73" }}
            d="M275.2,638.53c0-6.19,0.14-12.45,0.47-18.63c0.04-0.83,0.07-1.65,0.14-2.48
				c-71.52-13.24-134.15-52.59-177.42-107.81c0-0.01-0.01-0.01-0.01-0.02c0.34-1.94,0.55-3.92,0.55-5.95
				c0-16.26-11.36-29.84-26.58-33.3c0,0-0.01-0.01-0.01-0.01C52.12,433.7,39.5,392.36,36.66,348.4c-0.4-5.79-0.61-11.66-0.65-17.56
				l-0.02-0.58v-1.11c0-5.62,0.15-11.24,0.51-16.75h-0.02c0,0,0-0.01,0-0.01c0.06-1.49,0.17-2.99,0.28-4.49
				c0.03-0.42,0.07-0.85,0.09-1.27c0.09-1.55,0.23-3.1,0.4-4.65c0.02-0.22,0.04-0.45,0.06-0.67c0.11-1.19,0.22-2.34,0.36-3.53
				c0-0.07,0.01-0.15,0.02-0.23c0.01-0.08,0.03-0.16,0.03-0.24c0.2-1.82,0.4-3.64,0.66-5.42c0.12-1.33,0.33-2.59,0.53-3.87
				c0.05-0.33,0.08-0.67,0.14-1c0.03-0.25,0.07-0.54,0.11-0.79c0.11-0.83,0.22-1.65,0.36-2.45c0-0.11,0.04-0.22,0.04-0.32
				c0.1-0.61,0.21-1.21,0.31-1.82c0.25-1.44,0.5-2.89,0.75-4.28c0.4-2.43,0.86-4.86,1.37-7.24c0.23-1.13,0.48-2.26,0.72-3.39
				c0.23-1.09,0.46-2.21,0.71-3.28c0.11-0.47,0.22-0.94,0.36-1.4c0.4-1.69,0.79-3.42,1.22-5.07c0.5-2.01,1.04-4.03,1.62-6.01
				c0.32-1.33,0.72-2.63,1.12-3.92c0.15-0.55,0.32-1.1,0.49-1.65c0.12-0.38,0.23-0.76,0.35-1.13c0.26-0.93,0.55-1.84,0.83-2.75
				c0.16-0.53,0.32-1.05,0.49-1.58c0.18-0.61,0.36-1.19,0.58-1.76c0.54-1.65,1.08-3.31,1.65-4.93c0.11-0.29,0.22-0.61,0.36-0.94
				c0.19-0.56,0.41-1.13,0.61-1.69c0.48-1.35,0.96-2.7,1.46-4.02c1.42-3.9,2.99-7.74,4.61-11.54c0.62-1.57,1.32-3.1,2.01-4.64
				c0.27-0.59,0.51-1.19,0.78-1.79c0.7-1.6,1.43-3.16,2.19-4.69c0.03-0.05,0.05-0.11,0.08-0.17c0.76-1.67,1.57-3.29,2.38-4.86
				c0.15-0.35,0.3-0.71,0.51-1.01c0.05-0.15,0.1-0.2,0.15-0.3c0.45-0.91,0.93-1.81,1.39-2.73c0.37-0.71,0.73-1.42,1.11-2.14
				c0.94-1.76,1.87-3.53,2.88-5.29c0.04-0.11,0.07-0.18,0.11-0.25c0.14-0.25,0.29-0.47,0.4-0.72c0.86-1.51,1.69-3.02,2.59-4.5
				c0.9-1.58,1.84-3.17,2.81-4.71c1.37-2.27,2.77-4.5,4.21-6.73c1.01-1.55,2.02-3.09,3.06-4.64c0.29-0.43,0.57-0.86,0.86-1.26
				c0.76-1.14,1.54-2.24,2.32-3.34c0.4-0.57,0.8-1.16,1.2-1.71c0.32-0.46,0.64-0.89,0.96-1.33c0.62-0.87,1.24-1.75,1.89-2.61
				c0.41-0.58,0.85-1.13,1.27-1.7c0.48-0.63,0.94-1.26,1.45-1.91c0.43-0.63,0.92-1.22,1.38-1.81c0.5-0.65,0.99-1.31,1.51-1.96
				c0.57-0.74,1.17-1.44,1.74-2.17c0.89-1.1,1.78-2.21,2.7-3.32c1.47-1.82,2.99-3.59,4.51-5.37c0.17-0.2,0.36-0.4,0.53-0.61
				c0.99-1.16,1.99-2.3,3.03-3.43l0.03-0.04c1.26-1.4,2.52-2.81,3.81-4.17c0.29-0.32,0.61-0.65,0.9-0.97
				c2.01-2.12,4.03-4.17,6.08-6.22c0.76-0.78,1.59-1.57,2.38-2.35c0.88-0.86,1.75-1.73,2.66-2.61c0.21-0.21,0.44-0.42,0.66-0.63
				c0.1-0.09,0.2-0.18,0.3-0.28c0.52-0.49,1.04-0.95,1.55-1.43c1.27-1.19,2.53-2.38,3.86-3.54c0.85-0.78,1.67-1.5,2.5-2.23
				c0.42-0.37,0.84-0.74,1.26-1.12c3.67-3.2,7.45-6.3,11.3-9.32c1.76-1.4,3.56-2.77,5.36-4.1c3.78-2.88,7.66-5.61,11.58-8.27
				c1.09-0.73,2.2-1.45,3.3-2.17c0.69-0.45,1.38-0.91,2.05-1.34c1.05-0.68,2.11-1.34,3.16-2c0.92-0.57,1.82-1.15,2.74-1.72
				c1.01-0.65,2.05-1.26,3.06-1.83c0.11-0.07,0.25-0.14,0.36-0.22c0.08-0.05,0.16-0.09,0.24-0.14c1.03-0.64,2.06-1.22,3.09-1.78
				c1.52-0.91,3.08-1.77,4.64-2.63c0.51-0.28,1.01-0.58,1.52-0.85c0.69-0.38,1.4-0.75,2.1-1.13c0.95-0.51,1.9-1.02,2.87-1.52
				c0.05-0.03,0.08-0.05,0.12-0.08c0.1-0.05,0.2-0.1,0.3-0.15c1.94-1.01,3.89-2.01,5.86-2.95c1.98-0.97,3.96-1.91,5.97-2.84
				c0.3-0.14,0.6-0.27,0.9-0.41c0.61-0.27,1.21-0.54,1.82-0.81c0.48-0.23,1.02-0.46,1.54-0.68c0.4-0.17,0.79-0.36,1.18-0.51
				c0.71-0.32,1.42-0.62,2.12-0.92c0.48-0.21,0.97-0.41,1.44-0.62c1.97-0.86,4-1.67,6.02-2.43c1.55-0.63,3.12-1.24,4.68-1.82
				c0.44-0.16,0.87-0.35,1.31-0.5c0.18-0.08,0.36-0.13,0.54-0.19c0.25-0.08,0.5-0.18,0.76-0.26c0.04-0.04,0.09-0.04,0.13-0.04
				c0,0,0.01,0,0.01,0c0.01,0,0.03-0.01,0.04-0.02c0.19-0.05,0.39-0.14,0.63-0.24c0.07-0.03,0.15-0.05,0.22-0.08
				c0.65-0.25,1.32-0.48,1.99-0.71c0.18-0.07,0.36-0.11,0.54-0.18c0.01,0,0.01,0,0.02-0.01c0.33-0.14,0.59-0.2,0.93-0.29
				c1.72-0.66,3.49-1.27,5.21-1.77c0.02-0.02,0.05-0.04,0.07-0.05c0.03-0.01,0.06-0.02,0.1-0.04c0.11-0.04,0.22-0.07,0.32-0.11
				c0.72-0.24,1.43-0.46,2.14-0.68c0.68-0.22,1.36-0.44,2.03-0.64c0.32-0.1,0.65-0.18,0.97-0.27c0.33-0.1,0.65-0.2,0.98-0.27
				c1.73-0.54,3.49-1.04,5.22-1.51c0.22-0.11,0.47-0.14,0.68-0.22c1.84-0.5,3.71-1.01,5.58-1.47c0.05-0.01,0.09-0.02,0.13-0.03
				c2.17-0.54,4.25-1.04,6.43-1.53c0.41-0.1,0.86-0.25,1.32-0.3c1.47-0.35,2.94-0.71,4.45-0.96c0.17-0.03,0.34-0.08,0.51-0.12
				c0.34-0.07,0.67-0.14,1.01-0.22c0.88-0.19,1.78-0.35,2.68-0.52c0.46-0.09,0.91-0.17,1.37-0.26c0.47-0.09,0.96-0.18,1.44-0.27
				c1.06-0.2,2.11-0.4,3.18-0.57c0.65-0.14,1.33-0.25,1.98-0.32c1.94-0.36,3.89-0.65,5.86-0.9c1.11-0.18,2.23-0.32,3.35-0.47
				c0.55-0.08,1.11-0.13,1.66-0.2c1.83-0.23,3.65-0.47,5.51-0.66c0.23-0.03,0.46-0.05,0.7-0.08c0.63-0.08,1.25-0.16,1.88-0.21
				c0.05,0,0.1-0.01,0.16-0.01h0.25c0.15,0,0.3-0.05,0.45-0.05c0.57-0.05,1.12-0.11,1.68-0.16c1.04-0.09,2.08-0.19,3.14-0.28
				c0.38-0.03,0.74-0.04,1.1-0.05c0.12,0,0.23-0.01,0.35-0.01c0.4-0.05,0.76-0.1,1.16-0.1c0.35-0.05,0.71-0.05,1.06-0.05
				c0.13-0.02,0.25-0.02,0.38-0.04c0.21-0.01,0.41-0.03,0.61-0.03c0.01,0,0.01,0,0.02,0v0.02c-0.66,10.88-1.87,21.66-3.69,32.39
				c-2.33,13.97-5.72,27.79-10.12,41.3c-0.56,1.81-1.22,3.61-1.83,5.42c-0.56,1.63-1.08,3.28-1.67,4.9
				c-1.6,4.34-3.34,8.61-5.12,12.86c-2.41,5.71-4.94,11.35-7.67,16.86c-7.95,15.99-17.26,31.08-27.84,45.2
				c-2.38,3.19-4.86,6.33-7.39,9.47c-11.89,14.58-25.21,27.99-39.63,40.09c-9.62,14.12-16.6,29.41-20.9,45.7
				c-1.22,4.76-2.28,9.62-3.04,14.53c66.76-38.21,118.44-98.95,145.37-172.34c0.71-1.87,1.37-3.75,1.97-5.62
				c2.23-6.38,4.2-12.86,6.02-19.39c3.04-10.88,5.47-21.87,7.34-32.95c2.18-12.7,3.54-25.61,4.2-38.47
				c0.3-5.57,0.45-11.19,0.45-16.75c0-0.96,0-1.87-0.1-2.83l-0.91-16.55l-0.05-0.4h-16.98V0C147.64,0.04,0,147.71,0,329.16
				c0,0.5,0,0.94,0.04,1.44c-0.04,0.25,0,0.54,0,0.83c0,0.21,0.01,0.42,0.01,0.63v0.34l0.01,0.19
				c0.54,53.99,14.21,104.92,37.96,149.75c0,0.01,0.01,0.01,0.01,0.02c-4.66,5.84-7.46,13.23-7.46,21.28
				c0,18.87,15.3,34.17,34.17,34.17c3.18,0,6.24-0.47,9.16-1.28c0,0.01,0.01,0.01,0.01,0.02
				c49.79,61.12,121.01,104.14,201.99,117.42l-0.57-10.32C275.23,641.91,275.2,640.25,275.2,638.53z"
          />
          <path
            className="st2"
            style={{ fill: "#1F4A73" }}
            d="M228.21,484.42c-0.11-0.07-0.18-0.14-0.29-0.18c-0.01,0-0.01-0.01-0.02-0.01c0.32-1.88,0.53-3.8,0.53-5.78
				c0-18.87-15.3-34.17-34.17-34.17c-3.17,0-6.22,0.46-9.13,1.27c0,0-0.01-0.01-0.01-0.01c-19.32-23.85-32.84-52.56-38.38-84.03
				c-1.87-10.5-2.84-21.33-2.84-32.34c0-1.76,0.04-3.53,0.07-5.25c0.11-3.71,0.32-7.37,0.65-10.94c0.15-1.69,0.36-3.36,0.55-5.04
				c0.8-6.88,1.95-13.64,3.46-20.29c0.46-2.01,0.93-4.02,1.46-6.01c5.61-21.37,14.93-41.19,27.23-58.78
				c1.12,0.11,2.27,0.18,3.45,0.18c18.89,0,34.17-15.29,34.17-34.17c0-1.83-0.14-3.6-0.43-5.36c-2.01-12.91-11.26-23.42-23.49-27.23
				c-3.24-1.04-6.69-1.58-10.25-1.58c-18.89,0-34.17,15.29-34.17,34.17c0,4.21,0.76,8.27,2.19,12.02
				c-11.91,16.73-21.55,35.18-28.45,54.93c-4.75,13.56-8.2,27.74-10.22,42.38c-0.05,0.36-0.07,0.73-0.13,1.09
				c-0.52,3.45-0.9,6.97-1.22,10.49c-0.56,6.23-0.86,12.76-0.86,19.39c0,2.43,0.05,4.81,0.15,7.24c0,0,0,0,0,0
				c0.36,12.34,1.8,24.46,4.14,36.22c7.48,37.52,24.54,71.66,48.42,99.75c0.01,0.01,0.02,0.02,0.03,0.03
				c-0.35,1.97-0.57,3.98-0.57,6.05c0,18.87,15.3,34.17,34.17,34.17c3.27,0,6.42-0.49,9.41-1.34c0.01,0,0.01,0.01,0.02,0.01
				c2.23,1.51,4.5,3.02,6.8,4.5c17.05,10.86,35.65,19.46,55.4,25.4c6.91,2.05,13.96,3.78,21.12,5.18c0.47-1.94,0.97-3.85,1.51-5.76
				c1.87-6.84,4.1-14.03,6.55-21.12c0.72-2.19,1.51-4.39,2.3-6.55c0.14-0.4,0.29-0.79,0.47-1.19c-6.73-1.15-13.35-2.66-19.78-4.53
				C260.16,502.12,243.43,494.35,228.21,484.42z"
          />
          <path
            className="st2"
            style={{ fill: "#1F4A73" }}
            d="M663.66,327.74c0.05-0.25,0-0.56,0-0.86v-0.86c-0.51-54.1-14.17-105.15-38-150.04
				c4.66-5.84,7.47-13.23,7.47-21.29c0-18.87-15.3-34.17-34.17-34.17c-3.19,0-6.26,0.47-9.19,1.29c0,0,0-0.01-0.01-0.01
				C540.01,60.65,468.79,17.62,387.81,4.36l0.56,10.33c0.1,1.72,0.15,3.39,0.15,5.11c0,6.17-0.15,12.45-0.45,18.63
				c-0.05,0.81-0.1,1.67-0.15,2.48c71.52,13.21,134.13,52.59,177.4,107.81c0.01,0.01,0.01,0.02,0.02,0.02
				c-0.34,1.93-0.55,3.91-0.55,5.95c0,16.26,11.36,29.83,26.57,33.3c20.23,36.59,32.93,77.97,35.76,121.94
				c0.35,5.82,0.56,11.69,0.61,17.56v1.67c0,5.62-0.15,11.24-0.51,16.75c-0.1,1.92-0.2,3.85-0.35,5.77
				c-0.1,1.77-0.25,3.54-0.46,5.31c-0.1,1.21-0.2,2.33-0.35,3.54c0,0.15-0.05,0.3-0.05,0.46c-0.2,1.82-0.41,3.64-0.66,5.42
				c-0.2,1.62-0.4,3.24-0.66,4.86c-0.05,0.25-0.1,0.56-0.1,0.81c-0.05,0.51-0.15,1.06-0.25,1.62c-0.35,2.28-0.71,4.5-1.11,6.73
				c-0.05,0.15-0.05,0.35-0.1,0.51c-0.41,2.43-0.86,4.86-1.37,7.24c-0.45,2.23-0.91,4.45-1.42,6.68c-0.1,0.46-0.2,0.91-0.35,1.42
				c-0.86,3.69-1.82,7.39-2.83,11.03c-0.2,0.71-0.35,1.32-0.56,1.97c-0.45,1.62-0.91,3.19-1.42,4.76c-0.2,0.76-0.4,1.47-0.66,2.18
				c-0.71,2.28-1.42,4.56-2.23,6.83c-0.3,0.96-0.66,1.97-1.01,2.94c-0.66,1.92-1.37,3.85-2.08,5.72c-1.42,3.9-2.99,7.74-4.61,11.54
				c-0.86,2.18-1.82,4.3-2.78,6.43c-0.71,1.67-1.47,3.29-2.28,4.86c-0.76,1.67-1.57,3.29-2.38,4.86c-0.15,0.35-0.3,0.71-0.51,1.01
				c-0.05,0.15-0.1,0.2-0.15,0.3c-0.81,1.62-1.62,3.29-2.48,4.86c-0.96,1.77-1.87,3.54-2.89,5.31c-0.45,0.81-0.91,1.67-1.37,2.48
				c-0.61,1.01-1.17,1.97-1.72,2.99c-0.91,1.57-1.87,3.14-2.83,4.71c-1.37,2.23-2.78,4.5-4.2,6.73c-1.01,1.52-2.02,3.09-3.04,4.61
				c-0.3,0.46-0.61,0.86-0.86,1.27c-1.17,1.72-2.33,3.39-3.54,5.06c-0.96,1.37-1.87,2.63-2.83,3.95c-0.86,1.21-1.77,2.38-2.73,3.59
				c-0.05,0.1-0.1,0.15-0.2,0.25c-0.86,1.16-1.77,2.33-2.68,3.49v0.05c-1.47,1.87-2.94,3.64-4.45,5.47
				c-1.47,1.82-2.99,3.59-4.51,5.37c-1.16,1.37-2.33,2.73-3.54,4.05l-0.05,0.05c-0.35,0.4-0.76,0.86-1.16,1.27
				c-1.01,1.11-2.08,2.23-3.09,3.34c-0.56,0.61-1.06,1.16-1.62,1.67c-1.62,1.72-3.24,3.39-4.91,5.06c-2.83,2.83-5.72,5.62-8.71,8.35
				c-0.91,0.86-1.77,1.67-2.73,2.48c-1.27,1.16-2.48,2.23-3.75,3.34c-3.69,3.19-7.44,6.33-11.29,9.31c-1.77,1.42-3.59,2.78-5.36,4.1
				c-1.92,1.42-3.85,2.83-5.77,4.25c-2.78,1.97-5.62,3.9-8.5,5.82c-0.91,0.56-1.82,1.16-2.68,1.72c-1.97,1.27-3.95,2.48-5.92,3.75
				c-1.11,0.71-2.23,1.37-3.39,2.02c-0.86,0.51-1.77,1.06-2.68,1.57c-0.2,0.1-0.45,0.25-0.66,0.35c-2.02,1.22-4.1,2.38-6.17,3.49
				c-1.67,0.91-3.29,1.77-4.96,2.63c-0.41,0.2-0.86,0.46-1.27,0.66c-1.67,0.86-3.34,1.72-5.01,2.53c-1.97,0.96-3.95,1.87-5.97,2.83
				c-0.91,0.4-1.82,0.81-2.73,1.21c-0.86,0.41-1.82,0.81-2.73,1.21c-1.16,0.51-2.38,1.01-3.54,1.52c-1.97,0.86-4,1.67-6.02,2.43
				c-0.3,0.15-0.66,0.25-0.96,0.4c-1.67,0.66-3.34,1.32-5.01,1.92c-0.46,0.2-0.86,0.3-1.32,0.46c-0.05,0.05-0.1,0.05-0.15,0.05
				c-0.2,0.05-0.41,0.15-0.66,0.25c-0.71,0.3-1.47,0.56-2.23,0.81c-0.15,0.05-0.35,0.1-0.51,0.15c-0.2,0.05-0.3,0.15-0.51,0.15
				c-0.1,0.05-0.3,0.1-0.45,0.15c-0.56,0.2-1.06,0.4-1.62,0.61c-0.05,0-0.05,0-0.1,0.05c-1.01,0.35-2.07,0.66-3.09,1.01
				c-0.15,0-0.25,0.05-0.4,0.1c-0.15,0.1-0.35,0.15-0.51,0.2c-1.37,0.46-2.78,0.91-4.15,1.32c-0.66,0.2-1.31,0.4-1.97,0.56
				c-1.72,0.51-3.44,1.01-5.21,1.52c-1.77,0.51-3.54,0.96-5.31,1.42c-0.3,0.1-0.61,0.2-0.91,0.25c-1.92,0.51-3.9,0.96-5.82,1.42
				l-0.76,0.15c-0.4,0.1-0.86,0.25-1.32,0.3c-1.42,0.35-2.88,0.66-4.3,0.91c-0.05,0.05-0.1,0.05-0.15,0.05
				c-0.3,0.05-0.61,0.1-0.91,0.2c-1.57,0.3-3.09,0.61-4.66,0.91c-0.81,0.15-1.67,0.35-2.53,0.46c-1.37,0.25-2.68,0.51-4.05,0.71
				c-1.32,0.25-2.58,0.46-3.9,0.61c-1.77,0.25-3.54,0.51-5.31,0.76c-2.38,0.3-4.76,0.61-7.19,0.86c-0.51,0.05-0.96,0.1-1.47,0.15
				c-0.51,0.05-1.01,0.1-1.52,0.15c-0.15,0-0.3,0.05-0.46,0.05c-1.77,0.15-3.59,0.3-5.42,0.46c-0.3,0.05-0.56,0.05-0.86,0.05
				c-0.05,0-0.05,0-0.1,0c-1.06,0.1-2.07,0.15-3.14,0.2c0.66-10.88,1.87-21.66,3.7-32.39c2.33-13.97,5.72-27.79,10.12-41.3
				c0.58-1.87,1.26-3.74,1.9-5.61c0.54-1.57,1.03-3.15,1.61-4.71c1.51-4.11,3.17-8.14,4.85-12.16c2.49-5.94,5.11-11.82,7.94-17.55
				c7.95-15.99,17.26-31.08,27.84-45.2c0.15-0.2,0.32-0.4,0.47-0.61c2.26-2.99,4.54-5.95,6.92-8.84l-0.06,0.05
				c0.02-0.02,0.03-0.05,0.05-0.07c11.89-14.58,25.21-27.99,39.63-40.09c9.62-14.12,16.6-29.41,20.9-45.7
				c1.21-4.76,2.28-9.62,3.04-14.53c-66.76,38.21-118.44,98.95-145.37,172.34c-0.71,1.87-1.37,3.75-1.97,5.62
				c-2.23,6.38-4.2,12.86-6.02,19.39c-3.04,10.88-5.47,21.87-7.34,32.95c-2.18,12.7-3.54,25.61-4.2,38.47
				c-0.3,5.57-0.46,11.19-0.46,16.75c0,0.96,0,1.87,0.1,2.83l0.91,16.55l0.05,0.4h17.01c181.45-0.05,329.1-147.69,329.1-329.15
				C663.71,328.65,663.71,328.25,663.66,327.74z"
          />
          <path
            className="st2"
            style={{ fill: "#1F4A73" }}
            d="M435.49,173.9c0.11,0.07,0.18,0.14,0.29,0.18c0.01,0,0.01,0.01,0.02,0.01c-0.32,1.88-0.52,3.8-0.52,5.78
				c0,18.87,15.3,34.17,34.17,34.17c3.17,0,6.22-0.46,9.13-1.27c0,0,0.01,0.01,0.01,0.01c19.32,23.85,32.84,52.56,38.38,84.03
				c1.87,10.5,2.84,21.33,2.84,32.34c0,1.76-0.04,3.53-0.07,5.25c-0.11,3.71-0.32,7.37-0.65,10.94c-0.13,1.54-0.33,3.06-0.5,4.58
				c-0.8,7.01-1.95,13.9-3.49,20.67c-0.47,2.04-0.94,4.07-1.48,6.08c-5.61,21.37-14.93,41.19-27.23,58.78
				c-1.12-0.11-2.27-0.18-3.45-0.18c-18.89,0-34.17,15.29-34.17,34.17c0,1.83,0.14,3.6,0.43,5.36
				c2.01,12.91,11.26,23.42,23.49,27.23c3.24,1.04,6.69,1.58,10.25,1.58c18.89,0,34.17-15.29,34.17-34.17
				c0-4.21-0.76-8.27-2.19-12.02c11.91-16.73,21.55-35.18,28.45-54.93c4.75-13.56,8.2-27.74,10.22-42.38
				c0.06-0.38,0.08-0.76,0.13-1.14c0.51-3.44,0.89-6.94,1.21-10.45c0.56-6.23,0.86-12.75,0.86-19.39c0-2.43-0.05-4.81-0.15-7.24
				c0,0,0,0,0,0c-0.36-12.34-1.8-24.46-4.14-36.22c-7.48-37.52-24.53-71.66-48.42-99.75c-0.01-0.01-0.02-0.02-0.03-0.03
				c0.35-1.97,0.57-3.98,0.57-6.05c0-18.87-15.3-34.17-34.17-34.17c-3.27,0-6.42,0.49-9.41,1.34c-0.01,0-0.01-0.01-0.02-0.01
				c-2.23-1.51-4.5-3.02-6.8-4.5c-17.05-10.86-35.65-19.46-55.4-25.4c-6.91-2.05-13.96-3.78-21.12-5.18
				c-0.47,1.94-0.97,3.85-1.51,5.76c-1.87,6.84-4.1,14.03-6.55,21.12c-0.72,2.19-1.51,4.39-2.3,6.55c-0.14,0.4-0.29,0.79-0.47,1.19
				c6.73,1.15,13.35,2.66,19.78,4.53C403.55,156.2,420.28,163.97,435.49,173.9z"
          />
        </g>
        <g>
          <path
            className="st2"
            style={{ fill: "#1F4A73" }}
            d="M988.65,231.99c-25.29-24.26-56.25-36.56-92.01-36.56c-36.81,0-68.31,13.26-93.62,39.42
				c-25.27,26.13-38.08,58.02-38.08,94.8c0,36.79,12.9,68.52,38.35,94.32c25.46,25.81,57.04,38.89,93.85,38.89
				c24.73,0,48.16-6.27,69.63-18.64c21.46-12.36,38.46-29.27,50.5-50.25l2.96-5.15h-46.95l-1.03,1.26
				c-19.09,23.29-44.53,35.1-75.61,35.1c-25.23,0-46.68-8.34-63.77-24.79c-16.25-15.64-25.33-35.04-27.03-57.72h221.58l0.38-3.01
				c0.7-5.58,0.87-10.42,0.53-14.16C1027.31,287.74,1013.96,256.27,988.65,231.99z M806.36,308.55
				c4.4-20.93,14.63-38.68,30.46-52.83c16.76-15,37.06-22.6,60.33-22.6c22.91,0,42.95,7.6,59.55,22.59
				c15.67,14.15,25.67,31.91,29.77,52.84H806.36z"
          />
          <path
            className="st2"
            style={{ fill: "#1F4A73" }}
            d="M1163.76,195.43c-34.47,0-62.48,10.8-83.25,32.09c-20.76,21.27-31.28,53.25-31.28,95.06v136.24h39.19V322.59
				c0-29.62,6.84-52.2,20.32-67.13c13.4-14.83,31.91-22.35,55.01-22.35c23.12,0,41.63,7.52,55.02,22.35
				c13.48,14.93,20.32,37.51,20.32,67.13v136.24h39.19V322.59c0-41.81-10.52-73.79-31.27-95.06
				C1226.25,206.23,1198.23,195.43,1163.76,195.43z"
          />
          <polygon
            className="st2"
            style={{ fill: "#1F4A73" }}
            points="1406.17,405.93 1316,199.47 1274.67,199.47 1386.85,456.77 1387.75,458.83 1424.57,458.83 
				1538.17,199.47 1496.84,199.47 			"
          />
          <path
            className="st2"
            style={{ fill: "#1F4A73" }}
            d="M1568.76,114.64c-6.61,0-12.33,2.27-17.03,6.76c-4.72,4.53-7.12,10.21-7.12,16.87
				c0,7.01,2.4,12.86,7.12,17.38c4.68,4.49,10.41,6.77,17.03,6.77c6.61,0,12.34-2.28,17.01-6.76c4.73-4.52,7.13-10.37,7.13-17.38
				c0-6.67-2.4-12.34-7.12-16.87C1581.09,116.91,1575.36,114.64,1568.76,114.64z"
          />
          <rect
            x="1549.16"
            y="199.47"
            className="st2"
            style={{ fill: "#1F4A73" }}
            width="39.19"
            height="259.36"
          />
          <path
            className="st2"
            style={{ fill: "#1F4A73" }}
            d="M1733.37,197.96c-32.5,0-58.76,10.74-78.04,31.93c-19.19,21.09-28.92,52.79-28.92,94.22v134.73h39.19V324.1
				c0-29.34,6.19-51.71,18.4-66.49c12.21-14.79,28.36-21.98,49.36-21.98c3.86,0,5.89,0.24,6.91,0.44l4.1,0.81v-37.81l-2.78-0.54
				C1739.67,198.15,1736.9,197.96,1733.37,197.96z"
          />
          <path
            className="st2"
            style={{ fill: "#1F4A73" }}
            d="M1878.81,195.43c-36.8,0-68.62,13.17-94.59,39.13c-25.96,25.96-39.13,57.79-39.13,94.59
				c0,36.8,13.17,68.63,39.13,94.59c25.96,25.97,57.78,39.13,94.59,39.13c21.41,0,41.55-5.6,59.85-16.65
				c14.25-8.6,25.71-19.34,34.17-32.01v44.63h39.19V329.15c0-36.79-13.08-68.61-38.87-94.58
				C1947.35,208.6,1915.61,195.43,1878.81,195.43z M1946.04,397.44c-18.1,18.41-40.55,27.75-66.73,27.75
				c-26.2,0-48.83-9.43-67.25-28.02c-18.44-18.59-27.78-41.48-27.78-68.02c0-26.87,9.26-49.85,27.53-68.28
				c18.26-18.42,40.97-27.77,67.5-27.77c26.52,0,49.05,9.33,66.97,27.74c17.95,18.44,27.05,41.42,27.05,68.3
				C1973.34,356.03,1964.15,379,1946.04,397.44z"
          />
          <rect
            x="2040.48"
            y="98.48"
            className="st2"
            style={{ fill: "#1F4A73" }}
            width="39.19"
            height="360.35"
          />
          <rect
            x="2113.2"
            y="98.48"
            className="st2"
            style={{ fill: "#1F4A73" }}
            width="39.19"
            height="360.35"
          />
          <path
            className="st2"
            style={{ fill: "#1F4A73" }}
            d="M2370.73,199.47v142.81c0,26.88-6.98,47.65-20.74,61.73c-13.73,14.05-32.1,21.18-54.6,21.18
				c-23.11,0-41.62-7.52-55.01-22.35c-13.48-14.93-20.32-37.51-20.32-67.13V199.47h-39.19v136.24c0,41.8,10.52,73.79,31.28,95.06
				c20.77,21.29,48.78,32.09,83.25,32.09c32.35,0,57.65-11.01,75.34-32.74v15.67c0,21.53-7.07,39.85-21.02,54.44
				c-13.89,14.54-32.17,21.91-54.32,21.91c-30.19,0-51.82-13.43-66.12-41.07l-0.96-1.86h-42.92l1.76,4.65
				c8.61,22.75,22.61,41.28,41.59,55.09c19.03,13.84,41.46,20.86,66.66,20.86c33.12,0,60.8-11.23,82.26-33.36
				c21.41-22.11,32.27-50.27,32.27-83.69v-243.3H2370.73z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default EnvirallyLogo;
