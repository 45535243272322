import { FunctionComponent } from 'react';
import { SxProps, Theme, Backdrop, CircularProgress, IconButton, Box } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

interface LoadingBackdropProps {
	backDropSx?: SxProps<Theme>;
	isOpen?: boolean;
	onBackClick?: () => void;
}
const LoadingBackdrop: FunctionComponent<LoadingBackdropProps> = ({
	backDropSx = { color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 },
	isOpen = true,
	onBackClick
}) => {
	return (
		<Backdrop sx={backDropSx} open={isOpen} id="loadingBackdrop">
			<Box style={{ position: 'relative' }}>
				<CircularProgress color="inherit" id="loadingBackdropCircularProgress" />
				{onBackClick && (
					<IconButton
						sx={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							zIndex: 1
						}}
					>
						<ArrowBack
							onClick={onBackClick}
							style={{
								color: '#fff',
								position: 'absolute',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -60%)',
								zIndex: 1
							}}
						/>
					</IconButton>
				)}
			</Box>
		</Backdrop>
	);
};

export default LoadingBackdrop;
