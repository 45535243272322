import { createTheme } from '@mui/material/styles';

import { Color } from './enums';
import Inter  from '../assets/fonts/Inter-Regular.woff';

const PageTheme = createTheme({
	palette: {
		primary: {
			main: Color.Primary
		},
		secondary: {
			main: Color.Secondary
		},
		info: {
			main: Color.Info
			// main: Color.ComyBlue
		},
		success: {
			main: Color.Success
		},
		warning: {
			main: Color.Warning
		},
		error: {
			main: Color.Danger
		}
	},
	components: {
		MuiInputBase: {
			styleOverrides: {
				// Apply your custom font to input elements
				root: {
					fontFamily: Inter // Use the font from global settings
				}
			}
		},

		MuiFormControl: {
			styleOverrides: {
				// Apply your custom font to form controls
				root: {
					fontFamily: Inter // Use the font from global settings
				}
			}
		},

		MuiTableHead: {
			styleOverrides: {
				root: {
					backgroundColor: Color.Gray
				}
			}
		},

		MuiTabs: {
			styleOverrides: {
				indicator: {
					backgroundColor: 'rgba(133, 180, 224, 0.3)',
					borderRadius: '8px',
					minHeight: '4px',
					height: '60%',
					marginBottom: '18px'
				}
				// flexContainer: {
				//   border: "2px solid black",
				// },
			}
		},

		MuiTab: {
			styleOverrides: {
				root: {
					minHeight: '30px',
					height: '30px',
					textTransform: 'none',
					borderRadius: '8px',
					fontWeight: '600'
				}
			}
		},

		MuiToggleButton: {
			styleOverrides: {
				root: {
					fontFamily: Inter,
					fontWeight: '600'
				}
			}
		},

		MuiDialogTitle: {
			styleOverrides: {
				root: {
					fontFamily: Inter,
					fontWeight: '600'
				}
			}
		},

		MuiSwitch: {
			styleOverrides: {
				root: {
					padding: 8
				},
				track: {
					borderRadius: '22px',
					'&:before, &:after': {
						content: '""',
						position: 'absolute',
						top: '50%',
						transform: 'translateY(-50%)',
						width: '16px',
						height: '16px'
					},
					'&:before': {
						backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${Color.Primary}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
						left: '12px'
					},
					'&:after': {
						backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${Color.Primary}" d="M19,13H5V11H19V13Z" /></svg>')`,
						right: '12px'
					}
				},
				thumb: {
					boxShadow: 'none',
					width: '16px',
					height: '16px',
					margin: '2px'
				}
			}
		},

		MuiStack: {
			styleOverrides: {
				root: {
					fontFamily: Inter
				}
			}
		}

		// MuiIconButton: {
		//   styleOverrides: {
		//     root: {
		//       marginLeft: "5px",
		//       backgroundColor: "rgba(201, 148, 21, 0.5)",
		//       color: "#FFFFFF",
		//     },
		//   },
		// },
	},
	typography: {
		fontFamily: Inter,
		// fontSize: 12,

		h1: {
			fontWeight: 'bold',
			fontSize: '2.5rem' // 40px
		},

		h2: {
			fontWeight: 'bold',
			fontSize: '2rem' // 32px
		},

		h3: {
			fontWeight: '600', // Semi-bold
			fontSize: '1.5rem' // 24px
		},

		h4: {
			fontWeight: '600', // Semi-bold
			fontSize: '1rem' // 20px
		},

		body1: {
			fontWeight: 'normal', // Regular
			fontSize: '1rem' // 16px
		},

		h5: {
			fontWeight: '600', // Regular
			fontSize: '0.85rem' // 12px
		},

		h6: {
			fontWeight: 'normal', // Regular
			fontSize: '0.7rem', // 10px
			textTransform: 'none'
		}
	}
});

export default PageTheme;
