import React, { useEffect, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import { Country, State, City } from 'country-state-city';
import SelectFormControl from './SelectFormControl';

interface LabelledValue {
	value: string;
	label: string;
}

export type LocationDetails = {
	streetAddress: string;
	postalCode: string;
	city: string;
	stateProvince: string;
	country: string;
};

export type IndependentLocationFields = {
	streetAddress: string;
	postalCode: string;
};

export type DependentLocationFields = {
	city: string;
	stateProvince: string;
	country: string;
};

const DEFAULT_DETAILS: LocationDetails = {
	streetAddress: '',
	postalCode: '',
	city: '',
	stateProvince: '',
	country: ''
};

const filteredLabels = ['United States', 'Canada', 'Mexico', 'Brazil', 'Argentina'];

const COUNTRIES: LabelledValue[] = Country.getAllCountries()
	.map(country => ({
		value: country.isoCode,
		label: country.name
	}))
	.filter(country => filteredLabels.includes(country.label) );

//TODO: Add validation (error state/event handling)
interface AddressInputProps {
	id: string;
	independentFields: IndependentLocationFields;
	dependentFields: DependentLocationFields;
	onIndependentFieldsChange: (details: IndependentLocationFields) => void;
	onCountryChange: (country: string) => void;
	onStateChange: (state: string) => void;
	onCityChange: (city: string) => void;
	children?: React.ReactNode;
}
const AddressInput: React.FC<AddressInputProps> = ({
	id,
	independentFields,
	dependentFields,
	onIndependentFieldsChange,
	onCountryChange,
	onStateChange,
	onCityChange,
	children
}) => {
	const [streetAddress, setStreetAddress] = useState<string>(independentFields.streetAddress);
	const [postalCode, setPostalCode] = useState<string>(independentFields.postalCode);
	const [cities, setCities] = useState<LabelledValue[]>([{ value: '', label: '' }]);
	const [provinces, setProvinces] = useState<LabelledValue[]>([{ value: '', label: '' }]);
	const [validationErrors, setValidationErrors] = useState(DEFAULT_DETAILS);

	const updateProvinces = (countryLabel: string) => {
		const countryIsoCode = COUNTRIES.find(country => country.label === countryLabel)?.value;
		if (countryIsoCode) {
			const states = State.getStatesOfCountry(countryIsoCode);
			setProvinces(states.map(state => ({ value: state.isoCode, label: state.isoCode })));
		} else {
			setProvinces([{ value: '', label: '' }]);
		}
	};

	useEffect(() => {
		const countryIsoCode = COUNTRIES.find(country => country.label === dependentFields.country)?.value;
		const provinceIsoCode = provinces.find(province => province.label === dependentFields.stateProvince)?.value;
		if (countryIsoCode && provinceIsoCode) {
			const cities = City.getCitiesOfState(countryIsoCode, provinceIsoCode);
			setCities(cities.map(city => ({ value: city.stateCode, label: city.name })));
		} else {
			setCities([{ value: '', label: '' }]);
		}
	}, [provinces, dependentFields.country, dependentFields.stateProvince]);

	useEffect(() => {
		updateProvinces(dependentFields.country);
	}, [dependentFields.country]);

	const validateField = (key: string, value: string) => {
		if (!value) {
			setValidationErrors({
				...validationErrors,
				[key]: 'This field is required'
			});
		} else {
			setValidationErrors({ ...validationErrors, [key]: '' });
		}
	};

	return (
		<Grid container spacing={2} id={id}>
			{children}
			<Grid item xs={9}>
				<TextField
					label="Street Address"
					fullWidth
					id="streetAddress"
					name="streetAddress"
					value={streetAddress}
					onChange={e => {
						validateField('streetAddress', e.target.value);
						setStreetAddress(e.target.value);
						onIndependentFieldsChange({ ...independentFields, streetAddress: e.target.value });
					}}
					required
					error={!!validationErrors.streetAddress}
				/>
			</Grid>
			<Grid item xs={3}>
				<TextField
					label="Postal Code"
					fullWidth
					id="postalCode"
					name="postalCode"
					value={postalCode}
					onChange={e => {
						validateField('postalCode', e.target.value);
						setPostalCode(e.target.value);
						onIndependentFieldsChange({ ...independentFields, postalCode: e.target.value });
					}}
					required
					error={!!validationErrors.postalCode}
				/>
			</Grid>
			<Grid item xs={4}>
				<SelectFormControl
					label="Country"
					value={dependentFields.country}
					onChange={value => {
						validateField('country', value);
						updateProvinces(value);
						onCountryChange(value);
					}}
					options={COUNTRIES.map(countryData => countryData.label)}
					id="countrySelect"
					name="country"
					required
					error={!!validationErrors.country}
				/>
			</Grid>
			<Grid item xs={4}>
				<SelectFormControl
					label="State Province"
					value={dependentFields.stateProvince}
					onChange={value => {
						validateField('stateProvince', value);
						onStateChange(value);
					}}
					options={provinces.map(stateData => stateData.label)}
					id="stateProvinceSelect"
					name="stateProvince"
					required
					error={!!validationErrors.stateProvince}
				/>
			</Grid>
			<Grid item xs={4}>
				<SelectFormControl
					label="City"
					value={dependentFields.city}
					onChange={value => {
						validateField('city', value);
						onCityChange(value);
					}}
					options={cities.map(cityData => cityData.label)}
					id="citySelect"
					name="city"
					error={!!validationErrors.city}
				/>
			</Grid>
		</Grid>
	);
};

export default AddressInput;