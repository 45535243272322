type GarbageCanIconProps = {
	color: string; // Assuming 'color' is a CSS color value (e.g., "blue")
};

const GarbageCanIcon: React.FC<GarbageCanIconProps> = ({ color }) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9 3V4H4V6H5V19C5 20.1 5.9 21 7 21H17C18.1 21 19 20.1 19 19V6H20V4H15V3H9ZM7 6H17V19H7V6ZM9 8V17H11V8H9ZM13 8V17H15V8H13Z"
			fill={color}
		/>
	</svg>
);

export default GarbageCanIcon;
