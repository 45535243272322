import React, { useState, MouseEvent } from 'react';
import {
	Box,
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Paper,
	TablePagination,
	TableSortLabel
} from '@mui/material';

export interface Column {
	id: keyof Datum;
	label: string;
	numeric?: boolean;
}

export interface Datum {
	[key: string]: string | number;
}

export interface DataTableProps<T> {
	id: string;
	columns: Column[];
	data: T[];
	paginationEnabled?: boolean;
	sortingEnabled?: boolean;
	rowsPerPageOptions?: number[];
	renderRow: (row: T, columns: Column[]) => React.ReactNode[];
}

const DataTable = <T extends {}>({
	id,
	columns,
	data,
	paginationEnabled,
	sortingEnabled,
	rowsPerPageOptions,
	renderRow
}: DataTableProps<T>) => {
	const [pageNumber, setPageNumber] = useState<number>(0);
	const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions ? rowsPerPageOptions[0] : 5);
	const [orderBy, setOrderBy] = useState<keyof Datum>(''); // Column to be sorted by
	const [order, setOrder] = useState<'asc' | 'desc'>('asc'); // Sorting order

	const stableSort = (array: T[], comparator: (a: T, b: T) => number) => {
		return array
			.map((el, index) => [el, index] as [T, number])
			.sort((a, b) => {
				const order = comparator(a[0], b[0]);
				if (order !== 0) return order;
				return a[1] - b[1];
			})
			.map(el => el[0]);
	};

	const getComparator = (order: 'asc' | 'desc', orderBy: keyof Datum): ((a: T, b: T) => number) => {
		return order === 'asc'
			? (a: T, b: T) => String((a as any)[orderBy]).localeCompare(String((b as any)[orderBy]))
			: (a: T, b: T) => String((b as any)[orderBy]).localeCompare(String((a as any)[orderBy]));
	};

	return (
		<Box id={id}>
			<TableContainer component={Paper} id="tableContainer">
				<Table size="small">
					<TableHead>
						<TableRow>
							{columns.map(column => (
								<TableCell key={column.id} id={`dataTableCell-${column.label}-${column.id}`}>
									{!sortingEnabled ? (
										column.label
									) : (
										<TableSortLabel
											id={`tableSortLabel-${column.label}-${column.id}`}
											active={orderBy === column.id}
											direction={orderBy === column.id ? order : 'asc'}
											onClick={() => {
												setOrderBy(column.id);
												setOrder(orderBy === column.id && order === 'asc' ? 'desc' : 'asc');
											}}
										>
											{column.label}
										</TableSortLabel>
									)}
								</TableCell>
							))}
						</TableRow>
					</TableHead>

					<TableBody>
						{(sortingEnabled ? stableSort(data, getComparator(order, orderBy)) : data)
							.slice(
								paginationEnabled ? pageNumber * rowsPerPage : 0,
								paginationEnabled ? pageNumber * rowsPerPage + rowsPerPage : data.length
							)
							.map((row, rowIndex) => (
								<TableRow hover key={rowIndex} sx={{ cursor: 'pointer' }}>
									{renderRow(row, columns).map((cell, cellIndex) => (
										<TableCell key={cellIndex}>{cell}</TableCell>
									))}
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			{paginationEnabled && (
				<TablePagination
					id="tablePagination"
					rowsPerPageOptions={rowsPerPageOptions || [5, 10, 25]}
					component="div"
					count={data.length}
					rowsPerPage={rowsPerPage}
					page={pageNumber}
					onPageChange={(
						_event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent> | null,
						updatedPageNumber: number
					) => setPageNumber(updatedPageNumber)}
					onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						setRowsPerPage(parseInt(event.target.value, 10));
						setPageNumber(0);
					}}
				/>
			)}
		</Box>
	);
};

export default DataTable;
