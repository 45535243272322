/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class S3Service {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Move One Object
     * Move object from one path to another
     *
     * Returns:
     * - (str): New key of object in bucket.
     * @param key
     * @param destinationKey
     * @returns string Success
     * @throws ApiError
     */
    public s3MoveOneObject(
        key: string,
        destinationKey: string,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/s3/move-one-object',
            query: {
                'key': key,
                'destination_key': destinationKey,
            },
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
