/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { AiModelsService } from './services/AiModelsService';
import { CommonService } from './services/CommonService';
import { DeleteService } from './services/DeleteService';
import { DownloadService } from './services/DownloadService';
import { EcsHealthCheckService } from './services/EcsHealthCheckService';
import { FrontentConstantVariablesService } from './services/FrontentConstantVariablesService';
import { InvoiceService } from './services/InvoiceService';
import { PipelineService } from './services/PipelineService';
import { PromptsPromptsService } from './services/PromptsPromptsService';
import { ReportingService } from './services/ReportingService';
import { S3Service } from './services/S3Service';
import { UtilityCompanyUtilityTemplateService } from './services/UtilityCompanyUtilityTemplateService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ApiNoSql {

    public readonly aiModels: AiModelsService;
    public readonly common: CommonService;
    public readonly delete: DeleteService;
    public readonly download: DownloadService;
    public readonly ecsHealthCheck: EcsHealthCheckService;
    public readonly frontentConstantVariables: FrontentConstantVariablesService;
    public readonly invoice: InvoiceService;
    public readonly pipeline: PipelineService;
    public readonly promptsPrompts: PromptsPromptsService;
    public readonly reporting: ReportingService;
    public readonly s3: S3Service;
    public readonly utilityCompanyUtilityTemplate: UtilityCompanyUtilityTemplateService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '0.1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.aiModels = new AiModelsService(this.request);
        this.common = new CommonService(this.request);
        this.delete = new DeleteService(this.request);
        this.download = new DownloadService(this.request);
        this.ecsHealthCheck = new EcsHealthCheckService(this.request);
        this.frontentConstantVariables = new FrontentConstantVariablesService(this.request);
        this.invoice = new InvoiceService(this.request);
        this.pipeline = new PipelineService(this.request);
        this.promptsPrompts = new PromptsPromptsService(this.request);
        this.reporting = new ReportingService(this.request);
        this.s3 = new S3Service(this.request);
        this.utilityCompanyUtilityTemplate = new UtilityCompanyUtilityTemplateService(this.request);
    }
}
