import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button } from '@mui/material';

import { useNavigate } from 'react-router-dom';

export interface Notification {
	id: number;
	type: 'new_utility' | 'invoice_update';
	title: string;
	message: string;
	details: {
		utility_account?: string;
		invoice_no?: number;
		client?: string;
	};
	status: 'read' | 'unread';
	timestamp: string;
}

interface NotificationDialogProps {
	open: boolean;
	onClose: () => void;
	notification: Notification | null;
}

const NotificationDialog: React.FC<NotificationDialogProps> = ({ open, onClose, notification }) => {
	const navigate = useNavigate(); // Get the navigate function

	// TODO: REVISE WITH typedNavigate
	const handleView = () => {
		if (notification) {
			// Check the notification's title and navigate accordingly
			if (notification.title === 'Title1') {
				navigate('/page1'); // Replace with the route you want to navigate to
			} else if (notification.title === 'Title2') {
				navigate('/page2'); // Replace with another route
			} // Add more conditions for other titles
		}
		onClose(); // Close the dialog after navigation
	};
	return (
		<Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth id="notificationDialog">
			{notification && (
				<>
					<DialogTitle>
						<Typography id="notificationDialogTitle" variant="h4">
							{notification.title}
						</Typography>
					</DialogTitle>
					<DialogContent>
						<Typography id="notificationDialogMessage" variant="body1">
							{notification.message}
						</Typography>
					</DialogContent>
					<DialogActions>
						<Button id="notificationDialogViewButton" onClick={handleView}>
							View
						</Button>
						<Button id="notificationDialogCloseButton" onClick={onClose}>
							Close
						</Button>
					</DialogActions>
				</>
			)}
		</Dialog>
	);
};

export default NotificationDialog;
