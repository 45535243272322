/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type getUtilityAcccounts = {
    status: getUtilityAcccounts.status;
    message: string;
    body: Array<{
        id: string;
        name: string;
        account_number?: string;
        commodity_names?: string;
        meters_info?: Array<{
            meter_id: string;
            account_id: string;
            meter_number: string;
            meter_commodity: string;
        }>;
        commodity_list?: Array<{
            commodity_id: string;
            commodity_name: string;
        }>;
    }>;
};

export namespace getUtilityAcccounts {
    export enum status {
        SUCCESS = 'Success',
        FAILURE = 'Failure',
    }
}
