import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

interface AutocompleteProps {
	id: string;
	options: string[];
	onChange: (value: string | null) => void;
	label: string;
}

const TextFieldAutoComplete: React.FC<AutocompleteProps> = ({ id, options, onChange, label }) => {
	return (
		<Autocomplete
			id={id}
			options={options}
			onChange={(_, value) => onChange(value)}
			renderInput={params => <TextField {...params} label={label} variant="outlined" />}
			size="small"
			sx={{ bgcolor: 'white' }}
		/>
	);
};

export default TextFieldAutoComplete;
