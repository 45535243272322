/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum CommodityTypesEnum {
    DIESEL = 'Diesel',
    OIL = 'Oil',
    STEAM = 'Steam',
    PROPANE = 'Propane',
    GASOLINE = 'Gasoline',
    NATURAL_GAS = 'Natural Gas',
    ELECTRICITY = 'Electricity',
    WASTE = 'Waste',
    GREEN_HOUSE_GAS = 'Green House Gas',
    SPRINKLER = 'Sprinkler',
    WATER = 'Water',
    SEWAGE = 'Sewage',
    WATER_SEWER = 'Water_Sewer',
    LIGHTING_SERVICE = 'Lighting Service',
    FIRE_SERVICE = 'Fire Service',
    UNKNOWN = 'UNKNOWN',
}
