/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * High level categories that an invoice can fall into.
 *
 * These are values used for classifying the type of meter reading.
 */
export enum MeterReadTypeEnum {
    ACTUAL = 'Actual',
    ESTIMATED = 'Estimated',
}
