/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DownloadService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * S3 Download One File
     * Download an object from S3 buckets by key.
 *
 * This is useful for frontend development, as it allows the user to download the file as
 * an attachment in the browser.
 *
 * - A "key" is the path of the object in the bucket.
 * - The key for the uploaded file can be found in the invoice collection.
 *
 * __Example:__
 *
 * ```python
 * key = '3f822f8c-a488-40db-1ac3-881296a1f86f/ad1f2a5b-5c64-4f77-b4b4-37f87824737a/9bd3ffd0-0199-4636-90e2-05677d51f737/9bd3ffd0-0199-4636-90c2-05377d11f337.pdf'
 * url = f'/api/v2/s3/download-one?key={key}'
 * r = requests.get(url)
 * print(r.content)
 * ```
 *
 * Returns:
 * (bytes): FileAttachmentResponse
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public s3DownloadOneFileApiV2S3DownloadOneGet(
key: string,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/s3/download-one',
            query: {
                'key': key,
            },
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * S3 Read One Base64 File
     * Read an object from S3 buckets by key
 *
 * - A "key" is the path of the object in the bucket.
 * - The key for the uploaded file can be found in the invoice collection.
 *
 * __Example:__
 *
 * ```python
 * key = '3f822f8c-a488-40db-1ac3-881296a1f86f/ad1f2a5b-5c64-4f77-b4b4-37f87824737a/9bd3ffd0-0199-4636-90e2-05677d51f737/9bd3ffd0-0199-4636-90c2-05377d11f337.pdf'
 * url = f'/api/v2/s3/read-one?key={key}'
 * r = requests.get(url)
 * print(r.content)
 * ```
 * Args:
 * - key of object in S3
 *
 * Returns:
 * (str): a base64 encoded string of bytes
     * @param key 
     * @returns string Success
     * @throws ApiError
     */
    public s3ReadOneBase64FileApiV2S3ReadOneGet(
key: string,
): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/s3/read-one',
            query: {
                'key': key,
            },
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * S3 Read Failed Invoice
     * Read an object from S3 buckets by key
 *
 * - A "key" is the path of the object in the bucket.
 * - The key for the uploaded file can be found in the invoice collection.
 *
 * __Example:__
 *
 * ```python
 * key = '3f822f8c-a488-40db-1ac3-881296a1f86f/ad1f2a5b-5c64-4f77-b4b4-37f87824737a/9bd3ffd0-0199-4636-90e2-05677d51f737/9bd3ffd0-0199-4636-90c2-05377d11f337.pdf'
 * url = f'/api/v2/s3/read-failed-invoice?key={key}'
 * r = requests.get(url)
 * print(r.content)
 * ```
 * Args:
 * - key of object in S3
 *
 * Returns:
 * (str): a base64 encoded string of bytes
     * @param key 
     * @returns string Success
     * @throws ApiError
     */
    public s3ReadFailedInvoiceApiV2S3ReadFailedInvoiceGet(
key: string,
): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/s3/read-failed-invoice',
            query: {
                'key': key,
            },
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
