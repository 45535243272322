/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddAccountRecord } from '../models/AddAccountRecord';
import type { AddDivision } from '../models/AddDivision';
import type { AddDivisionAdminSide } from '../models/AddDivisionAdminSide';
import type { AddFacility } from '../models/AddFacility';
import type { AddGroup } from '../models/AddGroup';
import type { AddUserClientSide } from '../models/AddUserClientSide';
import type { AddYearlyRevenue } from '../models/AddYearlyRevenue';
import type { AdminAddClientUserToOrganization } from '../models/AdminAddClientUserToOrganization';
import type { AdminLandingPageResponse } from '../models/AdminLandingPageResponse';
import type { createNewClientResponse } from '../models/createNewClientResponse';
import type { createNewUtilityResponse } from '../models/createNewUtilityResponse';
import type { CreditsReportList } from '../models/CreditsReportList';
import type { downloadS3File } from '../models/downloadS3File';
import type { EditAdminUserPermissions } from '../models/EditAdminUserPermissions';
import type { EditDivision } from '../models/EditDivision';
import type { EditFacility } from '../models/EditFacility';
import type { EditGroup } from '../models/EditGroup';
import type { EditInvoiceWithRecordID } from '../models/EditInvoiceWithRecordID';
import type { EditInvoiceWithStatementID } from '../models/EditInvoiceWithStatementID';
import type { EditLoginCredentialRequest } from '../models/EditLoginCredentialRequest';
import type { EditOrganization } from '../models/EditOrganization';
import type { EditOrganizationBilling } from '../models/EditOrganizationBilling';
import type { EditSelfUserPreferences } from '../models/EditSelfUserPreferences';
import type { EditUser } from '../models/EditUser';
import type { EditUserClientSide } from '../models/EditUserClientSide';
import type { EditYearlyRevenue } from '../models/EditYearlyRevenue';
import type { Empty } from '../models/Empty';
import type { Facility } from '../models/Facility';
import type { FacilityAccountViewListResponse } from '../models/FacilityAccountViewListResponse';
import type { getAccountsInformationResponse } from '../models/getAccountsInformationResponse';
import type { getAdminHomeLandingInvoicesMetersResponse } from '../models/getAdminHomeLandingInvoicesMetersResponse';
import type { getAllReportsFilters } from '../models/getAllReportsFilters';
import type { getClientCostDistributionFilters } from '../models/getClientCostDistributionFilters';
import type { getClientCostDistributionReport } from '../models/getClientCostDistributionReport';
import type { getClientPeriodComparisonReport } from '../models/getClientPeriodComparisonReport';
import type { GetCommodityTypes } from '../models/GetCommodityTypes';
import type { getEmissionsRegionsAndYears } from '../models/getEmissionsRegionsAndYears';
import type { GetEmissionTransactionsResponse } from '../models/GetEmissionTransactionsResponse';
import type { getFacilityPeriodUtilizationsResponse } from '../models/getFacilityPeriodUtilizationsResponse';
import type { getGHGEmissionReport } from '../models/getGHGEmissionReport';
import type { getLandingTargetStructureData } from '../models/getLandingTargetStructureData';
import type { getMetersResponse } from '../models/getMetersResponse';
import type { getNaicsCodesResponse } from '../models/getNaicsCodesResponse';
import type { getNewClientsDraft } from '../models/getNewClientsDraft';
import type { getOrganizationStructureResponse } from '../models/getOrganizationStructureResponse';
import type { getPopulateAlertsPageResponse } from '../models/getPopulateAlertsPageResponse';
import type { getPopulateCarbonTabScopeDashboardResponse } from '../models/getPopulateCarbonTabScopeDashboardResponse';
import type { getPopulateEmissionFactors } from '../models/getPopulateEmissionFactors';
import type { getPopulateEnergyTabScopeDashboardResponse } from '../models/getPopulateEnergyTabScopeDashboardResponse';
import type { getPopulateInvoicesPageResponse } from '../models/getPopulateInvoicesPageResponse';
import type { GetPopulateInvoicesSummaryResponse } from '../models/GetPopulateInvoicesSummaryResponse';
import type { getPopulateLandingPageOverviewResponse } from '../models/getPopulateLandingPageOverviewResponse';
import type { getPopulateProductionDataPageResponse } from '../models/getPopulateProductionDataPageResponse';
import type { getPopulateUserResponse } from '../models/getPopulateUserResponse';
import type { getProductionUnitsResponse } from '../models/getProductionUnitsResponse';
import type { GetSettingsPopulateResponse } from '../models/GetSettingsPopulateResponse';
import type { getTotalCostAndUsageReport } from '../models/getTotalCostAndUsageReport';
import type { getTransactionFiltersResponse } from '../models/getTransactionFiltersResponse';
import type { getUnbundledCostReport } from '../models/getUnbundledCostReport';
import type { getUtilityAcccounts } from '../models/getUtilityAcccounts';
import type { getUtilityCompanies } from '../models/getUtilityCompanies';
import type { GetUtilityListModel } from '../models/GetUtilityListModel';
import type { getWeatherStationCode } from '../models/getWeatherStationCode';
import type { getYearlyRevenue } from '../models/getYearlyRevenue';
import type { newClientDraftResponse } from '../models/newClientDraftResponse';
import type { PerformanceReport } from '../models/PerformanceReport';
import type { PopulateInvoiceInfoModal } from '../models/PopulateInvoiceInfoModal';
import type { populateLandingPageTargetDashboardResponse } from '../models/populateLandingPageTargetDashboardResponse';
import type { postAddUserEmissionTransactionRecordResponse } from '../models/postAddUserEmissionTransactionRecordResponse';
import type { postAdminCreateNewClientSaveUpdateDraft } from '../models/postAdminCreateNewClientSaveUpdateDraft';
import type { postAlertsPageSendMessage } from '../models/postAlertsPageSendMessage';
import type { postCreateFilterLandingPageTargetDashboardresponse } from '../models/postCreateFilterLandingPageTargetDashboardresponse';
import type { postCreateMessageAlertsResponse } from '../models/postCreateMessageAlertsResponse';
import type { postEmissionTansactionRecord } from '../models/postEmissionTansactionRecord';
import type { postLandingTargetDashboardCreateFilterModel } from '../models/postLandingTargetDashboardCreateFilterModel';
import type { postTargetDashboardStructureModelResponse } from '../models/postTargetDashboardStructureModelResponse';
import type { postToInvoicesTable } from '../models/postToInvoicesTable';
import type { PostUtilityAccountNewAccount } from '../models/PostUtilityAccountNewAccount';
import type { PostUtilityAccountNewAccountResponse } from '../models/PostUtilityAccountNewAccountResponse';
import type { putClientDrafteResponse } from '../models/putClientDrafteResponse';
import type { putEditFilterLandingPageTargetDashboardResponse } from '../models/putEditFilterLandingPageTargetDashboardResponse';
import type { PutEditUtilityAccount } from '../models/PutEditUtilityAccount';
import type { putEmissionTansactionRecord } from '../models/putEmissionTansactionRecord';
import type { putLandingTargetDashboardEditFilterModel } from '../models/putLandingTargetDashboardEditFilterModel';
import type { SuccessResponse } from '../models/SuccessResponse';
import type { UpsertAccountGHGEmission } from '../models/UpsertAccountGHGEmission';
import type { upsertAccountGhgEmissionResponse } from '../models/upsertAccountGhgEmissionResponse';
import type { UpsertFacilityPeriodUtilizationRequest } from '../models/UpsertFacilityPeriodUtilizationRequest';
import type { UpsertRegionGHGEmission } from '../models/UpsertRegionGHGEmission';
import type { upsertRegionGhgEmissionResponse } from '../models/upsertRegionGhgEmissionResponse';
import type { UpsertUtilityGHGEmission } from '../models/UpsertUtilityGHGEmission';
import type { UserListResponseAdminPortal } from '../models/UserListResponseAdminPortal';
import type { UtilityAccount } from '../models/UtilityAccount';
import type { UtilityCompanyBody } from '../models/UtilityCompanyBody';
import type { WeatherDataReportResponse } from '../models/WeatherDataReportResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DefaultService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public options(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/',
        });
    }

    /**
     * @param idToken
     * @returns getPopulateAlertsPageResponse 200 response
     * @throws ApiError
     */
    public getAdminPortalAlertsPopulate(
        idToken: string
    ): CancelablePromise<getPopulateAlertsPageResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/alerts/populate',
            headers: {
                id_token: idToken,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalAlertsPopulate(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/alerts/populate',
        });
    }

    /**
     * @param idToken
     * @param organizationId
     * @returns getPopulateAlertsPageResponse 200 response
     * @throws ApiError
     */
    public getAdminPortalAlertsPopulateByOrganization(
        idToken: string,
        organizationId: string
    ): CancelablePromise<getPopulateAlertsPageResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/alerts/populate_by_organization',
            headers: {
                id_token: idToken,
                organization_id: organizationId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalAlertsPopulateByOrganization(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/alerts/populate_by_organization',
        });
    }

    /**
     * @param queryString1
     * @param header1
     * @returns Empty 200 response
     * @throws ApiError
     */
    public getAdminPortalAlertsTesting(
        queryString1: string,
        header1: string
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/alerts/testing',
            headers: {
                header1: header1,
            },
            query: {
                queryString1: queryString1,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalAlertsTesting(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/alerts/testing',
        });
    }

    /**
     * @param idToken
     * @param alertId
     * @param status
     * @returns Empty 200 response
     * @throws ApiError
     */
    public putAdminPortalAlertsUpdateAlertStatus(
        idToken: string,
        alertId: string,
        status: string
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin_portal/alerts/update_alert_status',
            headers: {
                id_token: idToken,
                alert_id: alertId,
                status: status,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalAlertsUpdateAlertStatus(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/alerts/update_alert_status',
        });
    }

    /**
     * @param idToken
     * @param postAdminCreateNewClientSaveUpdateDraft
     * @returns createNewClientResponse 200 response
     * @throws ApiError
     */
    public postAdminPortalClientCreateNewClient(
        idToken: string,
        postAdminCreateNewClientSaveUpdateDraft: postAdminCreateNewClientSaveUpdateDraft
    ): CancelablePromise<createNewClientResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin_portal/client/create_new_client',
            headers: {
                id_token: idToken,
            },
            body: postAdminCreateNewClientSaveUpdateDraft,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalClientCreateNewClient(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/client/create_new_client',
        });
    }

    /**
     * @param idToken
     * @returns getNewClientsDraft 200 response
     * @throws ApiError
     */
    public getAdminPortalClientDraft(
        idToken: string
    ): CancelablePromise<getNewClientsDraft> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/client/draft',
            headers: {
                id_token: idToken,
            },
        });
    }

    /**
     * @param idToken
     * @param postAdminCreateNewClientSaveUpdateDraft
     * @returns newClientDraftResponse 200 response
     * @throws ApiError
     */
    public postAdminPortalClientDraft(
        idToken: string,
        postAdminCreateNewClientSaveUpdateDraft: postAdminCreateNewClientSaveUpdateDraft
    ): CancelablePromise<newClientDraftResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin_portal/client/draft',
            headers: {
                id_token: idToken,
            },
            body: postAdminCreateNewClientSaveUpdateDraft,
        });
    }

    /**
     * @param idToken
     * @param draftId
     * @param postAdminCreateNewClientSaveUpdateDraft
     * @returns putClientDrafteResponse 200 response
     * @throws ApiError
     */
    public putAdminPortalClientDraft(
        idToken: string,
        draftId: string,
        postAdminCreateNewClientSaveUpdateDraft: postAdminCreateNewClientSaveUpdateDraft
    ): CancelablePromise<putClientDrafteResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin_portal/client/draft',
            headers: {
                id_token: idToken,
                draft_id: draftId,
            },
            body: postAdminCreateNewClientSaveUpdateDraft,
        });
    }

    /**
     * @param idToken
     * @param draftId
     * @returns putClientDrafteResponse 200 response
     * @throws ApiError
     */
    public deleteAdminPortalClientDraft(
        idToken: string,
        draftId: string
    ): CancelablePromise<putClientDrafteResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/admin_portal/client/draft',
            headers: {
                id_token: idToken,
                draft_id: draftId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalClientDraft(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/client/draft',
        });
    }

    /**
     * @param idToken
     * @param editDivision
     * @returns Empty 200 response
     * @throws ApiError
     */
    public putAdminPortalClientManageEditDivision(
        idToken: string,
        editDivision: EditDivision
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin_portal/client/manage/edit_division',
            headers: {
                id_token: idToken,
            },
            body: editDivision,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalClientManageEditDivision(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/client/manage/edit_division',
        });
    }

    /**
     * @param idToken
     * @param editFacility
     * @returns Empty 200 response
     * @throws ApiError
     */
    public putAdminPortalClientManageEditFacility(
        idToken: string,
        editFacility: EditFacility
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin_portal/client/manage/edit_facility',
            headers: {
                id_token: idToken,
            },
            body: editFacility,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalClientManageEditFacility(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/client/manage/edit_facility',
        });
    }

    /**
     * @param idToken
     * @param editGroup
     * @returns Empty 200 response
     * @throws ApiError
     */
    public putAdminPortalClientManageEditGroup(
        idToken: string,
        editGroup: EditGroup
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin_portal/client/manage/edit_group',
            headers: {
                id_token: idToken,
            },
            body: editGroup,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalClientManageEditGroup(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/client/manage/edit_group',
        });
    }

    /**
     * @param idToken
     * @param editOrganization
     * @returns Empty 200 response
     * @throws ApiError
     */
    public putAdminPortalClientManageEditOrganization(
        idToken: string,
        editOrganization: EditOrganization
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin_portal/client/manage/edit_organization',
            headers: {
                id_token: idToken,
            },
            body: editOrganization,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalClientManageEditOrganization(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/client/manage/edit_organization',
        });
    }

    /**
     * @param idToken
     * @param editOrganizationBilling
     * @returns Empty 200 response
     * @throws ApiError
     */
    public putAdminPortalClientManageEditOrganizationBilling(
        idToken: string,
        editOrganizationBilling: EditOrganizationBilling
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin_portal/client/manage/edit_organization_billing',
            headers: {
                id_token: idToken,
            },
            body: editOrganizationBilling,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalClientManageEditOrganizationBilling(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/client/manage/edit_organization_billing',
        });
    }

    /**
     * @param idToken
     * @param editUser
     * @returns Empty 200 response
     * @throws ApiError
     */
    public putAdminPortalClientManageEditUser(
        idToken: string,
        editUser: EditUser
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin_portal/client/manage/edit_user',
            headers: {
                id_token: idToken,
            },
            body: editUser,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalClientManageEditUser(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/client/manage/edit_user',
        });
    }

    /**
     * @param idToken
     * @param addDivisionAdminSide
     * @returns Empty 200 response
     * @throws ApiError
     */
    public postAdminPortalClientManageNewDivision(
        idToken: string,
        addDivisionAdminSide: AddDivisionAdminSide
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin_portal/client/manage/new_division',
            headers: {
                id_token: idToken,
            },
            body: addDivisionAdminSide,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalClientManageNewDivision(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/client/manage/new_division',
        });
    }

    /**
     * @param idToken
     * @param addFacility
     * @returns Empty 200 response
     * @throws ApiError
     */
    public postAdminPortalClientManageNewFacility(
        idToken: string,
        addFacility: AddFacility
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin_portal/client/manage/new_facility',
            headers: {
                id_token: idToken,
            },
            body: addFacility,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalClientManageNewFacility(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/client/manage/new_facility',
        });
    }

    /**
     * @param idToken
     * @param addGroup
     * @returns Empty 200 response
     * @throws ApiError
     */
    public postAdminPortalClientManageNewGroup(
        idToken: string,
        addGroup: AddGroup
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin_portal/client/manage/new_group',
            headers: {
                id_token: idToken,
            },
            body: addGroup,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalClientManageNewGroup(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/client/manage/new_group',
        });
    }

    /**
     * @param idToken
     * @returns getNaicsCodesResponse 200 response
     * @throws ApiError
     */
    public getAdminPortalClientNaicsCodes(
        idToken: string
    ): CancelablePromise<getNaicsCodesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/client/naics_codes',
            headers: {
                id_token: idToken,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalClientNaicsCodes(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/client/naics_codes',
        });
    }

    /**
     * @param idToken
     * @param organizationId
     * @param facilityId
     * @param divisionId
     * @param groupId
     * @returns getPopulateCarbonTabScopeDashboardResponse 200 response
     * @throws ApiError
     */
    public getAdminPortalDashboardsCarbonTab(
        idToken: string,
        organizationId: string,
        facilityId?: string,
        divisionId?: string,
        groupId?: string
    ): CancelablePromise<getPopulateCarbonTabScopeDashboardResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/dashboards/carbon_tab',
            headers: {
                id_token: idToken,
                facility_id: facilityId,
                division_id: divisionId,
                group_id: groupId,
                organization_id: organizationId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalDashboardsCarbonTab(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/dashboards/carbon_tab',
        });
    }

    /**
     * @param idToken
     * @param organizationId
     * @param facilityId
     * @param divisionId
     * @param groupId
     * @returns getPopulateEnergyTabScopeDashboardResponse 200 response
     * @throws ApiError
     */
    public getAdminPortalDashboardsEnergyTab(
        idToken: string,
        organizationId: string,
        facilityId?: string,
        divisionId?: string,
        groupId?: string
    ): CancelablePromise<getPopulateEnergyTabScopeDashboardResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/dashboards/energy_tab',
            headers: {
                id_token: idToken,
                facility_id: facilityId,
                division_id: divisionId,
                group_id: groupId,
                organization_id: organizationId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalDashboardsEnergyTab(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/dashboards/energy_tab',
        });
    }

    /**
     * @param idToken
     * @param organizationId
     * @returns getPopulateLandingPageOverviewResponse 200 response
     * @throws ApiError
     */
    public getAdminPortalDashboardsOverviewDashboard(
        idToken: string,
        organizationId: string
    ): CancelablePromise<getPopulateLandingPageOverviewResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/dashboards/overview_dashboard',
            headers: {
                id_token: idToken,
                organization_id: organizationId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalDashboardsOverviewDashboard(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/dashboards/overview_dashboard',
        });
    }

    /**
     * @param idToken
     * @param region
     * @returns getEmissionsRegionsAndYears 200 response
     * @throws ApiError
     */
    public getAdminPortalEmissionFactorsGetRegionsAndYears(
        idToken: string,
        region?: string
    ): CancelablePromise<getEmissionsRegionsAndYears> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/emission_factors/get_regions_and_years',
            headers: {
                id_token: idToken,
                region: region,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalEmissionFactorsGetRegionsAndYears(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/emission_factors/get_regions_and_years',
        });
    }

    /**
     * @param idToken
     * @param year
     * @param region
     * @param accountId
     * @param utilityId
     * @returns getPopulateEmissionFactors 200 response
     * @throws ApiError
     */
    public getAdminPortalEmissionFactorsPopulate(
        idToken: string,
        year: string,
        region?: string,
        accountId?: string,
        utilityId?: string
    ): CancelablePromise<getPopulateEmissionFactors> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/emission_factors/populate',
            headers: {
                region: region,
                id_token: idToken,
                account_id: accountId,
                utility_id: utilityId,
                year: year,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalEmissionFactorsPopulate(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/emission_factors/populate',
        });
    }

    /**
     * @param idToken
     * @param upsertAccountGhgEmission
     * @returns upsertAccountGhgEmissionResponse 200 response
     * @throws ApiError
     */
    public postAdminPortalEmissionFactorsUpsertAccountGhgEmissionFactors(
        idToken: string,
        upsertAccountGhgEmission: UpsertAccountGHGEmission
    ): CancelablePromise<upsertAccountGhgEmissionResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin_portal/emission_factors/upsert_account_ghg_emission_factors',
            headers: {
                id_token: idToken,
            },
            body: upsertAccountGhgEmission,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalEmissionFactorsUpsertAccountGhgEmissionFactors(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/emission_factors/upsert_account_ghg_emission_factors',
        });
    }

    /**
     * @param idToken
     * @param upsertRegionGhgEmission
     * @returns upsertRegionGhgEmissionResponse 200 response
     * @throws ApiError
     */
    public postAdminPortalEmissionFactorsUpsertRegionGhgEmissionFactors(
        idToken: string,
        upsertRegionGhgEmission: UpsertRegionGHGEmission
    ): CancelablePromise<upsertRegionGhgEmissionResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin_portal/emission_factors/upsert_region_ghg_emission_factors',
            headers: {
                id_token: idToken,
            },
            body: upsertRegionGhgEmission,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalEmissionFactorsUpsertRegionGhgEmissionFactors(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/emission_factors/upsert_region_ghg_emission_factors',
        });
    }

    /**
     * @param idToken
     * @param organizationId
     * @returns GetEmissionTransactionsResponse 200 response
     * @throws ApiError
     */
    public getAdminPortalEmissionTransactions(
        idToken: string,
        organizationId: string
    ): CancelablePromise<GetEmissionTransactionsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/emission_transactions',
            headers: {
                id_token: idToken,
                organization_id: organizationId,
            },
        });
    }

    /**
     * @param idToken
     * @param organizationId
     * @param postEmissionTansactionRecord
     * @returns postAddUserEmissionTransactionRecordResponse 200 response
     * @throws ApiError
     */
    public postAdminPortalEmissionTransactions(
        idToken: string,
        organizationId: string,
        postEmissionTansactionRecord: postEmissionTansactionRecord
    ): CancelablePromise<postAddUserEmissionTransactionRecordResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin_portal/emission_transactions',
            headers: {
                id_token: idToken,
                organization_id: organizationId,
            },
            body: postEmissionTansactionRecord,
        });
    }

    /**
     * @param idToken
     * @param putEmissionTansactionRecord
     * @returns Empty 200 response
     * @throws ApiError
     */
    public putAdminPortalEmissionTransactions(
        idToken: string,
        putEmissionTansactionRecord: putEmissionTansactionRecord
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin_portal/emission_transactions',
            headers: {
                id_token: idToken,
            },
            body: putEmissionTansactionRecord,
        });
    }

    /**
     * @param transactionId
     * @param idToken
     * @returns Empty 200 response
     * @throws ApiError
     */
    public deleteAdminPortalEmissionTransactions(
        transactionId: string,
        idToken: string
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/admin_portal/emission_transactions',
            headers: {
                transaction_id: transactionId,
                id_token: idToken,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalEmissionTransactions(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/emission_transactions',
        });
    }

    /**
     * @param idToken
     * @param organizationId
     * @returns getTransactionFiltersResponse 200 response
     * @throws ApiError
     */
    public getAdminPortalEmissionTransactionsFilters(
        idToken: string,
        organizationId: string
    ): CancelablePromise<getTransactionFiltersResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/emission_transactions/filters',
            headers: {
                id_token: idToken,
                organization_id: organizationId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalEmissionTransactionsFilters(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/emission_transactions/filters',
        });
    }

    /**
     * @param transactionId
     * @param idToken
     * @param status
     * @returns Empty 200 response
     * @throws ApiError
     */
    public putAdminPortalEmissionTransactionsStatus(
        transactionId: string,
        idToken: string,
        status: string
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin_portal/emission_transactions/status',
            headers: {
                transaction_id: transactionId,
                id_token: idToken,
                status: status,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalEmissionTransactionsStatus(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/emission_transactions/status',
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalFacilities(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/facilities',
        });
    }

    /**
     * @param idToken
     * @param project
     * @returns Facility 200 response
     * @throws ApiError
     */
    public getAdminPortalFacilitiesListFacility(
        idToken: string,
        project?: string
    ): CancelablePromise<Facility> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/facilities/list_facility',
            headers: {
                id_token: idToken,
            },
            query: {
                project: project,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalFacilitiesListFacility(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/facilities/list_facility',
        });
    }

    /**
     * @param idToken
     * @param accountId
     * @returns getAccountsInformationResponse 200 response
     * @throws ApiError
     */
    public getAdminPortalHomeLandingAccountInformation(
        idToken: string,
        accountId: string
    ): CancelablePromise<getAccountsInformationResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/home_landing/account_information',
            headers: {
                id_token: idToken,
                account_id: accountId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalHomeLandingAccountInformation(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/home_landing/account_information',
        });
    }

    /**
     * @param idToken
     * @param organizationId
     * @returns FacilityAccountViewListResponse 200 response
     * @throws ApiError
     */
    public getAdminPortalHomeLandingFacilitiesAccountsPopulate(
        idToken: string,
        organizationId?: string
    ): CancelablePromise<FacilityAccountViewListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/home_landing/facilities_accounts/populate',
            headers: {
                id_token: idToken,
                organization_id: organizationId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalHomeLandingFacilitiesAccountsPopulate(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/home_landing/facilities_accounts/populate',
        });
    }

    /**
     * @param idToken
     * @param accountId
     * @returns getAdminHomeLandingInvoicesMetersResponse 200 response
     * @throws ApiError
     */
    public getAdminPortalHomeLandingInvoicesMeters(
        idToken: string,
        accountId: string
    ): CancelablePromise<getAdminHomeLandingInvoicesMetersResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/home_landing/invoices_meters',
            headers: {
                id_token: idToken,
                account_id: accountId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalHomeLandingInvoicesMeters(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/home_landing/invoices_meters',
        });
    }

    /**
     * @param idToken
     * @returns AdminLandingPageResponse 200 response
     * @throws ApiError
     */
    public getAdminPortalHomeLandingSummary(
        idToken: string
    ): CancelablePromise<AdminLandingPageResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/home_landing/summary',
            headers: {
                id_token: idToken,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalHomeLandingSummary(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/home_landing/summary',
        });
    }

    /**
     * @param idToken
     * @param addAccountRecord
     * @returns Empty 200 response
     * @throws ApiError
     */
    public postAdminPortalInvoicesAddAccountRecord(
        idToken: string,
        addAccountRecord: AddAccountRecord
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin_portal/invoices/add_account_record',
            headers: {
                id_token: idToken,
            },
            body: addAccountRecord,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalInvoicesAddAccountRecord(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/invoices/add_account_record',
        });
    }

    /**
     * @param idToken
     * @param statementId
     * @returns Empty 200 response
     * @throws ApiError
     */
    public deleteAdminPortalInvoicesDeleteFromInvoiceTable(
        idToken: string,
        statementId: string
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/admin_portal/invoices/delete_from_invoice_table',
            headers: {
                id_token: idToken,
                statement_id: statementId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalInvoicesDeleteFromInvoiceTable(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/invoices/delete_from_invoice_table',
        });
    }

    /**
     * @param idToken
     * @param recordId
     * @returns Empty 200 response
     * @throws ApiError
     */
    public deleteAdminPortalInvoicesDeleteInvoiceRecord(
        idToken: string,
        recordId: string
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/admin_portal/invoices/delete_invoice_record',
            headers: {
                id_token: idToken,
                record_id: recordId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalInvoicesDeleteInvoiceRecord(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/invoices/delete_invoice_record',
        });
    }

    /**
     * @param idToken
     * @param editInvoiceWithRecordId
     * @returns Empty 200 response
     * @throws ApiError
     */
    public putAdminPortalInvoicesEditInvoiceByRecordId(
        idToken: string,
        editInvoiceWithRecordId: EditInvoiceWithRecordID
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin_portal/invoices/edit_invoice_by_record_id',
            headers: {
                id_token: idToken,
            },
            body: editInvoiceWithRecordId,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalInvoicesEditInvoiceByRecordId(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/invoices/edit_invoice_by_record_id',
        });
    }

    /**
     * @param idToken
     * @param editInvoiceWithStatementId
     * @returns Empty 200 response
     * @throws ApiError
     */
    public putAdminPortalInvoicesEditInvoiceByStatementId(
        idToken: string,
        editInvoiceWithStatementId: EditInvoiceWithStatementID
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin_portal/invoices/edit_invoice_by_statement_id',
            headers: {
                id_token: idToken,
            },
            body: editInvoiceWithStatementId,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalInvoicesEditInvoiceByStatementId(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/invoices/edit_invoice_by_statement_id',
        });
    }

    /**
     * @param idToken
     * @param statementId
     * @returns PopulateInvoiceInfoModal 200 response
     * @throws ApiError
     */
    public getAdminPortalInvoicesInvoiceInfoModal(
        idToken: string,
        statementId: string
    ): CancelablePromise<PopulateInvoiceInfoModal> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/invoices/invoice_info_modal',
            headers: {
                id_token: idToken,
                statement_id: statementId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalInvoicesInvoiceInfoModal(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/invoices/invoice_info_modal',
        });
    }

    /**
     * @param idToken
     * @param organizationId
     * @returns GetPopulateInvoicesSummaryResponse 200 response
     * @throws ApiError
     */
    public getAdminPortalInvoicesInvoicesSummaryTab(
        idToken: string,
        organizationId: string
    ): CancelablePromise<GetPopulateInvoicesSummaryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/invoices/invoices_summary_tab',
            headers: {
                id_token: idToken,
                organization_id: organizationId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalInvoicesInvoicesSummaryTab(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/invoices/invoices_summary_tab',
        });
    }

    /**
     * @param year
     * @param idToken
     * @param organizationId
     * @returns getPopulateInvoicesPageResponse 200 response
     * @throws ApiError
     */
    public getAdminPortalInvoicesPopulate(
        year: string,
        idToken: string,
        organizationId: string
    ): CancelablePromise<getPopulateInvoicesPageResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/invoices/populate',
            headers: {
                year: year,
                id_token: idToken,
                organization_id: organizationId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalInvoicesPopulate(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/invoices/populate',
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalReports(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/reports',
        });
    }

    /**
     * @param idToken
     * @param startDate
     * @param endDate
     * @param organizationId
     * @param divisionId
     * @param facilityId
     * @param country
     * @param accountId
     * @param commodityId
     * @param utilityId
     * @param groupId
     * @returns getClientCostDistributionReport 200 response
     * @throws ApiError
     */
    public getAdminPortalReportsCostDistribution(
        idToken: string,
        startDate: string,
        endDate: string,
        organizationId: string,
        divisionId?: string,
        facilityId?: string,
        country?: string,
        accountId?: string,
        commodityId?: string,
        utilityId?: string,
        groupId?: string
    ): CancelablePromise<getClientCostDistributionReport> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/reports/cost_distribution',
            headers: {
                id_token: idToken,
                division_id: divisionId,
                facility_id: facilityId,
                country: country,
                account_id: accountId,
                start_date: startDate,
                end_date: endDate,
                commodity_id: commodityId,
                utility_id: utilityId,
                group_id: groupId,
                organization_id: organizationId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalReportsCostDistribution(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/reports/cost_distribution',
        });
    }

    /**
     * @param idToken
     * @param startDate
     * @param endDate
     * @param organizationId
     * @param country
     * @param divisionId
     * @param facilityId
     * @param accountId
     * @param commodityId
     * @param utilityId
     * @param groupId
     * @returns CreditsReportList 200 response
     * @throws ApiError
     */
    public getAdminPortalReportsCreditsReport(
        idToken: string,
        startDate: string,
        endDate: string,
        organizationId: string,
        country?: string,
        divisionId?: string,
        facilityId?: string,
        accountId?: string,
        commodityId?: string,
        utilityId?: string,
        groupId?: string
    ): CancelablePromise<CreditsReportList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/reports/credits_report',
            headers: {
                country: country,
                division_id: divisionId,
                facility_id: facilityId,
                id_token: idToken,
                account_id: accountId,
                start_date: startDate,
                end_date: endDate,
                commodity_id: commodityId,
                utility_id: utilityId,
                group_id: groupId,
                organization_id: organizationId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalReportsCreditsReport(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/reports/credits_report',
        });
    }

    /**
     * @param idToken
     * @param organizationId
     * @returns getAllReportsFilters 200 response
     * @throws ApiError
     */
    public getAdminPortalReportsGetAllReportsFilters(
        idToken: string,
        organizationId: string
    ): CancelablePromise<getAllReportsFilters> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/reports/get_all_reports_filters',
            headers: {
                id_token: idToken,
                organization_id: organizationId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalReportsGetAllReportsFilters(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/reports/get_all_reports_filters',
        });
    }

    /**
     * @param idToken
     * @param startDate
     * @param endDate
     * @param organizationId
     * @param divisionId
     * @param facilityId
     * @param groupId
     * @returns getGHGEmissionReport 200 response
     * @throws ApiError
     */
    public getAdminPortalReportsGhgEmissionReport(
        idToken: string,
        startDate: string,
        endDate: string,
        organizationId: string,
        divisionId?: string,
        facilityId?: string,
        groupId?: string
    ): CancelablePromise<getGHGEmissionReport> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/reports/ghg_emission_report',
            headers: {
                id_token: idToken,
                division_id: divisionId,
                facility_id: facilityId,
                start_date: startDate,
                end_date: endDate,
                group_id: groupId,
                organization_id: organizationId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalReportsGhgEmissionReport(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/reports/ghg_emission_report',
        });
    }

    /**
     * @param facilityId
     * @param idToken
     * @param inDate
     * @returns PerformanceReport 200 response
     * @throws ApiError
     */
    public getAdminPortalReportsPerformanceReport(
        facilityId: string,
        idToken: string,
        inDate: string
    ): CancelablePromise<PerformanceReport> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/reports/performance_report',
            headers: {
                facility_id: facilityId,
                id_token: idToken,
                in_date: inDate,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalReportsPerformanceReport(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/reports/performance_report',
        });
    }

    /**
     * @param idToken
     * @param startDate
     * @param organizationId
     * @param endDate
     * @param facilityId
     * @param commodityId
     * @returns getClientPeriodComparisonReport 200 response
     * @throws ApiError
     */
    public getAdminPortalReportsPeriodComparison(
        idToken: string,
        startDate: string,
        organizationId: string,
        endDate: string,
        facilityId?: string,
        commodityId?: string
    ): CancelablePromise<getClientPeriodComparisonReport> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/reports/period_comparison',
            headers: {
                facility_id: facilityId,
                id_token: idToken,
                commodity_id: commodityId,
                start_date: startDate,
                organization_id: organizationId,
                end_date: endDate,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalReportsPeriodComparison(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/reports/period_comparison',
        });
    }

    /**
     * @param idToken
     * @param startDate
     * @param endDate
     * @param organizationId
     * @param country
     * @param divisionId
     * @param facilityId
     * @param commodityId
     * @param groupId
     * @returns getTotalCostAndUsageReport 200 response
     * @throws ApiError
     */
    public getAdminPortalReportsTotalCostAndUsage(
        idToken: string,
        startDate: string,
        endDate: string,
        organizationId: string,
        country?: string,
        divisionId?: string,
        facilityId?: string,
        commodityId?: string,
        groupId?: string
    ): CancelablePromise<getTotalCostAndUsageReport> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/reports/total_cost_and_usage',
            headers: {
                country: country,
                division_id: divisionId,
                facility_id: facilityId,
                id_token: idToken,
                start_date: startDate,
                end_date: endDate,
                commodity_id: commodityId,
                group_id: groupId,
                organization_id: organizationId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalReportsTotalCostAndUsage(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/reports/total_cost_and_usage',
        });
    }

    /**
     * @param idToken
     * @param startDate
     * @param endDate
     * @param organizationId
     * @param divisionId
     * @param facilityId
     * @param country
     * @param commodityId
     * @param groupId
     * @returns getUnbundledCostReport 200 response
     * @throws ApiError
     */
    public getAdminPortalReportsUnbundledCostReport(
        idToken: string,
        startDate: string,
        endDate: string,
        organizationId: string,
        divisionId?: string,
        facilityId?: string,
        country?: string,
        commodityId?: string,
        groupId?: string
    ): CancelablePromise<getUnbundledCostReport> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/reports/unbundled_cost_report',
            headers: {
                id_token: idToken,
                division_id: divisionId,
                facility_id: facilityId,
                country: country,
                start_date: startDate,
                end_date: endDate,
                commodity_id: commodityId,
                group_id: groupId,
                organization_id: organizationId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalReportsUnbundledCostReport(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/reports/unbundled_cost_report',
        });
    }

    /**
     * @param idToken
     * @param startDate
     * @param endDate
     * @param organizationId
     * @param divisionId
     * @param facilityId
     * @param groupId
     * @returns WeatherDataReportResponse 200 response
     * @throws ApiError
     */
    public getAdminPortalReportsWeatherDataReport(
        idToken: string,
        startDate: string,
        endDate: string,
        organizationId: string,
        divisionId?: string,
        facilityId?: string,
        groupId?: string
    ): CancelablePromise<WeatherDataReportResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/reports/weather_data_report',
            headers: {
                division_id: divisionId,
                facility_id: facilityId,
                id_token: idToken,
                start_date: startDate,
                end_date: endDate,
                group_id: groupId,
                organization_id: organizationId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalReportsWeatherDataReport(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/reports/weather_data_report',
        });
    }

    /**
     * @param idToken
     * @param organizationId
     * @returns getOrganizationStructureResponse 200 response
     * @throws ApiError
     */
    public getAdminPortalUsersGetOrganizationStructure(
        idToken: string,
        organizationId: string
    ): CancelablePromise<getOrganizationStructureResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/users/get_organization_structure',
            headers: {
                id_token: idToken,
                organization_id: organizationId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalUsersGetOrganizationStructure(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/users/get_organization_structure',
        });
    }

    /**
     * @param idToken
     * @param adminAddClientUserToOrganization
     * @returns Empty 200 response
     * @throws ApiError
     */
    public postAdminPortalUsersManagementAddClientUserToOrganization(
        idToken: string,
        adminAddClientUserToOrganization: AdminAddClientUserToOrganization
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin_portal/users/management/add_client_user_to_organization',
            headers: {
                id_token: idToken,
            },
            body: adminAddClientUserToOrganization,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalUsersManagementAddClientUserToOrganization(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/users/management/add_client_user_to_organization',
        });
    }

    /**
     * @param idToken
     * @param userId
     * @returns Empty 200 response
     * @throws ApiError
     */
    public deleteAdminPortalUsersManagementDeleteAdminUser(
        idToken: string,
        userId: string
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/admin_portal/users/management/delete_admin_user',
            headers: {
                id_token: idToken,
                user_id: userId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalUsersManagementDeleteAdminUser(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/users/management/delete_admin_user',
        });
    }

    /**
     * @param idToken
     * @param userId
     * @returns Empty 200 response
     * @throws ApiError
     */
    public deleteAdminPortalUsersManagementDeleteClientUser(
        idToken: string,
        userId: string
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/admin_portal/users/management/delete_client_user',
            headers: {
                id_token: idToken,
                user_id: userId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalUsersManagementDeleteClientUser(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/users/management/delete_client_user',
        });
    }

    /**
     * @param idToken
     * @param userId
     * @returns Empty 200 response
     * @throws ApiError
     */
    public putAdminPortalUsersManagementDisableAdminUser(
        idToken: string,
        userId: string
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin_portal/users/management/disable_admin_user',
            headers: {
                id_token: idToken,
                user_id: userId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalUsersManagementDisableAdminUser(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/users/management/disable_admin_user',
        });
    }

    /**
     * @param idToken
     * @param userId
     * @returns Empty 200 response
     * @throws ApiError
     */
    public putAdminPortalUsersManagementDisableClientUser(
        idToken: string,
        userId: string
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin_portal/users/management/disable_client_user',
            headers: {
                id_token: idToken,
                user_id: userId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalUsersManagementDisableClientUser(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/users/management/disable_client_user',
        });
    }

    /**
     * @param idToken
     * @param editAdminUserPermissions
     * @returns Empty 200 response
     * @throws ApiError
     */
    public putAdminPortalUsersManagementEditAdminPermissions(
        idToken: string,
        editAdminUserPermissions: EditAdminUserPermissions
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin_portal/users/management/edit_admin_permissions',
            headers: {
                id_token: idToken,
            },
            body: editAdminUserPermissions,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalUsersManagementEditAdminPermissions(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/users/management/edit_admin_permissions',
        });
    }

    /**
     * @param idToken
     * @param userId
     * @returns Empty 200 response
     * @throws ApiError
     */
    public putAdminPortalUsersManagementEnableAdminUser(
        idToken: string,
        userId: string
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin_portal/users/management/enable_admin_user',
            headers: {
                id_token: idToken,
                user_id: userId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalUsersManagementEnableAdminUser(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/users/management/enable_admin_user',
        });
    }

    /**
     * @param idToken
     * @param userId
     * @returns Empty 200 response
     * @throws ApiError
     */
    public putAdminPortalUsersManagementEnableClientUser(
        idToken: string,
        userId: string
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin_portal/users/management/enable_client_user',
            headers: {
                id_token: idToken,
                user_id: userId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalUsersManagementEnableClientUser(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/users/management/enable_client_user',
        });
    }

    /**
     * @param idToken
     * @returns Empty 200 response
     * @throws ApiError
     */
    public postAdminPortalUsersManagementNewAdminPortalUser(
        idToken: string
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin_portal/users/management/new_admin_portal_user',
            headers: {
                id_token: idToken,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalUsersManagementNewAdminPortalUser(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/users/management/new_admin_portal_user',
        });
    }

    /**
     * @param idToken
     * @returns UserListResponseAdminPortal 200 response
     * @throws ApiError
     */
    public getAdminPortalUsersManagementPopulate(
        idToken: string
    ): CancelablePromise<UserListResponseAdminPortal> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/users/management/populate',
            headers: {
                id_token: idToken,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalUsersManagementPopulate(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/users/management/populate',
        });
    }

    /**
     * @param idToken
     * @param utilityCompanyBody
     * @returns createNewUtilityResponse 200 response
     * @throws ApiError
     */
    public postAdminPortalUtilityNewUtilityCompany(
        idToken: string,
        utilityCompanyBody: UtilityCompanyBody
    ): CancelablePromise<createNewUtilityResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin_portal/utility/new_utility_company',
            headers: {
                id_token: idToken,
            },
            body: utilityCompanyBody,
        });
    }

    /**
     * @param idToken
     * @param utilityCompanyBody
     * @returns Empty 200 response
     * @throws ApiError
     */
    public putAdminPortalUtilityNewUtilityCompany(
        idToken: string,
        utilityCompanyBody: UtilityCompanyBody
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin_portal/utility/new_utility_company',
            headers: {
                id_token: idToken,
            },
            body: utilityCompanyBody,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalUtilityNewUtilityCompany(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/utility/new_utility_company',
        });
    }

    /**
     * @param idToken
     * @param utilityId
     * @param utilityName
     * @returns GetUtilityListModel 200 response
     * @throws ApiError
     */
    public getAdminPortalUtilityPopulate(
        idToken: string,
        utilityId?: string,
        utilityName?: string
    ): CancelablePromise<GetUtilityListModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/utility/populate',
            headers: {
                id_token: idToken,
                utility_id: utilityId,
                utility_name: utilityName,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalUtilityPopulate(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/utility/populate',
        });
    }

    /**
     * @param idToken
     * @param utilityId
     * @returns GetUtilityListModel 200 response
     * @throws ApiError
     */
    public getAdminPortalUtilityUtilityById(
        idToken: string,
        utilityId: string
    ): CancelablePromise<GetUtilityListModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/utility/utility_by_id',
            headers: {
                id_token: idToken,
                utility_id: utilityId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalUtilityUtilityById(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/utility/utility_by_id',
        });
    }

    /**
     * @param idToken
     * @param upsertUtilityGhgEmission
     * @returns UpsertUtilityGHGEmission 200 response
     * @throws ApiError
     */
    public postAdminPortalUtilityUtilityGhgEmission(
        idToken: string,
        upsertUtilityGhgEmission: UpsertUtilityGHGEmission
    ): CancelablePromise<UpsertUtilityGHGEmission> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin_portal/utility/utility_ghg_emission',
            headers: {
                id_token: idToken,
            },
            body: upsertUtilityGhgEmission,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalUtilityUtilityGhgEmission(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/utility/utility_ghg_emission',
        });
    }

    /**
     * @param idToken
     * @param facilityId
     * @param utilityId
     * @param organizationId
     * @returns getUtilityAcccounts 200 response
     * @throws ApiError
     */
    public getAdminPortalUtilityAccountAccountsList(
        idToken: string,
        facilityId?: string,
        utilityId?: string,
        organizationId?: string
    ): CancelablePromise<getUtilityAcccounts> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/utility_account/accounts_list',
            headers: {
                facility_id: facilityId,
                id_token: idToken,
                utility_id: utilityId,
                organization_id: organizationId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalUtilityAccountAccountsList(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/utility_account/accounts_list',
        });
    }

    /**
     * @param idToken
     * @param putEditUtilityAccount
     * @returns Empty 200 response
     * @throws ApiError
     */
    public putAdminPortalUtilityAccountEditAccount(
        idToken: string,
        putEditUtilityAccount: PutEditUtilityAccount
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin_portal/utility_account/edit_account',
            headers: {
                id_token: idToken,
            },
            body: putEditUtilityAccount,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalUtilityAccountEditAccount(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/utility_account/edit_account',
        });
    }

    /**
     * @param idToken
     * @param project
     * @returns UtilityAccount 200 response
     * @throws ApiError
     */
    public getAdminPortalUtilityAccountListAccounts(
        idToken: string,
        project?: string
    ): CancelablePromise<UtilityAccount> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_portal/utility_account/list_accounts',
            headers: {
                id_token: idToken,
            },
            query: {
                project: project,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalUtilityAccountListAccounts(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/utility_account/list_accounts',
        });
    }

    /**
     * @param idToken
     * @param postUtilityAccountNewAccount
     * @returns PostUtilityAccountNewAccountResponse 200 response
     * @throws ApiError
     */
    public postAdminPortalUtilityAccountNewAccount(
        idToken: string,
        postUtilityAccountNewAccount: PostUtilityAccountNewAccount
    ): CancelablePromise<PostUtilityAccountNewAccountResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin_portal/utility_account/new_account',
            headers: {
                id_token: idToken,
            },
            body: postUtilityAccountNewAccount,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsAdminPortalUtilityAccountNewAccount(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admin_portal/utility_account/new_account',
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsBackend(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/backend',
        });
    }

    /**
     * @param idToken
     * @param statementId
     * @param password
     * @returns Empty 200 response
     * @throws ApiError
     */
    public deleteBackendDeleteFromInvoiceTable(
        idToken: string,
        statementId: string,
        password?: string
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/backend/delete_from_invoice_table',
            headers: {
                id_token: idToken,
                statement_id: statementId,
                password: password,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsBackendDeleteFromInvoiceTable(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/backend/delete_from_invoice_table',
        });
    }

    /**
     * @param invoiceAccountId
     * @returns Empty 200 response
     * @throws ApiError
     */
    public getBackendGetAccountInfo(
        invoiceAccountId: string
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/backend/get_account_info',
            headers: {
                invoice_account_id: invoiceAccountId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsBackendGetAccountInfo(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/backend/get_account_info',
        });
    }

    /**
     * @param commodityName
     * @returns GetCommodityTypes 200 response
     * @throws ApiError
     */
    public getBackendGetCommodityTypes(
        commodityName?: string
    ): CancelablePromise<GetCommodityTypes> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/backend/get_commodity_types',
            headers: {
                commodity_name: commodityName,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsBackendGetCommodityTypes(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/backend/get_commodity_types',
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public getBackendGetInvoiceCategories(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/backend/get_invoice_categories',
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsBackendGetInvoiceCategories(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/backend/get_invoice_categories',
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public getBackendGetInvoiceColumns(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/backend/get_invoice_columns',
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsBackendGetInvoiceColumns(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/backend/get_invoice_columns',
        });
    }

    /**
     * @param meterNumber
     * @param accountId
     * @param organizationId
     * @returns getMetersResponse 200 response
     * @throws ApiError
     */
    public getBackendGetMeters(
        meterNumber?: string,
        accountId?: string,
        organizationId?: string
    ): CancelablePromise<getMetersResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/backend/get_meters',
            headers: {
                meter_number: meterNumber,
                account_id: accountId,
                organization_id: organizationId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsBackendGetMeters(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/backend/get_meters',
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public getBackendGetOrganizations(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/backend/get_organizations',
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsBackendGetOrganizations(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/backend/get_organizations',
        });
    }

    /**
     * @returns getUtilityCompanies 200 response
     * @throws ApiError
     */
    public getBackendGetUtilityCompanies(): CancelablePromise<getUtilityCompanies> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/backend/get_utility_companies',
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsBackendGetUtilityCompanies(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/backend/get_utility_companies',
        });
    }

    /**
     * @param getWeatherStationCode
     * @returns Empty 200 response
     * @throws ApiError
     */
    public getBackendGetWeatherStationIdFromAddress(
        getWeatherStationCode: getWeatherStationCode
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/backend/get_weather_station_id_from_address',
            body: getWeatherStationCode,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsBackendGetWeatherStationIdFromAddress(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/backend/get_weather_station_id_from_address',
        });
    }

    /**
     * @param postToInvoicesTable
     * @returns Empty 200 response
     * @throws ApiError
     */
    public postBackendPostToInvoiceTable(
        postToInvoicesTable: postToInvoicesTable
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/backend/post_to_invoice_table',
            body: postToInvoicesTable,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsBackendPostToInvoiceTable(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/backend/post_to_invoice_table',
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortal(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal',
        });
    }

    /**
     * @param idToken
     * @returns getFacilityPeriodUtilizationsResponse 200 response
     * @throws ApiError
     */
    public getClientPortalAdminAndSettingsOperationalInputFacilitiesPeriodUtilizations(
        idToken: string
    ): CancelablePromise<getFacilityPeriodUtilizationsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/client_portal/admin_and_settings/operational_input/facilities_period_utilizations',
            headers: {
                id_token: idToken,
            },
        });
    }

    /**
     * @param idToken
     * @param upsertFacilityPeriodUtilizationRequest
     * @returns Empty 200 response
     * @throws ApiError
     */
    public postClientPortalAdminAndSettingsOperationalInputFacilitiesPeriodUtilizations(
        idToken: string,
        upsertFacilityPeriodUtilizationRequest: UpsertFacilityPeriodUtilizationRequest
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/client_portal/admin_and_settings/operational_input/facilities_period_utilizations',
            headers: {
                id_token: idToken,
            },
            body: upsertFacilityPeriodUtilizationRequest,
        });
    }

    /**
     * @param idToken
     * @param utilPeriodId
     * @returns Empty 200 response
     * @throws ApiError
     */
    public deleteClientPortalAdminAndSettingsOperationalInputFacilitiesPeriodUtilizations(
        idToken: string,
        utilPeriodId: string
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/client_portal/admin_and_settings/operational_input/facilities_period_utilizations',
            headers: {
                id_token: idToken,
                util_period_id: utilPeriodId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalAdminAndSettingsOperationalInputFacilitiesPeriodUtilizations(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/admin_and_settings/operational_input/facilities_period_utilizations',
        });
    }

    /**
     * @param idToken
     * @returns getYearlyRevenue 200 response
     * @throws ApiError
     */
    public getClientPortalAdminAndSettingsOperationalInputYearlyRevenue(
        idToken: string
    ): CancelablePromise<getYearlyRevenue> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/client_portal/admin_and_settings/operational_input/yearly_revenue',
            headers: {
                id_token: idToken,
            },
        });
    }

    /**
     * @param idToken
     * @param addYearlyRevenue
     * @returns Empty 200 response
     * @throws ApiError
     */
    public postClientPortalAdminAndSettingsOperationalInputYearlyRevenue(
        idToken: string,
        addYearlyRevenue: AddYearlyRevenue
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/client_portal/admin_and_settings/operational_input/yearly_revenue',
            headers: {
                id_token: idToken,
            },
            body: addYearlyRevenue,
        });
    }

    /**
     * @param idToken
     * @param editYearlyRevenue
     * @returns Empty 200 response
     * @throws ApiError
     */
    public putClientPortalAdminAndSettingsOperationalInputYearlyRevenue(
        idToken: string,
        editYearlyRevenue: EditYearlyRevenue
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/client_portal/admin_and_settings/operational_input/yearly_revenue',
            headers: {
                id_token: idToken,
            },
            body: editYearlyRevenue,
        });
    }

    /**
     * @param idToken
     * @param inputId
     * @returns Empty 200 response
     * @throws ApiError
     */
    public deleteClientPortalAdminAndSettingsOperationalInputYearlyRevenue(
        idToken: string,
        inputId: string
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/client_portal/admin_and_settings/operational_input/yearly_revenue',
            headers: {
                id_token: idToken,
                input_id: inputId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalAdminAndSettingsOperationalInputYearlyRevenue(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/admin_and_settings/operational_input/yearly_revenue',
        });
    }

    /**
     * @param idToken
     * @param addDivision
     * @returns Empty 200 response
     * @throws ApiError
     */
    public postClientPortalAdminAndSettingsOrgStructureNewDivision(
        idToken: string,
        addDivision: AddDivision
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/client_portal/admin_and_settings/org_structure/new_division',
            headers: {
                id_token: idToken,
            },
            body: addDivision,
        });
    }

    /**
     * @param idToken
     * @param editDivision
     * @returns Empty 200 response
     * @throws ApiError
     */
    public putClientPortalAdminAndSettingsOrgStructureNewDivision(
        idToken: string,
        editDivision: EditDivision
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/client_portal/admin_and_settings/org_structure/new_division',
            headers: {
                id_token: idToken,
            },
            body: editDivision,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalAdminAndSettingsOrgStructureNewDivision(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/admin_and_settings/org_structure/new_division',
        });
    }

    /**
     * @param idToken
     * @param addFacility
     * @returns Empty 200 response
     * @throws ApiError
     */
    public postClientPortalAdminAndSettingsOrgStructureNewFacility(
        idToken: string,
        addFacility: AddFacility
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/client_portal/admin_and_settings/org_structure/new_facility',
            headers: {
                id_token: idToken,
            },
            body: addFacility,
        });
    }

    /**
     * @param idToken
     * @param editFacility
     * @returns Empty 200 response
     * @throws ApiError
     */
    public putClientPortalAdminAndSettingsOrgStructureNewFacility(
        idToken: string,
        editFacility: EditFacility
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/client_portal/admin_and_settings/org_structure/new_facility',
            headers: {
                id_token: idToken,
            },
            body: editFacility,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalAdminAndSettingsOrgStructureNewFacility(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/admin_and_settings/org_structure/new_facility',
        });
    }

    /**
     * @param idToken
     * @param addGroup
     * @returns Empty 200 response
     * @throws ApiError
     */
    public postClientPortalAdminAndSettingsOrgStructureNewGroup(
        idToken: string,
        addGroup: AddGroup
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/client_portal/admin_and_settings/org_structure/new_group',
            headers: {
                id_token: idToken,
            },
            body: addGroup,
        });
    }

    /**
     * @param idToken
     * @param editGroup
     * @returns Empty 200 response
     * @throws ApiError
     */
    public putClientPortalAdminAndSettingsOrgStructureNewGroup(
        idToken: string,
        editGroup: EditGroup
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/client_portal/admin_and_settings/org_structure/new_group',
            headers: {
                id_token: idToken,
            },
            body: editGroup,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalAdminAndSettingsOrgStructureNewGroup(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/admin_and_settings/org_structure/new_group',
        });
    }

    /**
     * @param idToken
     * @returns getPopulateAlertsPageResponse 200 response
     * @throws ApiError
     */
    public getClientPortalAlertsPagePopulate(
        idToken: string
    ): CancelablePromise<getPopulateAlertsPageResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/client_portal/alerts_page/populate',
            headers: {
                id_token: idToken,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalAlertsPagePopulate(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/alerts_page/populate',
        });
    }

    /**
     * @param idToken
     * @param postAlertsPageSendMessage
     * @returns postCreateMessageAlertsResponse 200 response
     * @throws ApiError
     */
    public postClientPortalAlertsPageSendMessage(
        idToken: string,
        postAlertsPageSendMessage: postAlertsPageSendMessage
    ): CancelablePromise<postCreateMessageAlertsResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/client_portal/alerts_page/send_message',
            headers: {
                id_token: idToken,
            },
            body: postAlertsPageSendMessage,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalAlertsPageSendMessage(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/alerts_page/send_message',
        });
    }

    /**
     * @param transactionId
     * @param idToken
     * @param status
     * @returns Empty 200 response
     * @throws ApiError
     */
    public putClientPortalEmissionTransactionsChangeStatus(
        transactionId: string,
        idToken: string,
        status: string
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/client_portal/emission_transactions/change_status',
            headers: {
                transaction_id: transactionId,
                id_token: idToken,
                status: status,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalEmissionTransactionsChangeStatus(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/emission_transactions/change_status',
        });
    }

    /**
     * @param idToken
     * @param postEmissionTansactionRecord
     * @returns postAddUserEmissionTransactionRecordResponse 200 response
     * @throws ApiError
     */
    public postClientPortalEmissionTransactionsEmissionTransactionRecord(
        idToken: string,
        postEmissionTansactionRecord: postEmissionTansactionRecord
    ): CancelablePromise<postAddUserEmissionTransactionRecordResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/client_portal/emission_transactions/emission_transaction_record',
            headers: {
                id_token: idToken,
            },
            body: postEmissionTansactionRecord,
        });
    }

    /**
     * @param idToken
     * @param putEmissionTansactionRecord
     * @returns Empty 200 response
     * @throws ApiError
     */
    public putClientPortalEmissionTransactionsEmissionTransactionRecord(
        idToken: string,
        putEmissionTansactionRecord: putEmissionTansactionRecord
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/client_portal/emission_transactions/emission_transaction_record',
            headers: {
                id_token: idToken,
            },
            body: putEmissionTansactionRecord,
        });
    }

    /**
     * @param transactionId
     * @param idToken
     * @returns Empty 200 response
     * @throws ApiError
     */
    public deleteClientPortalEmissionTransactionsEmissionTransactionRecord(
        transactionId: string,
        idToken: string
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/client_portal/emission_transactions/emission_transaction_record',
            headers: {
                transaction_id: transactionId,
                id_token: idToken,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalEmissionTransactionsEmissionTransactionRecord(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/emission_transactions/emission_transaction_record',
        });
    }

    /**
     * @param idToken
     * @returns getTransactionFiltersResponse 200 response
     * @throws ApiError
     */
    public getClientPortalEmissionTransactionsGetFilters(
        idToken: string
    ): CancelablePromise<getTransactionFiltersResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/client_portal/emission_transactions/get_filters',
            headers: {
                id_token: idToken,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalEmissionTransactionsGetFilters(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/emission_transactions/get_filters',
        });
    }

    /**
     * @param idToken
     * @returns GetEmissionTransactionsResponse 200 response
     * @throws ApiError
     */
    public getClientPortalEmissionTransactionsPopulateTransactionsPage(
        idToken: string
    ): CancelablePromise<GetEmissionTransactionsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/client_portal/emission_transactions/populate_transactions_page',
            headers: {
                id_token: idToken,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalEmissionTransactionsPopulateTransactionsPage(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/emission_transactions/populate_transactions_page',
        });
    }

    /**
     * @param idToken
     * @param statementId
     * @returns PopulateInvoiceInfoModal 200 response
     * @throws ApiError
     */
    public getClientPortalInvoicesPageInvoiceInfoModal(
        idToken: string,
        statementId: string
    ): CancelablePromise<PopulateInvoiceInfoModal> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/client_portal/invoices_page/invoice_info_modal',
            headers: {
                id_token: idToken,
                statement_id: statementId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalInvoicesPageInvoiceInfoModal(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/invoices_page/invoice_info_modal',
        });
    }

    /**
     * @param idToken
     * @returns GetPopulateInvoicesSummaryResponse 200 response
     * @throws ApiError
     */
    public getClientPortalInvoicesPageInvoicesSummaryTab(
        idToken: string
    ): CancelablePromise<GetPopulateInvoicesSummaryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/client_portal/invoices_page/invoices_summary_tab',
            headers: {
                id_token: idToken,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalInvoicesPageInvoicesSummaryTab(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/invoices_page/invoices_summary_tab',
        });
    }

    /**
     * @param year
     * @param idToken
     * @returns getPopulateInvoicesPageResponse 200 response
     * @throws ApiError
     */
    public getClientPortalInvoicesPagePopulate(
        year: string,
        idToken: string
    ): CancelablePromise<getPopulateInvoicesPageResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/client_portal/invoices_page/populate',
            headers: {
                year: year,
                id_token: idToken,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalInvoicesPagePopulate(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/invoices_page/populate',
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalLandingPage(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/landing_page',
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalLandingPageOverviewDashboard(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/landing_page/overview_dashboard',
        });
    }

    /**
     * @param idToken
     * @returns getPopulateLandingPageOverviewResponse 200 response
     * @throws ApiError
     */
    public getClientPortalLandingPageOverviewDashboardPopulate(
        idToken: string
    ): CancelablePromise<getPopulateLandingPageOverviewResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/client_portal/landing_page/overview_dashboard/populate',
            headers: {
                id_token: idToken,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalLandingPageOverviewDashboardPopulate(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/landing_page/overview_dashboard/populate',
        });
    }

    /**
     * @param idToken
     * @param postLandingTargetDashboardCreateFilterModel
     * @returns postCreateFilterLandingPageTargetDashboardresponse 200 response
     * @throws ApiError
     */
    public postClientPortalLandingPageTargetDashboardCreateFilter(
        idToken: string,
        postLandingTargetDashboardCreateFilterModel: postLandingTargetDashboardCreateFilterModel
    ): CancelablePromise<postCreateFilterLandingPageTargetDashboardresponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/client_portal/landing_page/target_dashboard/create_filter',
            headers: {
                id_token: idToken,
            },
            body: postLandingTargetDashboardCreateFilterModel,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalLandingPageTargetDashboardCreateFilter(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/landing_page/target_dashboard/create_filter',
        });
    }

    /**
     * @param idToken
     * @param putLandingTargetDashboardEditFilterModel
     * @returns putEditFilterLandingPageTargetDashboardResponse 200 response
     * @throws ApiError
     */
    public putClientPortalLandingPageTargetDashboardEditFilter(
        idToken: string,
        putLandingTargetDashboardEditFilterModel: putLandingTargetDashboardEditFilterModel
    ): CancelablePromise<putEditFilterLandingPageTargetDashboardResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/client_portal/landing_page/target_dashboard/edit_filter',
            headers: {
                id_token: idToken,
            },
            body: putLandingTargetDashboardEditFilterModel,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalLandingPageTargetDashboardEditFilter(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/landing_page/target_dashboard/edit_filter',
        });
    }

    /**
     * @param idToken
     * @param getLandingTargetStructureData
     * @returns Empty 200 response
     * @throws ApiError
     */
    public getClientPortalLandingPageTargetDashboardGetStructureData(
        idToken: string,
        getLandingTargetStructureData: getLandingTargetStructureData
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/client_portal/landing_page/target_dashboard/get_structure_data',
            headers: {
                id_token: idToken,
            },
            body: getLandingTargetStructureData,
        });
    }

    /**
     * @param idToken
     * @param getLandingTargetStructureData
     * @returns postTargetDashboardStructureModelResponse 200 response
     * @throws ApiError
     */
    public postClientPortalLandingPageTargetDashboardGetStructureData(
        idToken: string,
        getLandingTargetStructureData: getLandingTargetStructureData
    ): CancelablePromise<postTargetDashboardStructureModelResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/client_portal/landing_page/target_dashboard/get_structure_data',
            headers: {
                id_token: idToken,
            },
            body: getLandingTargetStructureData,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalLandingPageTargetDashboardGetStructureData(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/landing_page/target_dashboard/get_structure_data',
        });
    }

    /**
     * @param idToken
     * @returns populateLandingPageTargetDashboardResponse 200 response
     * @throws ApiError
     */
    public getClientPortalLandingPageTargetDashboardPopulate(
        idToken: string
    ): CancelablePromise<populateLandingPageTargetDashboardResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/client_portal/landing_page/target_dashboard/populate',
            headers: {
                id_token: idToken,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalLandingPageTargetDashboardPopulate(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/landing_page/target_dashboard/populate',
        });
    }

    /**
     * @param idToken
     * @param facilityId
     * @param inDate
     * @param evicValue
     * @returns SuccessResponse 200 response
     * @throws ApiError
     */
    public postClientPortalProductionDataEvic(
        idToken: string,
        facilityId: string,
        inDate: string,
        evicValue: string
    ): CancelablePromise<SuccessResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/client_portal/production_data/evic',
            headers: {
                id_token: idToken,
                facility_id: facilityId,
                in_date: inDate,
                evic_value: evicValue,
            },
            errors: {
                400: `400 response`,
            },
        });
    }

    /**
     * @param idToken
     * @param facilityId
     * @param inDate
     * @returns SuccessResponse 200 response
     * @throws ApiError
     */
    public deleteClientPortalProductionDataEvic(
        idToken: string,
        facilityId: string,
        inDate: string
    ): CancelablePromise<SuccessResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/client_portal/production_data/evic',
            headers: {
                id_token: idToken,
                facility_id: facilityId,
                in_date: inDate,
            },
            errors: {
                400: `400 response`,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalProductionDataEvic(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/production_data/evic',
        });
    }

    /**
     * @param idToken
     * @returns getPopulateProductionDataPageResponse 200 response
     * @throws ApiError
     */
    public getClientPortalProductionDataPopulate(
        idToken: string
    ): CancelablePromise<getPopulateProductionDataPageResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/client_portal/production_data/populate',
            headers: {
                id_token: idToken,
            },
            errors: {
                400: `400 response`,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalProductionDataPopulate(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/production_data/populate',
        });
    }

    /**
     * @param idToken
     * @param facilityId
     * @returns getProductionUnitsResponse 200 response
     * @throws ApiError
     */
    public getClientPortalProductionDataProductionUnits(
        idToken: string,
        facilityId?: string
    ): CancelablePromise<getProductionUnitsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/client_portal/production_data/production_units',
            headers: {
                id_token: idToken,
                facility_id: facilityId,
            },
            errors: {
                400: `400 response`,
            },
        });
    }

    /**
     * @param idToken
     * @param productionUnit
     * @returns SuccessResponse 200 response
     * @throws ApiError
     */
    public postClientPortalProductionDataProductionUnits(
        idToken: string,
        productionUnit: string
    ): CancelablePromise<SuccessResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/client_portal/production_data/production_units',
            headers: {
                id_token: idToken,
                production_unit: productionUnit,
            },
            errors: {
                400: `400 response`,
            },
        });
    }

    /**
     * @param idToken
     * @param productionUnit
     * @param productionUnitId
     * @returns Empty 200 response
     * @throws ApiError
     */
    public putClientPortalProductionDataProductionUnits(
        idToken: string,
        productionUnit: string,
        productionUnitId: string
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/client_portal/production_data/production_units',
            headers: {
                id_token: idToken,
                production_unit: productionUnit,
                production_unit_id: productionUnitId,
            },
        });
    }

    /**
     * @param idToken
     * @param productionUnitId
     * @returns SuccessResponse 200 response
     * @throws ApiError
     */
    public deleteClientPortalProductionDataProductionUnits(
        idToken: string,
        productionUnitId: string
    ): CancelablePromise<SuccessResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/client_portal/production_data/production_units',
            headers: {
                id_token: idToken,
                production_unit_id: productionUnitId,
            },
            errors: {
                400: `400 response`,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalProductionDataProductionUnits(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/production_data/production_units',
        });
    }

    /**
     * @param facilityId
     * @param idToken
     * @param inDate
     * @param productionValue
     * @param productionUnitId
     * @returns Empty 200 response
     * @throws ApiError
     */
    public postClientPortalProductionDataProductionValues(
        facilityId: string,
        idToken: string,
        inDate: string,
        productionValue: string,
        productionUnitId: string
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/client_portal/production_data/production_values',
            headers: {
                facility_id: facilityId,
                id_token: idToken,
                in_date: inDate,
                production_value: productionValue,
                production_unit_id: productionUnitId,
            },
        });
    }

    /**
     * @param facilityId
     * @param idToken
     * @param inDate
     * @param productionUnitId
     * @returns Empty 200 response
     * @throws ApiError
     */
    public deleteClientPortalProductionDataProductionValues(
        facilityId: string,
        idToken: string,
        inDate: string,
        productionUnitId: string
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/client_portal/production_data/production_values',
            headers: {
                facility_id: facilityId,
                id_token: idToken,
                in_date: inDate,
                production_unit_id: productionUnitId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalProductionDataProductionValues(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/production_data/production_values',
        });
    }

    /**
     * @param idToken
     * @param facilityId
     * @param revenueValue
     * @param inDate
     * @returns SuccessResponse 200 response
     * @throws ApiError
     */
    public postClientPortalProductionDataRevenue(
        idToken: string,
        facilityId: string,
        revenueValue: string,
        inDate: string
    ): CancelablePromise<SuccessResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/client_portal/production_data/revenue',
            headers: {
                id_token: idToken,
                facility_id: facilityId,
                revenue_value: revenueValue,
                in_date: inDate,
            },
            errors: {
                400: `400 response`,
            },
        });
    }

    /**
     * @param idToken
     * @param facilityId
     * @param inDate
     * @returns SuccessResponse 200 response
     * @throws ApiError
     */
    public deleteClientPortalProductionDataRevenue(
        idToken: string,
        facilityId: string,
        inDate: string
    ): CancelablePromise<SuccessResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/client_portal/production_data/revenue',
            headers: {
                id_token: idToken,
                facility_id: facilityId,
                in_date: inDate,
            },
            errors: {
                400: `400 response`,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalProductionDataRevenue(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/production_data/revenue',
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalReports(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/reports',
        });
    }

    /**
     * @param idToken
     * @param startDate
     * @param endDate
     * @param divisionId
     * @param facilityId
     * @param country
     * @param accountId
     * @param commodityId
     * @param utilityId
     * @param groupId
     * @returns getClientCostDistributionReport 200 response
     * @throws ApiError
     */
    public getClientPortalReportsCostDistribution(
        idToken: string,
        startDate: string,
        endDate: string,
        divisionId?: string,
        facilityId?: string,
        country?: string,
        accountId?: string,
        commodityId?: string,
        utilityId?: string,
        groupId?: string
    ): CancelablePromise<getClientCostDistributionReport> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/client_portal/reports/cost_distribution',
            headers: {
                division_id: divisionId,
                facility_id: facilityId,
                country: country,
                id_token: idToken,
                account_id: accountId,
                start_date: startDate,
                end_date: endDate,
                commodity_id: commodityId,
                utility_id: utilityId,
                group_id: groupId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalReportsCostDistribution(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/reports/cost_distribution',
        });
    }

    /**
     * @param idToken
     * @returns getClientCostDistributionFilters 200 response
     * @throws ApiError
     */
    public getClientPortalReportsCostDistributionFilters(
        idToken: string
    ): CancelablePromise<getClientCostDistributionFilters> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/client_portal/reports/cost_distribution/filters',
            headers: {
                id_token: idToken,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalReportsCostDistributionFilters(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/reports/cost_distribution/filters',
        });
    }

    /**
     * @param idToken
     * @param startDate
     * @param endDate
     * @param country
     * @param divisionId
     * @param facilityId
     * @param accountId
     * @param commodityId
     * @param utilityId
     * @param groupId
     * @returns CreditsReportList 200 response
     * @throws ApiError
     */
    public getClientPortalReportsCreditsReport(
        idToken: string,
        startDate: string,
        endDate: string,
        country?: string,
        divisionId?: string,
        facilityId?: string,
        accountId?: string,
        commodityId?: string,
        utilityId?: string,
        groupId?: string
    ): CancelablePromise<CreditsReportList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/client_portal/reports/credits_report',
            headers: {
                country: country,
                division_id: divisionId,
                facility_id: facilityId,
                id_token: idToken,
                account_id: accountId,
                start_date: startDate,
                end_date: endDate,
                commodity_id: commodityId,
                utility_id: utilityId,
                group_id: groupId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalReportsCreditsReport(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/reports/credits_report',
        });
    }

    /**
     * @param idToken
     * @returns getAllReportsFilters 200 response
     * @throws ApiError
     */
    public getClientPortalReportsGetAllReportsFilters(
        idToken: string
    ): CancelablePromise<getAllReportsFilters> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/client_portal/reports/get_all_reports_filters',
            headers: {
                id_token: idToken,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalReportsGetAllReportsFilters(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/reports/get_all_reports_filters',
        });
    }

    /**
     * @param idToken
     * @param startDate
     * @param endDate
     * @param divisionId
     * @param facilityId
     * @param groupId
     * @returns getGHGEmissionReport 200 response
     * @throws ApiError
     */
    public getClientPortalReportsGhgEmissionReport(
        idToken: string,
        startDate: string,
        endDate: string,
        divisionId?: string,
        facilityId?: string,
        groupId?: string
    ): CancelablePromise<getGHGEmissionReport> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/client_portal/reports/ghg_emission_report',
            headers: {
                division_id: divisionId,
                facility_id: facilityId,
                id_token: idToken,
                group_id: groupId,
                start_date: startDate,
                end_date: endDate,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalReportsGhgEmissionReport(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/reports/ghg_emission_report',
        });
    }

    /**
     * @param facilityId
     * @param idToken
     * @param inDate
     * @returns PerformanceReport 200 response
     * @throws ApiError
     */
    public getClientPortalReportsPerformanceReport(
        facilityId: string,
        idToken: string,
        inDate: string
    ): CancelablePromise<PerformanceReport> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/client_portal/reports/performance_report',
            headers: {
                facility_id: facilityId,
                id_token: idToken,
                in_date: inDate,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalReportsPerformanceReport(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/reports/performance_report',
        });
    }

    /**
     * @param idToken
     * @param startDate
     * @param endDate
     * @param facilityId
     * @param commodityId
     * @returns getClientPeriodComparisonReport 200 response
     * @throws ApiError
     */
    public getClientPortalReportsPeriodComparison(
        idToken: string,
        startDate: string,
        endDate: string,
        facilityId?: string,
        commodityId?: string
    ): CancelablePromise<getClientPeriodComparisonReport> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/client_portal/reports/period_comparison',
            headers: {
                facility_id: facilityId,
                id_token: idToken,
                commodity_id: commodityId,
                start_date: startDate,
                end_date: endDate,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalReportsPeriodComparison(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/reports/period_comparison',
        });
    }

    /**
     * @param idToken
     * @param startDate
     * @param endDate
     * @param divisionId
     * @param facilityId
     * @param country
     * @param commodityId
     * @param groupId
     * @returns getTotalCostAndUsageReport 200 response
     * @throws ApiError
     */
    public getClientPortalReportsTotalCostAndUsage(
        idToken: string,
        startDate: string,
        endDate: string,
        divisionId?: string,
        facilityId?: string,
        country?: string,
        commodityId?: string,
        groupId?: string
    ): CancelablePromise<getTotalCostAndUsageReport> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/client_portal/reports/total_cost_and_usage',
            headers: {
                division_id: divisionId,
                facility_id: facilityId,
                country: country,
                id_token: idToken,
                start_date: startDate,
                end_date: endDate,
                commodity_id: commodityId,
                group_id: groupId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalReportsTotalCostAndUsage(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/reports/total_cost_and_usage',
        });
    }

    /**
     * @param idToken
     * @param startDate
     * @param endDate
     * @param divisionId
     * @param facilityId
     * @param country
     * @param commodityId
     * @param groupId
     * @returns getUnbundledCostReport 200 response
     * @throws ApiError
     */
    public getClientPortalReportsUnbundledCostReport(
        idToken: string,
        startDate: string,
        endDate: string,
        divisionId?: string,
        facilityId?: string,
        country?: string,
        commodityId?: string,
        groupId?: string
    ): CancelablePromise<getUnbundledCostReport> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/client_portal/reports/unbundled_cost_report',
            headers: {
                division_id: divisionId,
                facility_id: facilityId,
                country: country,
                id_token: idToken,
                start_date: startDate,
                end_date: endDate,
                commodity_id: commodityId,
                group_id: groupId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalReportsUnbundledCostReport(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/reports/unbundled_cost_report',
        });
    }

    /**
     * @param idToken
     * @param startDate
     * @param endDate
     * @param divisionId
     * @param facilityId
     * @param groupId
     * @returns WeatherDataReportResponse 200 response
     * @throws ApiError
     */
    public getClientPortalReportsWeatherDataReport(
        idToken: string,
        startDate: string,
        endDate: string,
        divisionId?: string,
        facilityId?: string,
        groupId?: string
    ): CancelablePromise<WeatherDataReportResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/client_portal/reports/weather_data_report',
            headers: {
                division_id: divisionId,
                facility_id: facilityId,
                id_token: idToken,
                group_id: groupId,
                start_date: startDate,
                end_date: endDate,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalReportsWeatherDataReport(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/reports/weather_data_report',
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalScopeDashboard(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/scope_dashboard',
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalScopeDashboardCarbonTab(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/scope_dashboard/carbon_tab',
        });
    }

    /**
     * @param idToken
     * @param divisionId
     * @param facilityId
     * @param groupId
     * @returns getPopulateCarbonTabScopeDashboardResponse 200 response
     * @throws ApiError
     */
    public getClientPortalScopeDashboardCarbonTabPopulate(
        idToken: string,
        divisionId?: string,
        facilityId?: string,
        groupId?: string
    ): CancelablePromise<getPopulateCarbonTabScopeDashboardResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/client_portal/scope_dashboard/carbon_tab/populate',
            headers: {
                id_token: idToken,
                division_id: divisionId,
                facility_id: facilityId,
                group_id: groupId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalScopeDashboardCarbonTabPopulate(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/scope_dashboard/carbon_tab/populate',
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalScopeDashboardEnergyTab(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/scope_dashboard/energy_tab',
        });
    }

    /**
     * @param idToken
     * @param divisionId
     * @param facilityId
     * @param groupId
     * @returns getPopulateEnergyTabScopeDashboardResponse 200 response
     * @throws ApiError
     */
    public getClientPortalScopeDashboardEnergyTabPopulate(
        idToken: string,
        divisionId?: string,
        facilityId?: string,
        groupId?: string
    ): CancelablePromise<getPopulateEnergyTabScopeDashboardResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/client_portal/scope_dashboard/energy_tab/populate',
            headers: {
                id_token: idToken,
                division_id: divisionId,
                facility_id: facilityId,
                group_id: groupId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalScopeDashboardEnergyTabPopulate(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/scope_dashboard/energy_tab/populate',
        });
    }

    /**
     * @param idToken
     * @param editLoginCredentialRequest
     * @returns Empty 200 response
     * @throws ApiError
     */
    public putClientPortalSettingsLoginCredentials(
        idToken: string,
        editLoginCredentialRequest: EditLoginCredentialRequest
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/client_portal/settings/login_credentials',
            headers: {
                id_token: idToken,
            },
            body: editLoginCredentialRequest,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalSettingsLoginCredentials(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/settings/login_credentials',
        });
    }

    /**
     * @param idToken
     * @returns GetSettingsPopulateResponse 200 response
     * @throws ApiError
     */
    public getClientPortalSettingsPopulateSettings(
        idToken: string
    ): CancelablePromise<GetSettingsPopulateResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/client_portal/settings/populate_settings',
            headers: {
                id_token: idToken,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalSettingsPopulateSettings(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/settings/populate_settings',
        });
    }

    /**
     * @param idToken
     * @param editSelfUserPreferences
     * @returns Empty 200 response
     * @throws ApiError
     */
    public putClientPortalSettingsSelfUserPreferences(
        idToken: string,
        editSelfUserPreferences: EditSelfUserPreferences
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/client_portal/settings/self_user/preferences',
            headers: {
                id_token: idToken,
            },
            body: editSelfUserPreferences,
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalSettingsSelfUserPreferences(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/settings/self_user/preferences',
        });
    }

    /**
     * @param idToken
     * @param userToDisableId
     * @returns Empty 200 response
     * @throws ApiError
     */
    public postClientPortalSettingsUserManagementDisableUser(
        idToken: string,
        userToDisableId: string
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/client_portal/settings/user_management/disable_user',
            headers: {
                id_token: idToken,
                user_to_disable_id: userToDisableId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalSettingsUserManagementDisableUser(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/settings/user_management/disable_user',
        });
    }

    /**
     * @param idToken
     * @param userToEnableId
     * @returns Empty 200 response
     * @throws ApiError
     */
    public postClientPortalSettingsUserManagementEnableUser(
        idToken: string,
        userToEnableId: string
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/client_portal/settings/user_management/enable_user',
            headers: {
                id_token: idToken,
                user_to_enable_id: userToEnableId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalSettingsUserManagementEnableUser(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/settings/user_management/enable_user',
        });
    }

    /**
     * @param idToken
     * @param addUserClientSide
     * @returns Empty 200 response
     * @throws ApiError
     */
    public postClientPortalSettingsUserManagementUser(
        idToken: string,
        addUserClientSide: AddUserClientSide
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/client_portal/settings/user_management/user',
            headers: {
                id_token: idToken,
            },
            body: addUserClientSide,
        });
    }

    /**
     * @param idToken
     * @param editUserClientSide
     * @returns Empty 200 response
     * @throws ApiError
     */
    public putClientPortalSettingsUserManagementUser(
        idToken: string,
        editUserClientSide: EditUserClientSide
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/client_portal/settings/user_management/user',
            headers: {
                id_token: idToken,
            },
            body: editUserClientSide,
        });
    }

    /**
     * @param idToken
     * @param userToDeleteId
     * @returns Empty 200 response
     * @throws ApiError
     */
    public deleteClientPortalSettingsUserManagementUser(
        idToken: string,
        userToDeleteId: string
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/client_portal/settings/user_management/user',
            headers: {
                id_token: idToken,
                user_to_delete_id: userToDeleteId,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsClientPortalSettingsUserManagementUser(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/client_portal/settings/user_management/user',
        });
    }

    /**
     * @param idToken
     * @param keysInput
     * @returns Empty 200 response
     * @throws ApiError
     */
    public getDownloadDownloadMultipleFilesAsZip(
        idToken: string,
        keysInput: string
    ): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/download/download_multiple_files_as_zip',
            headers: {
                id_token: idToken,
                keys_input: keysInput,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsDownloadDownloadMultipleFilesAsZip(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/download/download_multiple_files_as_zip',
        });
    }

    /**
     * @param idToken
     * @param link
     * @returns downloadS3File 200 response
     * @throws ApiError
     */
    public getDownloadS3BucketFile(
        idToken: string,
        link: string
    ): CancelablePromise<downloadS3File> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/download/s3_bucket_file',
            headers: {
                id_token: idToken,
                link: link,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsDownloadS3BucketFile(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/download/s3_bucket_file',
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsOpenapi(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/openapi',
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public getOpenapi2(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/openapi/2',
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsOpenapi2(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/openapi/2',
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public getOpenapi3(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/openapi/3',
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsOpenapi3(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/openapi/3',
        });
    }

    /**
     * @param idToken
     * @returns getOrganizationStructureResponse 200 response
     * @throws ApiError
     */
    public getUserGetOrganizationStructure(
        idToken: string
    ): CancelablePromise<getOrganizationStructureResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/get_organization_structure',
            headers: {
                id_token: idToken,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsUserGetOrganizationStructure(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/user/get_organization_structure',
        });
    }

    /**
     * @param idToken
     * @returns getPopulateUserResponse 200 response
     * @throws ApiError
     */
    public getUserPopulateUser(
        idToken: string
    ): CancelablePromise<getPopulateUserResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/populate_user',
            headers: {
                id_token: idToken,
            },
        });
    }

    /**
     * @returns Empty 200 response
     * @throws ApiError
     */
    public optionsUserPopulateUser(): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/user/populate_user',
        });
    }

    /**
     * @param idToken
     * @returns Empty 200 response
     * @throws ApiError
     */
    public getUserPopulateUserTest(idToken: string): CancelablePromise<Empty> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/populate_user/test',
            headers: {
                id_token: idToken,
            },
        });
    }
}
