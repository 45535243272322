/**
 * Represents the default layout component.
 * This component is responsible for rendering the main content of the application,
 * including the navigation drawer, top app bar, and app routes.
 */
import { FunctionComponent } from 'react';

// Third party modules
import { styled, Box, CssBaseline } from '@mui/material';

// Local modules
import { AppRoutes } from 'src/modules/router';
import { COLLAPSED_DRAWER_WIDTH, NavigationDrawer, TopAppBar } from 'src/modules/navigation';
/**
 * A styled div component used as an offset for the toolbar in the DefaultLayout.
 * @param {Object} theme - The theme object containing mixins.
 * @returns {JSX.Element} - The Offset component.
 */
const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

/**
 * Default layout component.
 * This component represents the default layout for the application.
 * It includes a main content box, a navigation drawer, a top app bar,
 * an offset for the app bar height, and the routes for the app body.
 */
const DefaultLayout: FunctionComponent = () => {
	return (
		<Box
			component="main"
			sx={{
				flex: 1,
				p: '1rem',
				minWidth: 500,
				marginLeft: `${COLLAPSED_DRAWER_WIDTH}px`
			}}
		>
			{/* Apply a reset CSS to the entire app */}
			<CssBaseline />

			{/* Wrapper around MUI Drawer can be defined outside of the main content Box */}
			<NavigationDrawer />

			{/* Render the static AppBar appearing above the main component */}
			<TopAppBar />

			{/* Use theme.mixins.toolbar CSS to render an Offset to account for AppBar height */}
			<Offset />

			{/* Render Routes that represent the App body for the current page */}
			<AppRoutes />
		</Box>
	);
};

export default DefaultLayout;
