import { Drawer, styled } from '@mui/material';

const StyledDrawer = styled(Drawer)(({ theme }) => ({
	'& .MuiDrawer-paper': {
		width: '100%',
		maxHeight: '80vh',
		padding: theme.spacing(2)
	}
}));

export default StyledDrawer;