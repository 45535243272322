/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EnumData } from '../models/EnumData';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FrontentConstantVariablesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Constant Variables
     * ### Constant variables for frontend development.
     *
     * - Data in this endpoint typically will not change very often if at all.
     * - Most of this data is hardcoded in a enum.Enum class in the 360-library bitbucket repo.
     *
     * Typical use cases include things like:
     * - Populating dropdown lists in forms.
     * - Provding mouse hover information on btn's and other components.
     * - Other frontend development use cases.
     * @returns EnumData Success
     * @throws ApiError
     */
    public constantVariablesApiV2ReadConstantsGet(): CancelablePromise<EnumData> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/read/constants',
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
