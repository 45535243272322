import { CSSProperties } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

interface SelectFormControlProps<T> {
	style?: CSSProperties;
	label: string;
	value: T | string;
	onChange: (value: T) => void;
	options: T[];
	size?: 'small' | 'medium';
	id: string;
	name?: string;
	required?: boolean;
	error?: boolean;
	getOptionLabel?: (option: T) => string;
}

const SelectFormControl = <T extends unknown>({
	style,
	label,
	value,
	onChange,
	options,
	size = 'medium',
	id,
	name,
	error,
	required,
	getOptionLabel = option => option as unknown as string // Default implementation if T is a string
}: SelectFormControlProps<T>) => {

	// Ensure the value matches one of the options
	const currentValue = options.includes(value as T) ? value : '';

	return (
		<FormControl id={`selectFormControl-${id}`} fullWidth size={size} style={style}>
			<InputLabel required={required} error={error}>
				{label}
			</InputLabel>
			<Select
				labelId={`${label}-select`}
				value={currentValue as unknown as string} // Cast to string for Select component
				onChange={e => {
					onChange(e.target.value as unknown as T);
				}}
				variant="outlined"
				label={label}
				id={`select-${id}`}
				name={name}
				error={error}
				required={required}
			>
				{options.map((option, optionIndex) => (
					<MenuItem
						id={`selectMenuItem-${optionIndex}-${getOptionLabel(option)}}`}
						value={option as unknown as string}
						key={optionIndex}
					>
						{getOptionLabel(option)}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default SelectFormControl;