import React from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

export type Severity = 'success' | 'error' | 'info' | 'warning';
interface CustomAlertProps {
	id: string;
	isOpen: boolean;
	message: string;
	severity: Severity;
	sx?: React.CSSProperties;
	onClose?: (event?: React.SyntheticEvent | Event, reason?: string) => void;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return (
		<MuiAlert
			elevation={6}
			ref={ref}
			variant="filled"
			{...props}
			// sx={{ color: "white" }}
		/>
	);
});

const Toast: React.FC<CustomAlertProps> = ({
	id,
	isOpen,
	message,
	severity = 'success',
	onClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
	},
	sx
}) => {
	return (
		<Snackbar
			open={isOpen}
			//autoHideDuration={5000}
			onClose={onClose}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			sx={{ zIndex: 10000 }}
		>
			<Alert severity={severity} onClose={onClose} id={id} sx={{ ...sx, ml: '8rem' }}>
				{message}
			</Alert>
		</Snackbar>
	);
};

export default Toast;