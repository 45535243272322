import { useQuery } from 'react-query';
import { getPopulateUserResponse, ApiError } from 'src/modules/generated-api-clients/sql';
import { useApiSqlContext } from 'src/modules/contexts/ApiSqlContext';
import { useAuthenticator } from '@aws-amplify/ui-react';

export const useGetPopulateUser = () => {
	const { client } = useApiSqlContext();
	const { user } = useAuthenticator(context => [context.user]);

	const token = user.getSignInUserSession()?.getIdToken().getJwtToken();

	return useQuery<getPopulateUserResponse, ApiError>(
		['getUserPopulateUser', token],
		async () => {
			try {
				if (!client) {
					throw new Error('API client is not initialized');
				}

				if (token === undefined) {
					throw new Error('AWS Amplify user attributes cannot be undefined');
				}

				const response = await client.default.getUserPopulateUser(token);

				return response;
			} catch (error: unknown) {
				if (error instanceof ApiError) {
					console.error(
						`ApiError -> URL: ${error.url}\nSTATUS: ${error.status}\nMESSAGE: ${
							error.message
						}\nBODY: ${JSON.stringify(error.body)}`
					);
					throw error;
				} else {
					console.error('Error fetching user:', error);
					throw error;
				}
			}
		},
		{
			enabled: !!token // Ensure both account_id and email are available
		}
	);
};
