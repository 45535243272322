import React, { useEffect, useState } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { Paper, Box, CircularProgress, Divider } from '@mui/material';

import { useQueryReadFile } from 'src/modules/hooks/apis/no-sql/download';

// IMPORTANT FOR STYLING PDF VIEWER
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import { ToolbarSlot } from '@react-pdf-viewer/toolbar';
import { SelectionMode } from '@react-pdf-viewer/selection-mode';
import { handleNoSqlApiError } from 'src/modules/hooks/apis/no-sql/helpers';
import Toast, { Severity } from '../controlled/Toast';
import packageJson from '../../../../../package.json';
import { pdfjs } from 'react-pdf';
import { MoreActionsPopover } from '@react-pdf-viewer/toolbar';

// This component receives the toolbar slot as a prop
const VerticalToolbar = ({
	toolbarSlot,
	onSelectionModeChange
}: {
	toolbarSlot: ToolbarSlot;
	onSelectionModeChange: (mode: SelectionMode) => void;
}) => {
	const handleSelectionModeChange = (mode: SelectionMode) => {
		onSelectionModeChange(mode); // Call the prop function
	};
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				height: '100%'
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				{toolbarSlot.ShowSearchPopover({})}
			</Box>
			<Divider sx={{ width: '100%', m: '20%', p: 0 }} />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'space-evenly',
					flex: 1,
				}}
			>
				<MoreActionsPopover toolbarSlot={toolbarSlot} />
			</Box>

			<Divider sx={{ width: '100%', m: '20%', p: 0 }} />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'space-evenly',
					flex: 1
				}}
			>
				{toolbarSlot.GoToPreviousPage({})}
				{toolbarSlot.CurrentPageLabel({})}
				{toolbarSlot.GoToNextPage({})}
			</Box>
			<Divider sx={{ width: '100%', m: '20%', p: 0 }} />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'space-evenly',
					flex: 1
				}}
			>
				{toolbarSlot.ZoomIn({})}
				{toolbarSlot.CurrentScale({})}
				{toolbarSlot.ZoomOut({})}
			</Box>
			<Divider sx={{ width: '100%', m: '20%', p: 0 }} />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'space-evenly',
					flex: 1
				}}
			>
				<div style={{ marginBottom: '5%' }} onClick={() => handleSelectionModeChange(SelectionMode.Text)}>
					{toolbarSlot.SwitchSelectionMode({ mode: SelectionMode.Text })}
				</div>
				<div onClick={() => handleSelectionModeChange(SelectionMode.Hand)}>
					{toolbarSlot.SwitchSelectionMode({ mode: SelectionMode.Hand })}
				</div>
			</Box>
			<Divider sx={{ width: '100%', m: '20%', p: 0 }} />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'space-evenly',
					flex: 1
				}}
			>
				{toolbarSlot.Print({})}
				{toolbarSlot.Download({})}
			</Box>
		</Box>
	);
};

interface PdfViewerProps {
	invoiceUrl: string;
	toolbarStyle?: React.CSSProperties;
	contentStyle?: React.CSSProperties;
	useUploadFailureQuery?: boolean;
}

const PdfViewer: React.FC<PdfViewerProps> = ({ invoiceUrl, contentStyle, toolbarStyle }) => {
	const toolbarPluginInstance = toolbarPlugin();
	const { Toolbar } = toolbarPluginInstance;

	const {
		data: documentData,
		error: documentError,
		isError: documentIsError,
		isLoading: documentIsLoading
	} = useQueryReadFile(invoiceUrl);

	const [selectionMode, setSelectionMode] = useState(SelectionMode.Text);
	const [isToastOpen, setIsToastOpen] = useState(false);
	const [toastMessage, setToastMessage] = useState('');
	const [toastSeverity, setToastSeverity] = useState<Severity>('error');

	useEffect(() => {
		handleNoSqlApiError(documentError, setToastMessage, setToastSeverity);
		setIsToastOpen(documentIsError);
	}, [documentError, documentIsError]);

	if (documentIsLoading) {
		return <CircularProgress />;
	}

	const handleSelectionModeChange = (mode: SelectionMode) => {
		setSelectionMode(mode); // Update state to trigger re-render
	};

    // NOTE: see README for more info on how pdfjs-dist version is determined
    const pdfjsVersion = packageJson.dependencies['pdfjs-dist'].replace('^', '');
    if (pdfjsVersion !== pdfjs.version) {
        console.warn(`pdfjsVersion: ${pdfjsVersion} !== pdfjs.version: ${pdfjs.version}:: this may cause pdf viewer to fail.`);
    }
	return (
		<>
			<Toast
				message={toastMessage}
				severity={toastSeverity}
				isOpen={isToastOpen}
				onClose={() => setIsToastOpen(false)}
				id="toast-pdf-viewer"
			/>
			{/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js"> */}
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}>
				<Paper
					sx={{
						display: 'flex',
						flexDirection: 'row',
						padding: '1%',
						paddingLeft: 0,
						backgroundColor: '#F5F5F5',
						borderColor: '#E5E5E5',
						borderWidth: '1px',
						borderStyle: 'solid'
					}}
				>
					<Box
						sx={{
							...toolbarStyle,
							padding: '1%',
							backgroundColor: '#F5F5F5'
						}}
					>
						<Toolbar>
							{toolbarSlot => (
								<VerticalToolbar
									toolbarSlot={toolbarSlot}
									onSelectionModeChange={handleSelectionModeChange}
								/>
							)}
						</Toolbar>
					</Box>
					<Box
						id={`pdfViewer-${invoiceUrl}`}
						className="rpv-core__viewer"
						style={{
							...contentStyle,
							minHeight: '400px',
							flex: 1,
							borderColor: '#E5E5E5',
							borderWidth: '1px',
							borderStyle: 'solid'
						}}
					>
						{documentData && <Viewer fileUrl={documentData} plugins={[toolbarPluginInstance]} />}
					</Box>
				</Paper>
			</Worker>
		</>
	);
};

const PdfViewerInvoiceInfoModal: React.FC<PdfViewerProps> = ({invoiceUrl,contentStyle,toolbarStyle, useUploadFailureQuery = false,
}) => {
    const toolbarPluginInstance = toolbarPlugin();
    const { Toolbar } = toolbarPluginInstance;

	const {
		data: documentData,
		error: documentError,
		isError: documentIsError,
		isLoading: documentIsLoading
	} = useQueryReadFile(invoiceUrl, useUploadFailureQuery);

	const [selectionMode, setSelectionMode] = useState(SelectionMode.Text);
	const [isToastOpen, setIsToastOpen] = useState(false);
	const [toastMessage, setToastMessage] = useState('');
	const [toastSeverity, setToastSeverity] = useState<Severity>('error');

	useEffect(() => {
		handleNoSqlApiError(documentError, setToastMessage, setToastSeverity);
		setIsToastOpen(documentIsError);
	}, [documentError, documentIsError]);

	if (documentIsLoading) {
		return <CircularProgress />;
	}

	const handleSelectionModeChange = (mode: SelectionMode) => {
		setSelectionMode(mode); // Update state to trigger re-render
	};

    // NOTE: see README for more info on how pdfjs-dist version is determined
    const pdfjsVersion = packageJson.dependencies['pdfjs-dist'].replace('^', '');
    if (pdfjsVersion !== pdfjs.version) {
        console.warn(`pdfjsVersion: ${pdfjsVersion} !== pdfjs.version: ${pdfjs.version}:: this may cause pdf viewer to fail.`);
    }
	return (
		<>
			<Toast
				message={toastMessage}
				severity={toastSeverity}
				isOpen={isToastOpen}
				onClose={() => setIsToastOpen(false)}
				id="toast-pdf-viewer"
			/>
			{/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js"> */}
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}>
				    <div
					className="rpv-core__viewer"
					style={{
						border: "1px solid rgba(0, 0, 0, 0.3)",
						display: "flex",
						flexDirection: "column",
						height: "820px",
					}}
				>
					<div
						style={{
							alignItems: "center",
							backgroundColor: "#eeeeee",
							borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
							display: "flex",
							padding: "4px",
						}}
					>
						<Toolbar />
					</div>
					<div
						style={{
							flex: 1,
							overflow: "hidden",
						}}
					>
						{documentData && (
							<Viewer fileUrl={documentData} plugins={[toolbarPluginInstance]} />
						)}
					</div>
				</div>
			</Worker>
		</>
	);
};

export default PdfViewer;
export { PdfViewerInvoiceInfoModal };