

import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, Box } from '@mui/material';

interface DialogProps {
  title: string;
  open: boolean;
  onClose?: () => void;
  fullScreen?: boolean;
  fullWidth?: boolean;
  children: React.ReactNode;
}
const DialogFullScreen: React.FC<DialogProps> = ({
  title,
  open,
  onClose,
  fullScreen = true,
  fullWidth = false,
  children
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullScreen={fullScreen} fullWidth={fullWidth}>

      <DialogTitle sx={{ alignItems: 'center', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <Typography
            variant="h2"
            sx={{ alignItems: 'center', justifyContent: 'center' }}
          >
            {title}
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent>
        {children}
      </DialogContent>

      <DialogActions>
        {onClose && (
          <Button onClick={onClose} color="error" variant='contained'>
            Close
          </Button>
        )}
      </DialogActions>

    </Dialog>
  );
};

export default DialogFullScreen;


