/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LayoutlmRequest } from '../models/LayoutlmRequest';
import type { LayoutlmResponse } from '../models/LayoutlmResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AiModelsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Layoutlm Model Prediction Or 404
     * __Layoutlm prediction by S3 bucket file path.__
     *
     * Used on the prompts page of a utility company.
     *
     * This endpoint will be used to test prompts for each
     * entity as it applies to utility companies.
     *
     * POST request data should contain certain elements
     * of the prompt schema along with the path of the PDF
     * invoice in the S3 bucket.
     * @param requestBody
     * @returns LayoutlmResponse Success
     * @throws ApiError
     */
    public layoutlmModelPredictionOr404ApiV2AiLayoutlmFromS3PredictPost(
        requestBody: LayoutlmRequest,
    ): CancelablePromise<LayoutlmResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/ai/layoutlm/from-s3/predict',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
