import React, { useState } from 'react';

// MUI components
import { List, ListItemButton, ListItemAvatar, ListItemText, Drawer, Divider, Typography, Box } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import UpdateIcon from '@mui/icons-material/Update';

// Local components
import NotificationDialog, { Notification } from '../components/reusable/controlled/NotificationDialog';
import { formatDistanceToNow } from 'date-fns';

interface NotificationListProps {
	open: boolean;
	onClose: () => void;
	notifications: Notification[];
	onMarkAsRead: (notificationId: number) => void;
}

const NotificationDrawer: React.FC<NotificationListProps> = ({ open, onClose, notifications, onMarkAsRead }) => {
	const unreadNotifications = notifications.filter(notification => notification.status === 'unread');

	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [selectedNotification, setSelectedNotification] = useState<Notification | null>(null);

	const handleOpenDialog = (notification: Notification) => {
		setSelectedNotification(notification);
		onMarkAsRead(notification.id);
		setIsDialogOpen(true);
	};

	const handleCloseDialog = () => {
		setIsDialogOpen(false);
	};

	return (
		<Drawer anchor="right" open={open} onClose={onClose} PaperProps={{ sx: { width: 350, padding: '1rem' } }}>
			<List>
				<Typography variant="h4" sx={{ marginBottom: '2rem' }}>
					Notifications
				</Typography>
				<Divider />
				{unreadNotifications.length === 0 ? (
					<Typography sx={{ padding: '1rem', color: '#B3B3B3' }}>No notifications to display.</Typography>
				) : (
					unreadNotifications.map(notification => (
						<Box key={notification.id}>
							<ListItemButton onClick={() => handleOpenDialog(notification)}>
								<ListItemAvatar>
									{notification.type === 'new_utility' && <InfoOutlinedIcon color="primary" />}
									{notification.type === 'invoice_update' && <UpdateIcon color="success" />}
								</ListItemAvatar>

								<ListItemText
									primary={
										<>
											<Typography id="notificationTitle" variant="h5">
												{notification.title}
											</Typography>
											<Typography
												id="notificationTimestamp"
												sx={{ marginBottom: '4%', fontSize: '0.8rem', color: '#B3B3B3' }}
											>
												{formatDistanceToNow(new Date(notification.timestamp))} ago
											</Typography>
										</>
									}
									secondary={
										notification.type === 'invoice_update' ? (
											<>
												<Typography component="span">{notification.message} </Typography>
												<Typography component="span" sx={{ fontWeight: 'bold' }}>
													{notification.details.invoice_no}
												</Typography>
												<Typography component="span"> for </Typography>
												<Typography component="span" sx={{ fontStyle: 'italic' }}>
													{notification.details.client}.
												</Typography>
											</>
										) : notification.type === 'new_utility' ? (
											<>
												<Typography component={'span'}>
													{notification.message}
													{' ('}
												</Typography>
												<Typography component="span" sx={{ fontWeight: 'bold' }}>
													{notification.details.utility_account}
												</Typography>
												<Typography component={'span'}>{').'}</Typography>
											</>
										) : (
											<Typography component={'span'}>{notification.message}</Typography>
										)
									}
								/>
							</ListItemButton>
							<Divider />
						</Box>
					))
				)}
			</List>
			<NotificationDialog open={isDialogOpen} onClose={handleCloseDialog} notification={selectedNotification} />
		</Drawer>
	);
};

export default NotificationDrawer;
