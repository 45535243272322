/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum AlertCategoryEnum {
    TYPE_COERCION_ERROR = 'TYPE_COERCION_ERROR',
    DUPLICATE_INVOICE_FIELD = 'DUPLICATE_INVOICE_FIELD',
    DUPLICATE_INVOICE_ENTITY = 'DUPLICATE_INVOICE_ENTITY',
    DUPLICATE_INVOICE_ANSWER = 'DUPLICATE_INVOICE_ANSWER',
    MISSING_FIELD = 'MISSING_FIELD',
    LOW_CONFIDENCE_SCORE = 'LOW_CONFIDENCE_SCORE',
    NO_RESULTS = 'NO_RESULTS',
    INVOICE_MAPPING_FAILURE = 'INVOICE_MAPPING_FAILURE',
}
