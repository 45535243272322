type InvoicesIconProps = {
  color: string; // Assuming 'color' is a CSS color value (e.g., "blue")
  width?: string; // Assuming 'color' is a CSS color value (e.g., "blue")
  height?: string; // Assuming 'color' is a CSS color value (e.g., "blue")
};

const InvoicesIcon: React.FC<InvoicesIconProps> = ({
  color,
  width,
  height,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 24}
    height={height || 24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path d="M9 7L13 7" stroke={color} strokeLinecap="round" />
    <path d="M9 15L12 15" stroke={color} strokeLinecap="round" />
    <path d="M9 11L15 11" stroke={color} strokeLinecap="round" />
    <path
      d="M19 11V9C19 6.17157 19 4.75736 18.1213 3.87868C17.2426 3 15.8284 3 13 3H11C8.17157 3 6.75736 3 5.87868 3.87868C5 4.75736 5 6.17157 5 9V15C5 17.8284 5 19.2426 5.87868 20.1213C6.75736 21 8.17157 21 11 21H12"
      stroke={color}
      strokeLinecap="round"
    />
  </svg>
);

export default InvoicesIcon;
