import React, { useState } from 'react';
import { Tab, Tabs, Box } from '@mui/material';

interface MappedTabsProps {
	id?: string;
	labels: string[];
	components: React.ReactNode[];
}

const MappedTabs: React.FC<MappedTabsProps> = ({ id, labels, components }) => {
	const [value, setValue] = useState(0);
	return (
		<Box id={`tabsContainer-${id}`}>
			<Tabs value={value} onChange={(event, value) => setValue(value)} indicatorColor="primary" id={`tabs-${id}`}>
				{labels.map((label, index) => (
					<Tab key={index} label={label} disableRipple id={`tab-${id}-${index}-${label.toLowerCase()}`} />
				))}
			</Tabs>
			{components.map((component, index) => (
				<Box role="tabpanel" hidden={value !== index} key={index}>
					{value === index && <Box id={`tabpanel-${id}-${index}`}>{component}</Box>}
				</Box>
			))}
		</Box>
	);
};

export default MappedTabs;
