type ArcadiaIconProps = {
  width?: number;
  height?: number;
};

const ArcadiaIcon: React.FC<ArcadiaIconProps> = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 100}
    zoomAndPan="magnify"
    viewBox="0 0 75 74.999997"
    height={height || 100}
    preserveAspectRatio="xMidYMid meet"
    version="1.0"
  >
    <defs>
      <g />
      <clipPath id="aa5d9190d1">
        <path
          d="M 0.449219 0.449219 L 74.550781 0.449219 L 74.550781 74.550781 L 0.449219 74.550781 Z M 0.449219 0.449219 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="d24a024787">
        <path
          d="M 37.5 0.449219 C 17.039062 0.449219 0.449219 17.039062 0.449219 37.5 C 0.449219 57.960938 17.039062 74.550781 37.5 74.550781 C 57.960938 74.550781 74.550781 57.960938 74.550781 37.5 C 74.550781 17.039062 57.960938 0.449219 37.5 0.449219 Z M 37.5 0.449219 "
          clipRule="nonzero"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#aa5d9190d1)">
      <g clipPath="url(#d24a024787)">
        <path
          fill="#034037"
          d="M 0.449219 0.449219 L 74.550781 0.449219 L 74.550781 74.550781 L 0.449219 74.550781 Z M 0.449219 0.449219 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
    </g>
    <g fill="#29ff89" fillOpacity="1">
      <g transform="translate(22.255093, 54.062424)">
        <g>
          <path d="M 30.578125 0 L 25.640625 0 L 21.796875 -9.96875 L 8.140625 -9.96875 L 4.578125 0 L -0.046875 0 L 12.515625 -32.71875 L 17.1875 -32.71875 Z M 12.984375 -23.03125 L 9.421875 -13.4375 L 20.46875 -13.4375 L 16.453125 -24.21875 C 15.960938 -25.5 15.398438 -27.191406 14.765625 -29.296875 C 14.765625 -29.140625 14.582031 -28.367188 14.21875 -26.984375 C 13.851562 -25.597656 13.441406 -24.28125 12.984375 -23.03125 Z M 12.984375 -23.03125 " />
        </g>
      </g>
    </g>
  </svg>
);

export default ArcadiaIcon;
