import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
	({ theme }) => ({
		borderTop: `1px solid ${theme.palette.divider}`,
		borderBottom: `1px solid ${theme.palette.divider}`,
		px: '3%',
		backgroundColor: '#FAFAFB',
		'&:not(:last-child)': {
			borderBottom: 0
		},
		'&:before': {
			display: 'none'
		}
	})
);

const CustomAccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)'
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(3),
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'space-between'
	}
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	borderTop: '1px solid rgba(0, 0, 0, .125)',
	padding: 0,
	paddingTop: theme.spacing(2),
	paddingBottom: theme.spacing(2)
}));

interface AccordionSectionProps {
	title: string;
	summaryChildren: React.ReactNode;
	children: React.ReactNode;
	expanded?: boolean; // Optional prop for initial state
	onToggle?: (title: string, isExpanded: boolean) => void; // Callback for state changes
	style?: React.CSSProperties;
	printOpen?: boolean; // Prop to control the print state
}

const AccordionSection: React.FC<AccordionSectionProps> = ({
	title,
	summaryChildren,
	children,
	expanded = false,
	onToggle,
	style,
	printOpen = false, 
}) => {
	// Generate unique IDs based on the title
	const accordionId = `accordion-${title.replace(/\s+/g, '-').toLowerCase()}`;
	const summaryId = `${accordionId}-summary`;
	const detailsId = `${accordionId}-details`;

	const [isExpanded, setIsExpanded] = useState(expanded);

	useEffect(() => {
		setIsExpanded(expanded);
	}, [expanded]);

	// Adjust initial expanded state based on print mode
	const initialExpanded = printOpen ? true : isExpanded;

	return (
		<Accordion
			id={accordionId}
			expanded={initialExpanded}
			onChange={(event: React.SyntheticEvent, isExpanded: boolean) => {
				if (onToggle) {
					onToggle(title, isExpanded);
				} else {
					setIsExpanded(isExpanded);
				}
			}}
			style={style}
			className={printOpen ? "print-open" : ""}
		>
			<CustomAccordionSummary sx={{ flexDirection: 'row-reverse' }} id={summaryId}>
				{summaryChildren}
			</CustomAccordionSummary>
			<AccordionDetails id={detailsId}>{children}</AccordionDetails>
		</Accordion>
	);
};

export default AccordionSection;
