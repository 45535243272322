/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_delete_invoice_upload_pipeline_error_api_v2_pipeline_resolve_post } from '../models/Body_delete_invoice_upload_pipeline_error_api_v2_pipeline_resolve_post';
import type { Body_do_retry_api_v2_pipeline_retry_post } from '../models/Body_do_retry_api_v2_pipeline_retry_post';
import type { Body_upload_pdf_to_email_bucket_api_v2_pipeline_upload_pdf_post } from '../models/Body_upload_pdf_to_email_bucket_api_v2_pipeline_upload_pdf_post';
import type { Body_upload_single_file_api_v2_pipeline_upload_one_post } from '../models/Body_upload_single_file_api_v2_pipeline_upload_one_post';
import type { EmailBucketResponse } from '../models/EmailBucketResponse';
import type { InvoicesErrorHistory } from '../models/InvoicesErrorHistory';
import type { SfnSchema } from '../models/SfnSchema';
import type { UploadOneResponse } from '../models/UploadOneResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PipelineService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Upload Single File
     * Upload a file to the pipeline for processing
 *
 * ### *To be used with frontend upload file component*
     * @param formData 
     * @returns UploadOneResponse File submitted to pipeline
     * @throws ApiError
     */
    public uploadSingleFileApiV2PipelineUploadOnePost(
formData: Body_upload_single_file_api_v2_pipeline_upload_one_post,
): CancelablePromise<UploadOneResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/pipeline/upload/one',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request Error`,
                404: `Duplicate File Detected`,
                415: `File not allowed`,
                422: `Validation Error`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Sfn Read Single Execution Status
     * @param executionArn 
     * @returns SfnSchema Success
     * @throws ApiError
     */
    public sfnReadSingleExecutionStatusApiV2ReadSingleSfnExecutionGet(
executionArn: string,
): CancelablePromise<SfnSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/read/single/sfn-execution',
            query: {
                'execution_arn': executionArn,
            },
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get Invoice Errors
     * Returns a list of invoice error responses which are encountered during calling the upload one endpoint
     * @returns InvoicesErrorHistory Success
     * @throws ApiError
     */
    public getInvoiceErrorsApiV2PipelineErrorsGet(): CancelablePromise<Array<InvoicesErrorHistory>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/pipeline/errors',
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Do Retry
     * Retry a failed invoice pdf upload, file is already present on email bucket, LLM notes can be provided
     * @param requestBody 
     * @returns EmailBucketResponse Success
     * @throws ApiError
     */
    public doRetryApiV2PipelineRetryPost(
requestBody: Body_do_retry_api_v2_pipeline_retry_post,
): CancelablePromise<EmailBucketResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/pipeline/retry',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Delete Invoice Upload Pipeline Error
     * If an invoice upload is obsolete or invoice is not needed, mark it resolved in Mongo DB
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public deleteInvoiceUploadPipelineErrorApiV2PipelineResolvePost(
requestBody: Body_delete_invoice_upload_pipeline_error_api_v2_pipeline_resolve_post,
): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/pipeline/resolve',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Upload Pdf To Email Bucket
     * Upload pdfs to email bucket, which will start the automated process to call the upload one endpoint
     * @param formData 
     * @returns EmailBucketResponse Success
     * @throws ApiError
     */
    public uploadPdfToEmailBucketApiV2PipelineUploadPdfPost(
formData: Body_upload_pdf_to_email_bucket_api_v2_pipeline_upload_pdf_post,
): CancelablePromise<Array<EmailBucketResponse>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/pipeline/upload-pdf',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
