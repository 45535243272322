import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

interface MultiAutoCompleteProps {
	options: string[];
	label: string;
}

const MultiAutoComplete: React.FC<MultiAutoCompleteProps> = ({ options, label }) => {
	const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

	const handleOptionChange = (event: React.SyntheticEvent, value: string[]) => {
		setSelectedOptions(value);
	};

	return (
		<Autocomplete
			multiple
			id="multi-autocomplete"
			options={options}
			value={selectedOptions}
			onChange={handleOptionChange}
			renderInput={params => (
				<TextField {...params} variant="standard" label={label} id={`multiAutocompleteTextField-${label}`} />
			)}
		/>
	);
};

export default MultiAutoComplete;
