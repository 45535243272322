import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Authenticator } from '@aws-amplify/ui-react';
import { Box } from '@mui/material';
import logo from 'src/assets/logo/Envirally_final_logo_blue-02.svg';

document.title = 'Envirally - Admin';

const components = {
	Header() {
		return (
			<Box
				className="text-center"
				style={{
					marginTop: '5%',
					marginBottom: '5%',
					display: 'flex',
					justifyContent: 'center'
				}}
			>
				<img src={logo} className="envirally-logo" alt="envirally logo" style={{ width: '50%' }} />
			</Box>
		);
	}
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		{/* https://ui.docs.amplify.aws/react/connected-components/authenticator/advanced */}
		<Authenticator loginMechanisms={['email']} components={components}>
			<App />
		</Authenticator>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
