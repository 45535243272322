import { createTheme } from '@mui/material/styles';

import { Color } from './enums';
import Inter from '../assets/fonts/Inter-Regular.woff';

const DialogTheme = createTheme({
	components: {
		MuiInputLabel: {
			styleOverrides: {
				// Apply your custom font to form controls
				root: {
					fontFamily: Inter, // Use the font from global settings
					fontSize: '0.875rem', // Adjust font size as needed
					lineHeight: '1.5rem' // Adjust line height as needed
				}
			}
		}
	},
	palette: {
		primary: {
			main: Color.Primary
		},
		secondary: {
			main: Color.Secondary
		}
	},
	typography: {
		fontFamily: Inter,
		// H1
		h1: {
			fontWeight: 'bold',
			fontSize: '2.5rem' // 40px
		},

		// H2
		h2: {
			fontWeight: 'bold',
			fontSize: '2rem' // 32px
		},

		// H3
		h3: {
			fontWeight: '600', // Semi-bold
			fontSize: '1.5rem' // 24px
		},

		// H4
		h4: {
			fontWeight: '600', // Semi-bold
			fontSize: '1rem' // 20px
		},

		// Paragraph
		body1: {
			fontWeight: 'normal', // Regular
			fontSize: '1rem' // 16px
		},

		// H5 (ALL CAPS)
		h5: {
			fontWeight: '600', // Regular
			fontSize: '0.85rem' // 12px
			// textTransform: "uppercase",
		},

		// H5 (ALL CAPS) - Smaller Size
		h6: {
			fontFamily: 'Inter',
			fontWeight: 'normal', // Regular
			fontSize: '0.625rem', // 10px
			textTransform: 'none'
		}
	}
});

export default DialogTheme;
