/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type getAccountsInformationResponse = {
    status: getAccountsInformationResponse.status;
    /**
     * Successfully retrieved account information
     */
    message: string;
    body: {
        utility_account_id: string;
        utility_account_name: string;
        utility_account_number: string;
        facility_id: string;
        facility_name: string;
        group_id: string;
        group_name: string;
        division_id: string;
        division_name: string;
        organization_id: string;
        organization_name: string;
        utility_id: string;
        utility_name: string;
    };
};

export namespace getAccountsInformationResponse {
    export enum status {
        SUCCESS = 'Success',
    }
}
