import React from 'react';
import { Grid, FormControl, Select, MenuItem, TextField, InputAdornment, Button, InputLabel } from '@mui/material';

//icons
import SearchIcon from '@mui/icons-material/Search';

export interface FilterElement {
	id: string;
	type: 'text' | 'select';
	label: string;
	value: string;
	options?: string[];
	onChange: (value: string) => void;
	xs?: number;
}

interface DataTableFilterProps {
	filterElements: FilterElement[];
	handleClearFilters: () => void;
	buttonXs?: number;
}

const DataTableFilter: React.FC<DataTableFilterProps> = ({ filterElements, handleClearFilters, buttonXs }) => {
	return (
		<Grid container spacing={2} id={`dataTableFilerGrid`}>
			{filterElements.map((filter, index) => (
				<Grid item xs={filter.xs || 3} key={index}>
					{filter.type === 'text' ? (
						<FormControl fullWidth>
							<TextField
								id={`dataTableFilterTextField-${index}`}
								label={filter.label}
								variant="outlined"
								size="small"
								value={filter.value}
								onChange={e => filter.onChange(e.target.value)}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon fontSize="small" color="action" />
										</InputAdornment>
									)
								}}
							/>
						</FormControl>
					) : (
						<FormControl id={`dataTableFilterSelectFormControl`} fullWidth size="small">
							<InputLabel>{filter.label}</InputLabel>
							<Select
								id={`dataTableFilterSelect-${index}`}
								labelId={`${filter.id}-select`}
								value={filter.value}
								onChange={e => filter.onChange(e.target.value as string)}
								variant="outlined"
								label={filter.label}
							>
								{filter.options?.map((option, optionIndex) => (
									<MenuItem
										id={`dataTableFilterSelectMenuItem-${index}-${optionIndex}`}
										value={option}
										key={optionIndex}
									>
										{option}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
				</Grid>
			))}
			<Grid id="dataTableFilterClearFiltersGrid" item xs={buttonXs} textAlign="end">
				<Button id="dataTableFilterClearFiltersButton" variant="outlined" onClick={handleClearFilters}>
					Clear Filters
				</Button>
			</Grid>
		</Grid>
	);
};

export default DataTableFilter;
