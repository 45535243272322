import { useQuery } from 'react-query';
import { ApiError } from 'src/modules/generated-api-clients/no-sql';
import { useApiNoSqlContext } from 'src/modules/contexts/ApiNoSqlContext';
import { AxiosError } from 'axios';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { QUERY_KEYS } from './enums';
import { logApiError, logAxiosError, logError, logRequest } from './helpers';

export const useQueryReadFile = (key: string, useUploadFailureQuery = false) => {
	const { client } = useApiNoSqlContext();
	const { user } = useAuthenticator(context => [context.user]);

	return useQuery<string, ApiError>(
		[QUERY_KEYS.download.SingleBase64File, key, user, useUploadFailureQuery],
		async () => {
			try {
				if (!client) {
					throw new Error('API client is not initialized');
				}
				if (user.attributes === undefined) {
					throw new Error('AWS Amplify user attributes cannot be undefined');
				}

				const response = useUploadFailureQuery
					? await client.download.s3ReadFailedInvoiceApiV2S3ReadFailedInvoiceGet(key)
					: await client.download.s3ReadOneBase64FileApiV2S3ReadOneGet(key);
				// logRequest({ key }, 'GET', QUERY_KEYS.download.SingleBase64File, response);

				// Decode the Base64 string to a byte array
				const byteCharacters = atob(response);
				const byteNumbers = new Array(byteCharacters.length);
				for (let i = 0; i < byteCharacters.length; i++) {
					byteNumbers[i] = byteCharacters.charCodeAt(i);
				}
				const byteArray = new Uint8Array(byteNumbers);

				// Create a Blob from the byte array
				const blob = new Blob([byteArray], { type: 'application/pdf' });

				// Create a URL from the Blob
				const fileUrl = URL.createObjectURL(blob);
				console.log(`DERIVE fileUrl(key=${key} -> ${fileUrl}`);
				return fileUrl;
			} catch (error: unknown) {
				if (error instanceof ApiError) {
					logApiError(error);
				} else if (error instanceof AxiosError) {
					logAxiosError(error);
				} else {
					logError(error, 'Error reading file as Base64 string');
				}
				throw error;
			}
		},
		{
			enabled: !!key
		}
	);
};

export const useQueryDownloadFile = (key: string) => {
	const { client } = useApiNoSqlContext();
	const { user } = useAuthenticator(context => [context.user]);

	return useQuery<string, ApiError>(
		[QUERY_KEYS.download.SingleFile, key, user],
		async () => {
			try {
				if (!client) {
					throw new Error('API client is not initialized');
				}

				if (user.attributes === undefined) {
					throw new Error('AWS Amplify user attributes cannot be undefined');
				}

				const response = await client.download.s3DownloadOneFileApiV2S3DownloadOneGet(key);
				logRequest({ key }, 'GET', QUERY_KEYS.download.SingleFile, response);

				return response;
			} catch (error: unknown) {
				if (error instanceof ApiError) {
					logApiError(error);
				} else if (error instanceof AxiosError) {
					logAxiosError(error);
				} else {
					logError(error, 'Error downloading file');
				}
				throw error;
			}
		},
		{
			enabled: !!key
		}
	);
};
