import React, { useState, useRef, useEffect } from 'react';
import {
	Dialog,
	DialogContent,
	Typography,
	TextField,
	Box,
	Divider,
	Grid,
	IconButton,
	Tooltip,
	Stack
} from '@mui/material';

import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import { PdfViewer } from 'src/modules/components';

//api
import { useQueryAlerts } from 'src/modules/hooks/apis/sql/alerts';

interface CommunicationDialogProps {
	open: boolean;
	onClose: () => void;
	alert_id?: string;
}

const CommunicationDialog: React.FC<CommunicationDialogProps> = props => {
	const { open, onClose, alert_id } = props;
	const [reply, setReply] = useState('');
	const [attachedFiles, setAttachedFiles] = useState<File[]>([]);
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const [remainingCharacters, setRemainingCharacters] = useState(200);
	const maxCharacterLimit = 200;

	const { data: alertsData, isLoading, isError, error } = useQueryAlerts();

	useEffect(() => {
		if (isError) {
			console.log('error', error);
		}
	}, [isError, error]);

	const handleReplyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newText = event.target.value;
		setReply(newText);

		const remainingCharacters = maxCharacterLimit - newText.length;
		setRemainingCharacters(remainingCharacters);
	};

	const handleReplySubmit = () => {
		if (reply) {
			setReply('');
			setAttachedFiles([]);
			setRemainingCharacters(200);
		}
	};

	// ==================================== FILE ATTACHMENT ====================================

	const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files) {
			setAttachedFiles([...attachedFiles, ...Array.from(event.target.files)]);
			if (fileInputRef.current) {
				fileInputRef.current.value = '';
			}
		}
	};

	const handleDeleteFile = (index: number) => {
		const updatedFiles = [...attachedFiles];
		updatedFiles.splice(index, 1);
		setAttachedFiles(updatedFiles);
	};

	return (
		<Dialog open={open} maxWidth="xl" fullWidth onClose={onClose}>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={8}>
						<PdfViewer invoiceUrl="" />
					</Grid>
					<Grid item xs={4}>
						<Typography id="dialogTitle" variant="h4" gutterBottom>
							Communication History
						</Typography>
						<Divider sx={{ pb: 1 }} />
						{/* <Box>
              {alertsData && alertsData.map((message, index) => (
                <Box key={index}>
                  {message.from === "receiver" ? (
                    <Box sx={{ py: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={9}>
                          <Box
                            sx={{
                              border: "1px solid #1D4A74",
                              borderRadius: "12px",
                              p: 2,
                            }}
                          >
                            <Typography
                              id="messageReceiverName"
                              variant="h5"
                              sx={{ mb: 1 }}
                            >
                              {message.receiver_name}
                            </Typography>
                            <Typography id="messageReceivedContent">
                              {message.message}
                            </Typography>

                            {message.attachment && (
                              <Box
                                sx={{
                                  borderRadius: "12px",
                                  border: "2px solid #1D4A74",
                                  mt: 1,
                                  p: 1,
                                  cursor: "pointer",
                                  bgcolor: "#EBF3FA",
                                }}
                              >
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={1}
                                >
                                  <InvoicesIcon
                                    color="black"
                                    width="40"
                                    height="40"
                                  />
                                  <Typography
                                    id="messageReceiverAttachment"
                                    variant="caption"
                                  >
                                    {message.attachment}
                                  </Typography>
                                </Stack>
                              </Box>
                            )}
                          </Box>

                          <Box>
                            <Typography
                              id="messageReceiverTimestamp"
                              variant="caption"
                            >
                              {message.timestamp}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    <Box sx={{ py: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={9}>
                          <Box
                            sx={{
                              bgcolor: "#1D4A74",
                              borderRadius: "12px",
                              p: 2,
                            }}
                          >
                            <Typography
                              id="messageSenderName"
                              variant="h5"
                              color="white"
                              sx={{ mb: 1 }}
                            >
                              {message.sender_name}
                            </Typography>
                            <Typography id="messageSenderContent" color="white">
                              {message.message}
                            </Typography>

                            {message.attachment && (
                              <Box
                                sx={{
                                  borderRadius: "12px",
                                  border: "2px solid #1D4A74",
                                  mt: 1,
                                  p: 1,
                                  cursor: "pointer",
                                  bgcolor: "#EBF3FA",
                                }}
                              >
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={1}
                                >
                                  <InvoicesIcon
                                    color="black"
                                    width="40"
                                    height="40"
                                  />
                                  <Typography
                                    id="messageSenderAttachment"
                                    variant="caption"
                                  >
                                    {message.attachment}
                                  </Typography>
                                </Stack>
                              </Box>
                            )}
                          </Box>

                          <Box textAlign="end">
                            <Typography
                              id="messageSenderTimestamp"
                              variant="caption"
                            >
                              {message.timestamp}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Box>
              ))}
            </Box> */}
						<Divider />

						<Box sx={{ mt: 2 }}>
							{attachedFiles.length > 0 && (
								<div>
									<Typography variant="h5">Attached: </Typography>
									{attachedFiles.map((file, index) => (
										<Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
											<a
												id={`attachedFile-${index}`}
												href={URL.createObjectURL(file)}
												download={file.name}
											>
												{file.name}
											</a>
											<IconButton
												id={`deleteFileButton-${index}`}
												color="error"
												onClick={() => handleDeleteFile(index)}
												size="small"
											>
												<DeleteIcon fontSize="small" />
											</IconButton>
										</Box>
									))}
								</div>
							)}

							<TextField
								id="replyTextField"
								label="Message"
								multiline
								fullWidth
								rows={3}
								variant="outlined"
								value={reply}
								onChange={handleReplyChange}
								inputProps={{ maxLength: maxCharacterLimit }}
								sx={{ mt: 2 }}
							/>
							<Typography id="remainingCharactersInfo" variant="caption">
								You have {remainingCharacters} characters remaining
							</Typography>

							<Box display="flex" justifyContent="end" sx={{ mt: 2 }}>
								<Tooltip title="Reply">
									<IconButton id="sendButton" color="primary" onClick={handleReplySubmit}>
										<SendIcon />
									</IconButton>
								</Tooltip>
								<Tooltip title="Attachment">
									<IconButton id="attachButton" onClick={() => fileInputRef.current?.click()}>
										<AttachFileIcon />
										<input
											id="fileInput"
											type="file"
											ref={fileInputRef}
											style={{ display: 'none' }}
											onChange={handleFileInputChange}
										/>
									</IconButton>
								</Tooltip>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default CommunicationDialog;
