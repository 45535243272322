import { LinkProps, Path } from 'react-router-dom';

import { Route } from './enums';
import {
	HashString,
	QueryParams,
	RouteParams,
	SafeLocationStateTypeGuards,
	SafeLocationStates,
	SplitPathAndHash
} from './types';
import { routePathTemplates } from './constants';

/**
 * Validates the state object for a given route.
 *
 * By default, undefined state is valid since not all routes have state.
 *
 * If a route has state, then the state must be defined and the type guard must be defined.
 * Otherwise, it is assumed that the state is valid.
 *
 * @param {Route} route - The static enumerated route.
 * @param {TState} state - The state object to validate.
 * @returns {boolean} A boolean indicating whether the state object is valid for the given route.
 */
export const isRouteState = <K extends Route>(state: any, guard: SafeLocationStateTypeGuards[K]): boolean => {
	if (state === undefined || state === null || guard === undefined) {
		return true;
	}
	return guard(state);
};

/**
 * Constructs a path string for a given route with the provided parameters.
 * This requires runtime validation of the parameters and will throw an error if the parameters are invalid.
 *
 * @template K - The type of the route from the `Route` enum.
 * @param route - The route for which to construct the path.
 * @param routeParams - An object containing the route parameters.
 * @param queryParams - An object containing the query parameters.
 * @param hash - Optional hash fragment for the URL.
 * @returns The constructed path string.
 * @throws Will throw an error if the route parameters are invalid.
 */
export const constructPathString = <K extends Route>(
	route: K,
	routeParams: RouteParams[K],
	queryParams: QueryParams[K],
	hash: HashString<K>
): string => {
	let [basePath, queryStringTemplate] = routePathTemplates[route].split('?');

	// Replace route parameters in the base path
	for (const [key, value] of Object.entries(routeParams)) {
		basePath = basePath.replace(`:${key}`, encodeURIComponent(String(value)));
	}

	// Process template query string
	const queryStringParts: string[] = [];
	if (queryStringTemplate) {
		const templateParams = queryStringTemplate.split('&');
		for (const param of templateParams) {
			const [key, type] = param.split('=');
			if (key in queryParams) {
				const paramValue = (queryParams as Record<string, string | undefined>)[key];
				if (paramValue !== undefined) {
					queryStringParts.push(`${key}=${encodeURIComponent(String(paramValue))}`);
				}
			} else {
				// Improved error message
				throw new Error(`Invalid query parameter key '${key}' for route '${route}'. Expected type: ${type}`);
			}
		}
	}

	// Append additional query parameters not in the template
	for (const [key, value] of Object.entries(queryParams as Record<string, string | undefined>)) {
		if (!queryStringTemplate || !queryStringTemplate.includes(`${key}=`)) {
			if (!(key in queryParams)) {
				// Ensure the key is a valid query param key for the route
				throw new Error(`Invalid additional query parameter key '${key}' for route '${route}'.`);
			}
			queryStringParts.push(`${key}=${encodeURIComponent(String(value))}`);
		}
	}

	const finalQueryString = queryStringParts.join('&');
	const finalPath = finalQueryString ? `${basePath}?${finalQueryString}` : basePath;
	return hash ? `${finalPath}#${encodeURIComponent(hash)}` : finalPath;
};

/**
 * Constructs a `Path` object for use with the `navigate` function in react-router-dom.
 *
 * This function takes a route, route parameters, query parameters, and a hash fragment
 * to construct a `Path` object that represents a complete URL.
 *
 * @template K - The type of the route from the `Route` enum.
 * @param {K} route - The route for which to construct the path.
 * @param {RouteParams[K]} routeParams - The route parameters.
 * @param {QueryParams[K]} queryParams - The query parameters.
 * @param {HashString<K>} hash - The hash fragment.
 * @returns {Path} The constructed `Path` object.
 */
export const constructPathObject = <K extends Route>(
	route: K,
	routeParams: RouteParams[K],
	queryParams: QueryParams[K],
	hash: HashString<K>
): Partial<Path> => {
	let basePath = routePathTemplates[route].split('?')[0];

	// Replace route parameters in the base path
	for (const [key, value] of Object.entries(routeParams)) {
		basePath = basePath.replace(`:${key}`, encodeURIComponent(String(value)));
	}

	// Construct query string
	const queryString = Object.entries(queryParams)
		.filter(([_, value]) => value !== undefined)
		.map(([key, value]) => `${key}=${encodeURIComponent(String(value))}`)
		.join('&');

	// Construct the final path object
	const pathObject: Partial<Path> = {
		pathname: basePath,
		search: `?${queryString}`,
		hash: hash ? `#${encodeURIComponent(hash)}` : hash
	};

	return pathObject;
};

/**
 * Constructs `LinkProps` from react-router-dom for a given route, state, and parameters.
 *
 * @template K - The route type extending from the `Route` enum.
 * @param {K} route - The static enumerated route.
 * @param {SafeLocationStates[K]} state - The state object for the route.
 * @param {QueryParams[K]} queryParams - An object containing the query parameters.
 * @param {RouteParams[K]} routeParams - The parameters for the route.
 * @returns {LinkProps} The `LinkProps` object for use with React Router.
 */
export const constructLinkProps = <K extends Route>(
	route: K,
	state: SafeLocationStates[K],
	routeParams: RouteParams[K],
	queryParams: QueryParams[K],
	hash: HashString<K>
): LinkProps => {
	let path: string = routePathTemplates[route];

	// Replace route parameters in the path
	if (routeParams) {
		for (const [key, value] of Object.entries(routeParams)) {
			path = path.replace(`:${key}`, encodeURIComponent(String(value)));
		}
	}

	// Construct query string if queryParams is provided
	const queryString = queryParams
		? '?' +
		  Object.entries(queryParams)
				.filter(([, value]) => value !== undefined) // Remove undefined values
				.map(([key, value]) => `${key}=${encodeURIComponent(String(value))}`)
				.join('&')
		: '';

	// Construct the LinkProps object with the pathname with everything before '?'
	return {
		to: {
			pathname: path.replace(/\?.*/, ''),
			search: queryString,
			hash: hash
		},
		state: state
	};
};

// Helper function to split a path into path and hash
export const splitPathAndHash = <S extends string>(path: S): SplitPathAndHash<S> => {
	const hashIndex = path.indexOf('#');
	if (hashIndex === -1) {
		return [path, undefined] as SplitPathAndHash<S>;
	}
	// Use hashIndex + 1 to skip the '#' character
	return [path.substring(0, hashIndex), path.substring(hashIndex + 1)] as SplitPathAndHash<S>;
};

// Helper function to split a path into path and query string
export const parsePathnameFromRoutePath = <K extends Route>(route: K): string => {
	// Get the path template for the given route
	const pathTemplate = String(routePathTemplates[route]);

	// Split the path template into pathname and the rest (query string and hash fragment)
	// Splits at the first occurrence of '?' or '#' character
	const [pathname] = pathTemplate.split(/[?#]/);

	// Strip any '{' or '}' characters from the pathname
	return pathname.replace(/[{}]/g, '');
};

// Helper function to validate query parameter types
/**
 * Validates the type of a query parameter.
 * Expected types are 'string', 'number', 'boolean', or undefined (determined at compile-time).
 * Value types are string or null (determined at runtime).
 * Expected type being undefined at compile-time means an optional parameter was not provided.
 * Value being null at runtime means an optional parameter was not provided.
 *
 * @param _key - The key of the query parameter.
 * @param value - The value of the query parameter.
 * @param expectedType - The expected type of the query parameter.
 * @returns A boolean indicating whether the query parameter type is valid.
 */
export const validateQueryParamType = (
	_key: string,
	value: string | null,
	expectedType: string | undefined
): boolean => {
	// The parameter is optional and not present
	if (expectedType === undefined || value === null) {
		return true;
	}

	// The parameter is optional and present
	switch (expectedType) {
		case 'string':
			return true; // All URLSearchParams values are strings
		case 'number':
			return !isNaN(parseFloat(value));
		case 'boolean':
			return value === 'true' || value === 'false';
		default:
			return false;
	}
};

/**
 * Converts a value to the specified type.
 * Used for converting optional (possibly undefined) query parameter types.
 * @param value - The value to be converted.
 * @param type - The type to convert the value to. Can be 'number', 'boolean', or undefined (defaults to string).
 * @returns The converted value.
 */
export const convertToType = (value: string, type: string | undefined) => {
	switch (type) {
		case 'number':
			return parseFloat(value);
		case 'boolean':
			return value === 'true';
		default:
			return value; // Default is string
	}
};
