/**
 * @file hooks.ts
 * @description Custom hooks and utility functions for navigation in the application.
 *
 * These hooks leverage the type-safe route configurations defined in the application to
 * ensure that navigation and state management are consistent with the expected types.
 */

import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
  NavigateOptions,
  Params,
} from "react-router-dom";

import { Route } from "./enums";
import type {
  RouteParams,
  QueryParams,
  SafeLocationStates,
  ExtractHash,
  RoutePathTemplates,
  HashString,
} from "./types";
import {
  locationStateTypeGuards,
  queryParamsPrototypes,
  routePathTemplates,
} from "./constants";
import {
  isRouteState,
  splitPathAndHash,
  constructPathObject,
  validateQueryParamType,
  convertToType,
} from "./tools";

/**
 * Hook to retrieve the location state of the current route in a type-safe manner.
 * It provides a function that, when called with a route, returns the typed location state associated with that route.
 * @template K - A type that extends from the Route enumeration.
 * @returns {Function} A function that accepts a route and returns the typed location state for that route.
 * @example
 * const getLocationState = useSafeLocationState();
 * const clientState = getLocationState<Route.Client>(Route.Client); // Strongly typed as SafeLocationStates[Route.Client]
 */
export const useSafeLocationState = (): (<K extends Route>(
  route: K
) => SafeLocationStates[K]) => {
  const location = useLocation();

  /**
   * Function to get the typed location state for a specific route.
   * This function verifies that the location state is valid for the given route using the associated type guard.
   * @template K - A type that extends from the Route enumeration.
   * @param {K} route - The route key of the enumeration type Route.
   * @returns {SafeLocationStates[K]} The location state typed according to the route's associated state type.
   * @throws Will throw an error if the location state is not valid for the given route.
   */
  return <K extends Route>(route: K): SafeLocationStates[K] => {
    if (!isRouteState<K>(location.state, locationStateTypeGuards[route])) {
      console.log(
        `Invalid location state for route ${route} with location state: ${JSON.stringify(location.state)}`
      );
      throw new Error("Invalid route location state type");
    }
    // Cast is safe because of the above check
    return location.state as SafeLocationStates[K];
  };
};

/**
 * Hook to perform navigation actions in a type-safe manner.
 * It provides a function that encapsulates the navigate function from react-router-dom.
 * The function allows for passing in type-safe parameters and state.
 * @template K - A type that extends from the Route enumeration.
 * @returns {Function} A function to perform type-safe navigation actions.
 * @example
 * const safeNavigate = useSafeNavigate();
 * safeNavigate(Route.Client, { id: '123' }, { name: 'Alice' }, 'section', { replace: true });
 */
export const useSafeNavigate = (): (<K extends Route>(
  route: K | number,
  state: SafeLocationStates[K],
  routeParams: RouteParams[K],
  queryParams: QueryParams[K],
  hash: ExtractHash<RoutePathTemplates[K]>,
  options?: Omit<NavigateOptions, "state">
) => void) => {
  const navigate = useNavigate();

  /**
   * Navigates to a specified route with type safety for route parameters, query parameters, hash, and state.
   * @template K - A type that extends from the Route enumeration.
   * @param {K} route - The enumerated route to navigate to.
   * @param {SafeLocationStates[K]} state - The state object for the route.
   * @param {RouteParams[K]} routeParams - The parameters for the route.
   * @param {QueryParams[K]} queryParams - The query parameters for the route.
   * @param {ExtractHash<RoutePathTemplates[K]>} hash - The hash fragment for the URL.
   * @param {Omit<NavigateOptions, 'state'>} options - Additional navigation options excluding 'state'.
   */
  return <K extends Route>(
    route: K | number,
    state: SafeLocationStates[K],
    routeParams: RouteParams[K],
    queryParams: QueryParams[K],
    fragment: ExtractHash<RoutePathTemplates[K]>,
    options?: Omit<NavigateOptions, "state">
  ): void => {
    if (typeof route === "number") {
      navigate(route);
      return;
    }
    const { pathname, search, hash } = constructPathObject(
      route,
      routeParams,
      queryParams,
      fragment
    );
    console.log("src/modules/router/hooks.ts NAVIGATE to:", { route, state, pathname, search, hash });
    navigate({ pathname, search, hash }, { ...options, state });
    return;
  };
};

/**
 * Hook to retrieve route parameters for the current route in a type-safe manner.
 * It leverages the useParams hook from react-router-dom, providing type-safe route parameters.
 * @template K - A type that extends from the Route enumeration.
 * @returns {Partial<RouteParams[K]>} The typed route parameters for the current route.
 * @example
 * const params = useSafeRouteParams<Route.Client>();
 * // params is now typed as RouteParams[Route.Client]
 */
export const useSafeRouteParams = <K extends Route>(): Readonly<
  [RouteParams[K]] extends [string]
    ? Params<RouteParams[K]>
    : Partial<RouteParams[K]>
> => {
  return useParams<RouteParams[K]>();
};

/**
 * Hook to retrieve query parameters for the current route in a type-safe manner.
 * It parses the current URL's search string and returns the query parameters as a typed object.
 * @returns {} A function that returns the typed query parameters for the route.
 * @example
 * const getQueryParams = useQueryParams<Route.Client>();
 * const queryParams = getQueryParams(); // Typed as QueryParams[Route.Client]
 */
export const useSafeQueryParams = <K extends Route>(
  route: K
): QueryParams[K] => {
  const [searchParams] = useSearchParams();

  // Now we're using the actual route value to access the correct prototype
  const queryParamsProtoForRoute = queryParamsPrototypes[route];

  const queryParams: Partial<QueryParams[K]> = {};

  Object.keys(queryParamsProtoForRoute).forEach((key) => {
    const value = searchParams.get(key);
    const expectedType = queryParamsProtoForRoute[key as keyof QueryParams[K]];

    if (validateQueryParamType(key, value, expectedType)) {
      // Using type assertion here
      queryParams[key as keyof QueryParams[K]] =
        value !== null
          ? (convertToType(value, expectedType) as any)
          : undefined;
    } else if (value === null) {
      queryParams[key as keyof QueryParams[K]] = undefined;
    } else {
      console.warn(`Invalid query parameter: ${key}`);
    }
  });

  return queryParams as QueryParams[K];
};

/**
 * Hook to retrieve the hash fragment for the current route in a type-safe manner.
 * It returns the hash fragment as a typed value.
 * @returns {Function} A function that returns the hash fragment for the route.
 * @throws Will throw an error if the hash fragment is not valid for the route.
 * @example
 * const getHash = useHash<Route.Client>();
 * try {
 * 	const hash = getHash(); // Typed as HashString[Route.Client]
 * } catch (error) {
 * 	console.error(error);
 * }
 */
export const useSafeHashFragment = (): (<K extends Route>(
  route: K
) => ExtractHash<RoutePathTemplates[K]>) => {
  const location = useLocation();

  /**
   * Function to retrieve the hash fragment for the current route.
   * @template K - A type that extends from the Route enumeration.
   * @returns {ExtractHash<RoutePathTemplates[K]>} The hash fragment for the route.
   * @throws Will throw an error if the hash fragment is not valid for the route.
   */
  return <K extends Route>(route: K): HashString<K> => {
    // Extract the expected hash for the given route
    const [, expectedHash] = splitPathAndHash(routePathTemplates[route]);

    // Get the actual hash from the location and strip out the leading '#'
    const actualHash = location.hash.replace("#", "");

    // Validate the actual hash against the expected hash
    if (actualHash !== expectedHash && actualHash !== "") {
      throw new Error(
        `Unexpected hash fragment for route ${route}. Expected: ${expectedHash}, found: ${actualHash}`
      );
    }

    // Casting is safe after validation
    return actualHash as HashString<K>;
  };
};
