import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/system';

interface ConnectionStatusProps {
	status: 'error' | 'green' | 'gray';
}

const ConnectionStatus: React.FC<ConnectionStatusProps> = ({ status }) => {
	const theme = useTheme();

	// Define colors based on the status prop
	let color;
	switch (status) {
		case 'error':
			color = theme.palette.error.main;
			break;
		case 'green':
			color = '#034037';
			break;
		case 'gray':
			color = '#ccc';
			break;
		default:
			color = theme.palette.primary.main;
	}

	return (
		<Box
			id="connectionStatus"
			width={20}
			height={20}
			borderRadius="50%"
			border="1px solid #4D4D4D"
			bgcolor={color}
		/>
	);
};

export default ConnectionStatus;
