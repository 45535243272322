import { FunctionComponent } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

const GoBackButton: FunctionComponent<{}> = () => {
	return (
		<Tooltip title="Back" placement="top">
			<IconButton sx={{ border: '2px solid #ccc' }} size="small" onClick={() => window.history.back()}>
				<ArrowBack />
			</IconButton>
		</Tooltip>
	);
};

export default GoBackButton;
