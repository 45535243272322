type UtilitiesIconProps = {
  color: string; // Assuming 'color' is a CSS color value (e.g., "blue")
};

const UtilitiesIcon: React.FC<UtilitiesIconProps> = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M10.2111 20.1056L9.98754 20.5528L9.98754 20.5528L10.2111 20.1056ZM9.14377 15.6858L8.68167 15.8768L9.14377 15.6858ZM8.91839 15.2337L8.52328 15.5401L8.91839 15.2337ZM15.0816 15.2337L15.4767 15.5401L15.0816 15.2337ZM17.5 10C17.5 11.8007 16.6352 13.3992 15.2962 14.4033L15.8961 15.2034C17.4761 14.0185 18.5 12.1288 18.5 10H17.5ZM12 4.5C15.0376 4.5 17.5 6.96243 17.5 10H18.5C18.5 6.41015 15.5899 3.5 12 3.5V4.5ZM6.5 10C6.5 6.96243 8.96243 4.5 12 4.5V3.5C8.41015 3.5 5.5 6.41015 5.5 10H6.5ZM8.70383 14.4033C7.3648 13.3992 6.5 11.8007 6.5 10H5.5C5.5 12.1288 6.52387 14.0185 8.1039 15.2034L8.70383 14.4033ZM10.4974 19.7535C10.4668 18.2874 10.1631 16.8435 9.60587 15.4949L8.68167 15.8768C9.1917 17.1111 9.4696 18.4325 9.49764 19.7744L10.4974 19.7535ZM13.5652 19.6584C12.5799 20.151 11.4201 20.151 10.4348 19.6584L9.98754 20.5528C11.2544 21.1862 12.7456 21.1862 14.0125 20.5528L13.5652 19.6584ZM14.3941 15.4949C13.8369 16.8435 13.5332 18.2874 13.5026 19.7535L14.5024 19.7744C14.5304 18.4325 14.8083 17.1111 15.3183 15.8768L14.3941 15.4949ZM14.0125 20.5528C14.3131 20.4024 14.4956 20.0987 14.5024 19.7744L13.5026 19.7535C13.5033 19.7185 13.5234 19.6793 13.5652 19.6584L14.0125 20.5528ZM9.49764 19.7744C9.50442 20.0987 9.68686 20.4024 9.98754 20.5528L10.4348 19.6584C10.4766 19.6793 10.4967 19.7185 10.4974 19.7535L9.49764 19.7744ZM8.1039 15.2034C8.42245 15.4422 8.49305 15.5011 8.52328 15.5401L9.31351 14.9273C9.17866 14.7534 8.96127 14.5964 8.70383 14.4033L8.1039 15.2034ZM9.60587 15.4949C9.51909 15.2849 9.44612 15.0983 9.31351 14.9273L8.52328 15.5401C8.55574 15.582 8.57799 15.6259 8.68167 15.8768L9.60587 15.4949ZM15.2962 14.4033C15.0387 14.5964 14.8213 14.7534 14.6865 14.9273L15.4767 15.5401C15.5069 15.5011 15.5775 15.4422 15.8961 15.2034L15.2962 14.4033ZM15.3183 15.8768C15.422 15.6259 15.4443 15.582 15.4767 15.5401L14.6865 14.9273C14.5539 15.0983 14.4809 15.2849 14.3941 15.4949L15.3183 15.8768Z"
      fill={color}
    />
    <path
      d="M14.5 16.5V16.5C12.8951 17.1419 11.1049 17.1419 9.5 16.5V16.5"
      stroke={color}
    />
    <path
      d="M11 14V9.75C11 9.05964 10.4404 8.5 9.75 8.5V8.5C9.05964 8.5 8.5 9.05964 8.5 9.75V9.75C8.5 10.4404 9.05964 11 9.75 11H14.25C14.9404 11 15.5 10.4404 15.5 9.75V9.75C15.5 9.05964 14.9404 8.5 14.25 8.5V8.5C13.5596 8.5 13 9.05964 13 9.75V14"
      stroke={color}
      strokeLinecap="round"
    />
  </svg>
);

export default UtilitiesIcon;
