/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Units of measure for all commodities.
 *
 * This will replace all other enums for UOMs.
 */
export enum UnitOfMeasureEnum {
    BCF = 'BCF',
    BCFE = 'BCFE',
    CCF = 'CCF',
    LITRES = 'LITRES',
    MMBTU = 'MMBTU',
    MCF = 'MCF',
    M3 = 'M3',
    GJ = 'GJ',
    GALLONS = 'GALLONS',
    KWH = 'KWH',
    CUMTRS = 'CUMTRS',
    THERMS = 'THERMS',
    DEKATHERMS = 'DEKATHERMS',
    UNKNOWN = 'UNKNOWN',
}
