/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type getProductionUnitsResponse = {
    status: getProductionUnitsResponse.status;
    /**
     * Production Units fetched successfully
     */
    message: string;
    body: Array<{
        production_unit_id: string;
        production_units: string;
    }>;
};

export namespace getProductionUnitsResponse {
    export enum status {
        SUCCESS = 'Success',
    }
}
