/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UtilityOptional } from '../models/UtilityOptional';
import type { UtilityTemplate } from '../models/UtilityTemplate';
import type { UtilityTemplateBase } from '../models/UtilityTemplateBase';
import type { UtilityUpdate } from '../models/UtilityUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UtilityCompanyUtilityTemplateService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List All Utility Company
     * @param id
     * @param sqlId
     * @param invoiceCompanyName
     * @param project Query param string with comma separated field names as they appear in database
     * @param skip
     * @param limit
     * @returns UtilityOptional Success
     * @throws ApiError
     */
    public listAllUtilityCompanyApiV2ReadListUtilityTemplateGet(
        id?: string,
        sqlId?: string,
        invoiceCompanyName?: string,
        project?: string,
        skip?: number,
        limit?: number,
    ): CancelablePromise<Array<UtilityOptional>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/read/list/utility-template',
            query: {
                '_id': id,
                'sql_id': sqlId,
                'invoice_company_name': invoiceCompanyName,
                'project': project,
                'skip': skip,
                'limit': limit,
            },
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Read One Utility Company
     * Single Utility by utility_id
     * @param id
     * @param sqlId
     * @param invoiceCompanyName
     * @param project Query param string with comma separated field names as they appear in database
     * @returns UtilityOptional Success
     * @throws ApiError
     */
    public readOneUtilityCompanyApiV2ReadSingleUtilityTemplateGet(
        id?: string,
        sqlId?: string,
        invoiceCompanyName?: string,
        project?: string,
    ): CancelablePromise<UtilityOptional> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/read/single/utility-template',
            query: {
                '_id': id,
                'sql_id': sqlId,
                'invoice_company_name': invoiceCompanyName,
                'project': project,
            },
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create a new utility company
     * Create a new utility company.
     *
     * - **_id**: is created dynamically.
     * - Not required in post request.
     * @param requestBody
     * @returns UtilityTemplate Success
     * @throws ApiError
     */
    public createUtilityCompanyApiV2CreateUtilityTemplatePost(
        requestBody: UtilityTemplateBase,
    ): CancelablePromise<UtilityTemplate> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/create/utility-template',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update Utility Company
     * @param id
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public updateUtilityCompanyApiV2UpdateUtilityTemplateIdPatch(
        id: string,
        requestBody: UtilityUpdate,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v2/update/utility-template/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
