import { FC, forwardRef, ReactNode } from 'react';
import { Box } from '@mui/material';

interface CircleBorderProps {
	children: ReactNode;
	borderColor?: string;
	bgColor?: string;
	width?: string | number;
	height?: string | number;
}

const CircleBorder = forwardRef<HTMLDivElement, CircleBorderProps>((props, ref) => {
	const { children, borderColor = '#232a31', bgColor, width = '40px', height = '40px' } = props;
	return (
		<Box
			ref={ref}
			id="circleBorder"
			sx={{
				border: `1px solid ${borderColor}`,
				width: width,
				height: height,
				borderRadius: '50%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				bgcolor: bgColor
			}}
		>
			{children}
		</Box>
	);
});

export default CircleBorder;
