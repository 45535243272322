/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum InvoiceMapping {
    COMPANY_NAME = 'company_name',
    UTILITY_NAME = 'utility_name',
    INVOICE_NUMBER = 'invoice_number',
    ACCOUNT_NUMBER = 'account_number',
    UTILITY_COMPANY_ADDRESS = 'utility_company_address',
    SERVICE_ADDRESS = 'service_address',
    INVOICE_DATE = 'invoice_date',
    INVOICE_PERIOD_DATE_RANGE = 'invoice_period_date_range',
    INVOICE_PERIOD_START_DATE = 'invoice_period_start_date',
    INVOICE_PERIOD_END_DATE = 'invoice_period_end_date',
    RESTATEMENT_PERIOD = 'restatement_period',
    SUMMARY_PERIOD = 'summary_period',
    INVOICE_DUE_DATE = 'invoice_due_date',
    INVOICE_TOTAL = 'invoice_total',
    TOTAL_AMOUNT_DUE = 'total_amount_due',
    COMMODITY_SUPPLIER_NAME = 'commodity_supplier_name',
    COMMODITY_DISTRIBUTOR_NAME = 'commodity_distributor_name',
    CONSUMPTION_UNITS = 'consumption_units',
    DIESEL_CONSUMPTION_USAGE = 'diesel_consumption_usage',
    ELECTRICITY_CONSUMPTION_USAGE = 'electricity_consumption_usage',
    NATURAL_GAS_CONSUMPTION_USAGE = 'natural_gas_consumption_usage',
    PROPANE_CONSUMPTION_USAGE = 'propane_consumption_usage',
    SEWER_CONSUMPTION_USAGE = 'sewer_consumption_usage',
    WASTE_CONSUMPTION_USAGE = 'waste_consumption_usage',
    WATER_CONSUMPTION_USAGE = 'water_consumption_usage',
    WATER_SEWER_CONSUMPTION_USAGE = 'water_sewer_consumption_usage',
    ADJUSTED_USAGE = 'adjusted_usage',
    ADJUSTMENT_FACTOR = 'adjustment_factor',
    MULTIPLIER = 'multiplier',
    POWER_FACTOR = 'power_factor',
    DEMAND_CONSUMPTION = 'demand_consumption',
    DEMAND_CONSUMPTION_UNITS = 'demand_consumption_units',
    LIGHTING_SERVICE_CONSUMPTION_USAGE = 'lighting_service_consumption_usage',
    FIRE_SERVICE_CONSUMPTION_USAGE = 'fire_service_consumption_usage',
    CAPACITY_CHARGE = 'capacity_charge',
    RATE_RIDER_CHARGE = 'rate_rider_charge',
    RATE_RIDER_CREDIT = 'rate_rider_credit',
    COST_ADJUSTMENT_CREDIT = 'cost_adjustment_credit',
    COST_ADJUSTMENT_CHARGE = 'cost_adjustment_charge',
    DIESEL_CONSUMPTION_CHARGE = 'diesel_consumption_charge',
    ELECTRICITY_CONSUMPTION_CHARGE = 'electricity_consumption_charge',
    NATURAL_GAS_CONSUMPTION_CHARGE = 'natural_gas_consumption_charge',
    PROPANE_CONSUMPTION_CHARGE = 'propane_consumption_charge',
    SEWER_CONSUMPTION_CHARGE = 'sewer_consumption_charge',
    WASTE_CONSUMPTION_CHARGE = 'waste_consumption_charge',
    WATER_CONSUMPTION_CHARGE = 'water_consumption_charge',
    LIGHTING_SERVICE_CONSUMPTION_CHARGE = 'lighting_service_consumption_charge',
    FIRE_SERVICE_CONSUMPTION_CHARGE = 'fire_service_consumption_charge',
    WATER_SEWER_CONSUMPTION_CHARGE = 'water_sewer_consumption_charge',
    WHOLESALE_MARKET_SERVICE_CHARGE = 'wholesale_market_service_charge',
    REGULATORY_CHARGE = 'regulatory_charge',
    STANDARD_SUPPLY_SERVICE_CHARGE = 'standard_supply_service_charge',
    CUSTOMER_CHARGE = 'customer_charge',
    RECORD_SUB_TOTAL = 'record_sub_total',
    RENEWABLE_POWER_CHARGE = 'renewable_power_charge',
    ON_PEAK_POWER_CHARGE = 'on_peak_power_charge',
    OFF_PEAK_POWER_CHARGE = 'off_peak_power_charge',
    MID_PEAK_POWER_CHARGE = 'mid_peak_power_charge',
    TRANSMISSION = 'transmission',
    TRANSPORTATION = 'transportation',
    DISTRIBUTION = 'distribution',
    DELIVERY = 'delivery',
    DEMAND_CHARGE = 'demand_charge',
    PENALTY = 'penalty',
    LATE_FEE = 'late_fee',
    BALANCE_FORWARD = 'balance_forward',
    BALANCE_FORWARD_CREDIT = 'balance_forward_credit',
    CREDITS = 'credits',
    GST = 'gst',
    LOCAL_TAX = 'local_tax',
    FEDERAL_TAX = 'federal_tax',
    HST = 'hst',
    PST = 'pst',
    QST = 'qst',
    CARBON_TAX = 'carbon_tax',
    OTHER_TAX = 'other_tax',
    STATE_TAX = 'state_tax',
}
