import { SetStateAction } from 'react';

import { AxiosError } from 'axios';
import { ApiError, ErrorSchema } from 'src/modules/generated-api-clients/no-sql';

import { Severity } from '@components';
import { MutationKey, QueryKey } from './enums';

export const logRequest = <T>(
	params: T,
	method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
	key: QueryKey | MutationKey,
	response: any
): void => {
	let logString = `${method} ${key}(`;

	// Iterating over all properties in the object
	for (const key in params) {
		if (typeof params === 'object') {
			if (params === null) {
				logString += 'null';
				break;
			}
			if (params.hasOwnProperty(key)) {
				const value = params[key];
				if (typeof value === 'object') {
					logString += `${key}=${JSON.stringify(value)}, `;
				}
				logString += `${key}=${value}, `;
			}
		}
	}

	// Removing the last comma and space, and adding the response
	logString = logString.replace(/, $/, '') + `) -> ${JSON.stringify(response)}`;

	// Logging the string
	console.log(logString);
};

export const logApiError = (error: ApiError): void => {
	console.error(
		`ApiError -> URL: ${error.url}\nSTATUS: ${error.status}\nMESSAGE: ${error.message}\nBODY: ${JSON.stringify(
			error.body
		)}`
	);
};

export const logAxiosError = (error: AxiosError): void => {
	console.error(`AxiosError -> ${JSON.stringify(error)}`);
};

export const logError = (error: unknown, message?: string): void => console.error(`${message || 'Error'}: ${error}`);

export const getErrorSeverity = (error: ErrorSchema): Severity => {
	switch (error.color) {
		case 'red':
			return 'error';
		case 'yellow':
			return 'warning';
		case 'green':
			return 'success';
		default:
			return 'info';
	}
};

// type-guard for ErrorSchema
export const isErrorSchema = (error: any): error is ErrorSchema => {
	return error && error.message !== undefined && error.error !== undefined && error.color !== undefined;
};

export const getErrorMessage = (
	error: ErrorSchema
): string => error.message || 'An error occurred';
export const getErrorFormatted = (
	error: ErrorSchema
): string => `Message: '${error.message}'; Error: '${error.error}'`;

/* Define types for applying React state setters for strings in the handleApiError helper function. */
type SetStateString = (value: SetStateAction<string>) => void;
type SetStateSeverity = (value: SetStateAction<Severity>) => void;

/**
 * Handles API errors and updates the message and severity state.
 *
 * @param invoiceError - The API error object.
 * @param setMessage - The state setter function for the error message.
 * @param setSeverity - The state setter function for the error severity.
 */
export const handleNoSqlApiError = (
	invoiceError: ApiError | null,
	setMessage: SetStateString,
	setSeverity: SetStateSeverity
) => {
	if (invoiceError === null) {
		setMessage('Error returned was null');
		setSeverity('error');
	} else {

		if (invoiceError.body) {
			console.warn('error: ', invoiceError.body as ErrorSchema);
			if (isErrorSchema(invoiceError.body)) {
				if (invoiceError.status === 422) {
					console.log('422: ', invoiceError as ApiError);
					setMessage(invoiceError.body.message as string);
					setSeverity(invoiceError.body.color as Severity);
				} else {
					// setMessage(getErrorFormatted(invoiceError.body as ErrorSchema)); // Comment this and uncomment the next line to use the UI-friendly error message
					setMessage(getErrorMessage(invoiceError.body as ErrorSchema));	// UI-friendly error message
					setSeverity(getErrorSeverity(invoiceError.body as ErrorSchema));
				}
			}
		} else {
			setMessage(`Failed to fetch invoice: ${invoiceError.message}`);
			setSeverity('error');
		}
	}
};
