export { default as PageTheme } from './PageTheme';
export { default as DialogTheme } from './DialogTheme';
export { default as DrawerTheme } from './DrawerTheme';

export const dataGridStyle = {
	'& .selectedRow': {
		backgroundColor: 'rgba(25, 118, 210, 0.08)',
		'&:hover': {
			backgroundColor: 'rgba(25, 118, 210, 0.12)'
		}
	},
	// Target the header cells
	'.MuiDataGrid-columnHeaders': {
		backgroundColor: '#204B73',
		color: '#FFF' // Change text color to white
	},
	'& .MuiDataGrid-menuIcon': {
		color: '#FFF' // Ensure header titles are also white
	},
	'& .MuiDataGrid-sortIcon': {
		color: '#FFF' // Change color of icons (like sort icon) in the header
	},
	'& .MuiDataGrid-cell:focus': {
		outline: 'none' // Removes the default focus outline
	},
	'& .MuiDataGrid-cell:focus-within': {
		outline: 'none' // Removes the focus outline when a child element of the cell is focused
	},
	// You may also need to adjust focus styles for other cell states if necessary
	'& .MuiDataGrid-cell:focus-within .MuiDataGrid-editCell': {
		outline: 'none'
	},
	'& .MuiDataGrid-footerContainer': {
		backgroundColor: '#204B73', // Your desired background color
		color: '#FFF', // Your desired text color,

		// For further customization, you can target child elements like:
		'& .MuiTablePagination-root': {
			color: '#FFF' // Text color for pagination
		},
		'& .MuiIconButton-root': {
			color: '#FFF' // Text color for pagination buttons
		}
	}
};

export const dataGridStylePaginationHidden = {
	'& .selectedRow': {
		backgroundColor: 'rgba(25, 118, 210, 0.15)',
		'&:hover': {
			backgroundColor: 'rgba(25, 118, 210, 0.2)'
		}
	},
	'.MuiDataGrid-columnHeaders': {
		backgroundColor: '#204B73',
		color: '#FFF' // Change text color to white
	},
	'& .MuiDataGrid-menuIcon': {
		color: '#FFF' // Ensure header titles are also white
	},
	'& .MuiDataGrid-sortIcon': {
		color: '#FFF' // Change color of icons (like sort icon) in the header
	},
	'& .MuiDataGrid-cell:focus': {
		outline: 'none' // Removes the default focus outline
	},
	'& .MuiDataGrid-cell:focus-within': {
		outline: 'none' // Removes the focus outline when a child element of the cell is focused
	},
	'& .MuiDataGrid-cell:focus-within .MuiDataGrid-editCell': {
		outline: 'none'
	},
	'& .MuiDataGrid-footerContainer': {
		display: 'none', // Hide the footer container including pagination
		backgroundColor: '#204B73', // Your desired background color
		color: '#FFF', // Your desired text color,

		// These child element styles may no longer be necessary since the footer is hidden
		'& .MuiTablePagination-root': {
			color: '#FFF' // Text color for pagination (not needed when footer is hidden)
		},
		'& .MuiIconButton-root': {
			color: '#FFF' // Text color for pagination buttons (not needed when footer is hidden)
		}
	}
};

export const SHAKE_ANIMATION_STRING = `
@keyframes bell-ring {
	0% {
		transform: translateX(0);
	}
	25% {
		transform: translateX(-2px) rotate(5deg);
	}
	50% {
		transform: translateX(2px) rotate(-5deg);
	}
	75% {
		transform: translateX(-2px) rotate(5deg);
	}
	100% {
		transform: translateX(0);
	}
}
`;
