import React, { useState, useEffect, useCallback } from 'react';
import {
	IconButton,
	TextField,
	Typography,
	Box,
	Dialog,
	DialogContent,
	Button,
	Tooltip,
	ToggleButton,
	ToggleButtonGroup,
	Paper,
	RadioGroup,
	Radio,
	FormControlLabel,
	Divider,
	Grid,
	FormControl
} from '@mui/material';

//mui x
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

//icons
import EventIcon from '@mui/icons-material/Event';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CloseIcon from '@mui/icons-material/Close';

//component
import SelectFormControl from '../controlled/SelectFormControl';

interface FrequencyPickerProps {
	size?: string;
	onFrequencyPattern: (pattern: string) => void;
}

const FrequencyPicker: React.FC<FrequencyPickerProps> = ({ size, onFrequencyPattern }) => {
	const [open, setOpen] = useState(false);
	const [nextDate, setNextDate] = useState<Date | null>();
	const [numOfMonth, setNumOfMonth] = useState('1');

	const handleOpenDialog = () => {
		setOpen(true);
	};

	const handleCloseDialog = () => {
		setOpen(false);
	};

	// ============================== TOGGLE BUTTON GROUP ==============================

	const [alignment, setAlignment] = useState('daily');
	const weeklyOptions = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
	const [weeklyOption, setWeeklyOption] = useState('');
	const daysOptions = Array.from({ length: 30 }, (_, i) => (i + 1).toString());
	const [dayOption, setDayOption] = useState('30');
	const definedOptions = [
		'Last day in this mid year',
		'Last day of this quarter',
		'Last day of this year',
		'Last day of this month',
		'Last day of this week'
	];
	const [definedOption, setDefinedOption] = useState('Last day in this mid year');
	const [selectedRadioDaily, setSelectedRadioDaily] = useState('none');
	const [selectedRadioMonthly, setSelectedRadioMonthly] = useState('twice_monthly');
	const [message, setMessage] = useState('A notification will be sent');
	const [frequencyMsg, setfrequencyMsg] = useState('');
	const [frequencyPattern, setFrequencyPattern] = useState('');

	const generateMessage = useCallback(() => {
		let frequencyMsg = '';

		// Add nextDate if it's provided

		switch (alignment) {
			case 'daily':
				frequencyMsg +=
					selectedRadioDaily === 'none'
						? ''
						: selectedRadioDaily === 'businessDay'
						? ' Every Business Day'
						: ' Daily';
				break;
			case 'weekly':
				frequencyMsg += ` Every ${weeklyOption} Week(s)`;
				break;
			case 'monthly':
				frequencyMsg +=
					selectedRadioMonthly === 'twice_monthly'
						? ' Twice Monthly'
						: ` Every ${numOfMonth} Month(s) on Day ${dayOption}`;
				break;
			case 'defined':
				frequencyMsg += ` ${definedOption.toUpperCase()}`;
				break;
			default:
				break;
		}

		setfrequencyMsg(frequencyMsg);

		let message = 'A notification will be sent';
		if (nextDate) {
			const dateStr = nextDate.toString();
			const extractedDateStr = dateStr.substring(4, 16);
			message += ` ${extractedDateStr}`;
		}

		if (selectedRadioDaily === 'none' && alignment === 'daily') {
			if (nextDate === undefined) {
				message = ' ';
			}
		} else {
		}

		setMessage(message + frequencyMsg);
	}, [
		alignment,
		dayOption,
		definedOption,
		nextDate,
		numOfMonth,
		selectedRadioDaily,
		selectedRadioMonthly,
		weeklyOption
	]);

	// Define labels for the ToggleButtons
	const labels = ['Daily', 'Weekly', 'Monthly', 'Defined'];

	const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
		setAlignment(newAlignment);
	};

	useEffect(() => {
		if (alignment) {
			generateMessage();
		}
	}, [
		alignment,
		nextDate,
		weeklyOption,
		selectedRadioDaily,
		selectedRadioMonthly,
		numOfMonth,
		dayOption,
		definedOption,
		generateMessage
	]);

	const handleDateChange = (date: Date | null) => {
		// Adjust the parameter type to Date | null
		setNextDate(date); // Always set the controlled value
	};

	const handleSaveButton = () => {
		const dateStr = nextDate?.toString();
		const extractedDateStr = dateStr?.substring(4, 16);
		const freqpattern = nextDate ? `${extractedDateStr} ${frequencyMsg || ''}` : frequencyMsg;
		setFrequencyPattern(freqpattern);
		onFrequencyPattern(freqpattern);
		setOpen(false);
	};

	const openDialogButtonId = 'openDialogButton';
	const closeDialogButtonId = 'closeDialogButton';
	return (
		<Box id="frequencyContainer">
			{size ? (
				<TextField
					label="Frequency Picker"
					variant="outlined"
					onClick={handleOpenDialog}
					InputProps={{
						endAdornment: (
							<IconButton onClick={handleOpenDialog} id={openDialogButtonId}>
								<EventIcon fontSize="small" />
							</IconButton>
						)
					}}
					size="small"
					fullWidth
					value={frequencyPattern}
				/>
			) : (
				<TextField
					id={openDialogButtonId}
					label="Frequency Picker"
					variant="outlined"
					onClick={handleOpenDialog}
					InputProps={{
						endAdornment: (
							<IconButton onClick={handleOpenDialog}>
								<EventIcon fontSize="small" />
							</IconButton>
						)
					}}
					fullWidth
					value={frequencyPattern}
				/>
			)}

			<Dialog open={open} maxWidth="xs" fullWidth onClose={handleCloseDialog} id="frequencyPickerDialog">
				<DialogContent>
					<Box>
						<Box display="flex" justifyContent="space-between" alignItems="center">
							<Typography id="frequencyPickerDialogTitle" variant="h4">
								Frequency
							</Typography>
							<Box>
								<Tooltip title="Reset Values" placement="top">
									<IconButton
										id={closeDialogButtonId}
										size="small"
										onClick={handleCloseDialog}
										sx={{ border: '1px solid #ccc' }}
									>
										<RestartAltIcon fontSize="small" />
									</IconButton>
								</Tooltip>
								<Tooltip title="Close" placement="top" sx={{ border: '1px solid #ccc', marginLeft: 1 }}>
									<IconButton size="small" onClick={handleCloseDialog} id={closeDialogButtonId}>
										<CloseIcon fontSize="small" />
									</IconButton>
								</Tooltip>
							</Box>
						</Box>
						<Box sx={{ p: 2 }}>
							<Typography id="frequencyPatternTitle" variant="h5" sx={{ mb: 2 }}>
								Frequency Pattern
							</Typography>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<FormControl id="frequencyPatternFormControl" fullWidth size="small">
									<DatePicker
										onChange={handleDateChange}
										label="Next Date"
										value={nextDate}
										views={['year', 'month', 'day']}
									/>
								</FormControl>
							</LocalizationProvider>

							<ToggleButtonGroup
								id="frequencyToggleButtonGroup"
								color="primary"
								value={alignment}
								exclusive
								onChange={handleChange}
								aria-label="Frequency"
								sx={{ borderRadius: '40px', mt: 2, height: '30px' }}
							>
								{labels.map(label => (
									<ToggleButton
										id={`frequencyToggleButton-${label}`}
										key={label}
										value={label.toLowerCase()}
										sx={{
											borderRadius: '40px'
											// "&.Mui-selected": {
											//   backgroundColor: "primary.main", // Change to your desired color
											//   color: "white",
											// },
										}}
									>
										{label}
									</ToggleButton>
								))}
							</ToggleButtonGroup>

							<Paper variant="outlined" sx={{ my: 2, p: 2 }}>
								{alignment === 'daily' && (
									<RadioGroup
										id="dailyRadioGroup"
										row
										value={selectedRadioDaily}
										aria-labelledby="daily-radio-button-group"
										name="daily-radio-button-group"
										onChange={event => setSelectedRadioDaily(event.target.value)}
									>
										<FormControlLabel
											id="noneRadioFormControlLabel"
											value="none"
											control={<Radio />}
											label="None"
										/>
										<FormControlLabel
											id="dailyRadioFormControlLabel"
											value="daily"
											control={<Radio />}
											label="Daily"
										/>
										<FormControlLabel
											id="businessDayRadioFormControlLabel"
											value="businessDay"
											control={<Radio />}
											label="Business Day"
										/>
									</RadioGroup>
								)}
								{alignment === 'weekly' && (
									<SelectFormControl
										id="weeklySelectFormControl"
										label="Every Week(s)"
										value={weeklyOption}
										onChange={value => setWeeklyOption(value)}
										options={weeklyOptions}
										size="small"
									/>
								)}
								{alignment === 'monthly' && (
									<RadioGroup
										id="monthlyRadioGroup"
										value={selectedRadioMonthly}
										aria-labelledby="monthly-radio-buttons-group"
										name="monthly-radio-buttons-group"
										onChange={event => setSelectedRadioMonthly(event.target.value)}
									>
										<FormControlLabel
											id="twiceMonthlyRadioFormControlLabel"
											value="twice_monthly"
											control={<Radio />}
											label="Twice Monthly"
										/>
										<Divider sx={{ my: 2 }} />
										<FormControlLabel
											id="everyRadioFormControlLabel"
											value="every"
											control={<Radio />}
											label={
												<Grid container spacing={1} display="flex" alignItems="center">
													<Grid item xs={2}>
														Every
													</Grid>
													<Grid item xs={2}>
														<TextField
															id="numOfMonth"
															value={numOfMonth}
															size="small"
															variant="standard"
															sx={{ width: '100%' }}
															onChange={event => setNumOfMonth(event.target.value)}
														/>
													</Grid>
													<Grid item xs={4.5}>
														<Typography id="monthsOnLabel" variant="body1">
															Month(s) On
														</Typography>
													</Grid>
													<Grid item xs={3.5}>
														<SelectFormControl
															id="daySelectFormControl"
															label="Day"
															value={dayOption}
															onChange={value => setDayOption(value)}
															options={daysOptions}
															size="small"
														/>
													</Grid>
												</Grid>
											}
										/>
									</RadioGroup>
								)}
								{alignment === 'defined' && (
									<SelectFormControl
										id="definedSelectFormControl"
										label="Defined"
										value={definedOption}
										onChange={value => setDefinedOption(value)}
										options={definedOptions}
										size="small"
									/>
								)}
							</Paper>
							<Paper variant="outlined" sx={{ p: 2, borderLeft: '4px solid #1D4A74' }}>
								<Grid container display="flex" alignItems="center">
									<Grid item xs={10} id="frequencyMessageGrid">
										<Typography id="frequencyMessage" variant="body2" sx={{ width: '80%' }}>
											{message}
										</Typography>
									</Grid>

									<Grid item xs={2}>
										<Button
											variant="contained"
											fullWidth
											onClick={handleSaveButton}
											id="frequencySaveButton"
										>
											Save
										</Button>
									</Grid>
								</Grid>
							</Paper>
						</Box>
					</Box>
				</DialogContent>
			</Dialog>
		</Box>
	);
};

export default FrequencyPicker;
