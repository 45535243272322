import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

interface SearchFieldProps {
	value: string;
	onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
	label: string;
}

const SearchField: React.FC<SearchFieldProps> = ({ value, onChange, label }) => {
	return (
		<TextField
			id={`searchField-${label}`}
			label={label}
			variant="outlined"
			//   size="small"
			value={value}
			onChange={onChange}
			//   sx={{ width: "50%" }}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<SearchIcon fontSize="small" color="action" />
					</InputAdornment>
				)
			}}
			fullWidth
		/>
	);
};

export default SearchField;
