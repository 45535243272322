import React from 'react';
import { FormControl, Grid } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { ThemeProvider } from '@mui/material/styles';
import PageTheme from 'src/styles/PageTheme';

export type DateRange = { start: dayjs.Dayjs; end: dayjs.Dayjs };

interface MonthRangePickerProps {
	startDate: dayjs.Dayjs | null;
	endDate: dayjs.Dayjs | null;
	labels?: { start: string; end: string };
	onChangeStartDate: (newValue: dayjs.Dayjs | null) => void;
	onChangeEndDate: (newValue: dayjs.Dayjs | null) => void;
	required?: boolean;
}

/**
 * This component has 2 DatePickers representing a range and is semi-controlled.
 * It must be semi-controlled, for example, if the user selects a start date that is after the end date.
 * The parent component should pass in the start and end dates as props.
 * The parent component should also pass in onChangeStartDate and onChangeEndDate as props.
 * The parent component should update the start and end dates when the user changes the dates.
 *
 * @param props DateRangePickerProps object
 * @returns A MonthRangePicker component
 */
const MonthRangePicker: React.FC<MonthRangePickerProps> = ({
	startDate,
	endDate,
	labels = { start: 'Start', end: 'End' },
	onChangeStartDate,
	onChangeEndDate,
	required = false
}) => {
	const handleStartDateChange = (newValue: dayjs.Dayjs | null) => {
		// Update start date to be first day of month
		if (newValue) {
			const newStartDate = newValue.startOf('month');
			onChangeStartDate(newStartDate);
		}
	};

	const handleEndDateChange = (newValue: dayjs.Dayjs | null) => {
		// Update end date to be last day of month
		if (newValue) {
			const newEndDate = newValue.endOf('month');
			onChangeEndDate(newEndDate);
		}
	};

	return (
		<ThemeProvider theme={PageTheme}>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<Grid container spacing={2} id="monthRangePickerGrid">
					<Grid item xs={6} id="monthRangePickerStartDateGrid">
						<FormControl id="monthRangePickerStartDataFormControl" fullWidth required={required}>
							<DatePicker
								value={startDate}
								onChange={handleStartDateChange}
								label={labels.start}
								views={['month', 'year']}
								sx={{ fontSize: '10px' }}
							/>
						</FormControl>
					</Grid>
					<Grid id="monthRangePickerEndDateGrid" item xs={6}>
						<FormControl id="monthRangePickerEndDateFormControl" fullWidth required={required}>
							<DatePicker
								value={endDate}
								onChange={handleEndDateChange}
								label={labels.end}
								views={['month', 'year']}
								sx={{ fontSize: '10px' }}
							/>
						</FormControl>
					</Grid>
				</Grid>
			</LocalizationProvider>
		</ThemeProvider>
	);
};

export default MonthRangePicker;
