import { FunctionComponent, useState, Dispatch, SetStateAction } from 'react';

// Third party modules
import { Link, LinkProps } from 'react-router-dom';
import { ListItemButton, ListItemIcon, ListItemText, Drawer, Box, List, useTheme } from '@mui/material';

// Local modules
import { Route, constructLinkProps } from 'src/modules/router';
import {
	HomeIcon,
	AlertsIcon,
	InvoicesIcon,
	PromptIcon,
	UtilitiesIcon,
	MeterIcon,
	AdminSettingIcon,
	EnvirallyIcon,
	EnvirallyLogo,
	ValidationIcon,
} from '@components';
import { COLLAPSED_DRAWER_WIDTH, EXPANDED_DRAWER_WIDTH } from './constants';

type ListItem = {
	text: string;
	icon: JSX.Element;
	linkProps: LinkProps;
};

const mainListItems: ListItem[] = [
	{
		text: Route.Clients,
		icon: <HomeIcon color="white" />,
		linkProps: constructLinkProps<Route.Clients>(Route.Clients, null, {}, {}, undefined)
	},
	{
		text: Route.InvoicesAlerts,
		icon: <AlertsIcon color="white" />,
		linkProps: constructLinkProps<Route.InvoicesAlerts>(
			Route.InvoicesAlerts,
			undefined,
			{ id: 'optionalId' },
			{},
			undefined
		)
	},
	{
		text: Route.Invoices,
		icon: <InvoicesIcon color="white" />,
		linkProps: constructLinkProps<Route.Invoices>(Route.Invoices, undefined, {}, {}, undefined)
	},
	{
		text: Route.InvoicesErrorIntake,
		icon: <ValidationIcon color="white" />,
		linkProps: constructLinkProps<Route.InvoicesErrorIntake>(Route.InvoicesErrorIntake, undefined, {}, {}, undefined)
	}
];

const secondaryListItems = [
	{
		text: 'Manage Utilities',
		icon: <UtilitiesIcon color="white" />,
		linkProps: constructLinkProps<Route.Utilities>(Route.Utilities, undefined, {}, { isAdding: false }, undefined)
	},
	{
		text: Route.ReferenceData,
		icon: <MeterIcon color="white" />,
		linkProps: constructLinkProps<Route.ReferenceData>(Route.ReferenceData, undefined, {}, {}, undefined)
	},
	{
		text: Route.Settings,
		icon: <AdminSettingIcon color="white" />,
		linkProps: constructLinkProps<Route.Settings>(Route.Settings, undefined, { id: 's' }, {}, undefined)
	}
];

const NavigationDrawer: FunctionComponent = () => {
	const theme = useTheme();
	const [open, setOpen] = useState(false);

	return (
		<Drawer
			PaperProps={{
				sx: {
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					width: open ? EXPANDED_DRAWER_WIDTH : COLLAPSED_DRAWER_WIDTH,
					backgroundColor: '#1D4A74',
					color: 'white',
					fontSize: '0.5rem',
					transition: theme.transitions.create('width', {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.enteringScreen
					})
					// ...(!open && {
					// 	[theme.breakpoints.up('sm')]: {
					// 		width: COLLAPSED_DRAWER_WIDTH
					// 	}
					// })
				},
				onMouseOver: () => setOpen(true),
				onMouseOut: () => setOpen(false)
			}}
			variant="permanent"
			open={open}
		>
			{open ? (
				<Box
					sx={{
						bgcolor: '#FFFFFF',
						height: '120px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<EnvirallyLogo />
				</Box>
			) : (
				<Box
					sx={{
						height: '120px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'flex-start'
					}}
				>
					<Box
						sx={{
							height: '70px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<EnvirallyIcon />
					</Box>
				</Box>
			)}
			<Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
				<List key="main-nav" component="nav" sx={{ px: 1, mt: '10%' }}>
					{mainListItems.map((item, index) => (
						<ListItemButton
							key={index}
							id={`primaryNavListItem-${item.text.replace(/\s+/g, '')}`}
							component={Link}
							to={item.linkProps.to}
						>
							<ListItemIcon>{item.icon}</ListItemIcon>
							<ListItemText primary={item.text} />
						</ListItemButton>
					))}
				</List>
				<List key="secondary-nav" component="nav" sx={{ px: 1, mb: '10%' }}>
					{secondaryListItems.map((item, index) => (
						<ListItemButton
							key={index}
							id={`secondaryNavListItem-${item.text.replace(/\s+/g, '')}`}
							component={Link}
							to={item.linkProps.to}
						>
							<ListItemIcon>{item.icon}</ListItemIcon>
							<ListItemText primary={item.text} />
						</ListItemButton>
					))}
				</List>
			</Box>
		</Drawer>
	);
};

export default NavigationDrawer;
