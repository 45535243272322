import { createTheme } from '@mui/material/styles';

import { Color } from './enums';
import Inter from '../assets/fonts/Inter-Regular.woff';

const DrawerTheme = createTheme({
	palette: {
		primary: {
			main: Color.Primary
		},
		secondary: {
			main: Color.Secondary
		}
	},
	typography: {
		fontFamily: Inter,
		fontSize: 12,

		h1: {
			fontWeight: 'bold',
			fontSize: '2.5rem' // 40px
		},

		h2: {
			fontWeight: 'bold',
			fontSize: '2rem' // 32px
		},

		h3: {
			fontWeight: '600', // Semi-bold
			fontSize: '1.5rem' // 24px
		},

		h4: {
			fontWeight: '600', // Semi-bold
			fontSize: '1rem' // 20px
		},

		body2: {
			fontWeight: 'normal', // Regular
			fontSize: '1rem' // 16px
		},

		h5: {
			fontWeight: '600', // Regular
			fontSize: '0.85rem' // 12px
		},

		h6: {
			fontWeight: 'normal', // Regular
			fontSize: '0.7rem', // 10px
			textTransform: 'none'
		}
	}
});

export default DrawerTheme;
