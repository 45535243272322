// SingleSelect.tsx
import React from 'react';
import { Typography, Box } from '@mui/material';

interface HeaderBoxProps {
    title: string;
    field: string | number; 
    Icon?: React.ElementType

}
const HeaderBox: React.FC<HeaderBoxProps> = ({ 
    title,
    field,
    Icon
  }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

    {Icon && <Icon fontSize="small" sx={{ mr: '10px', ml: '5px' }} />} {/* Conditionally render the Icon if provided */}

    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h5" color="#B3B3B3">
        {title}
      </Typography>
      <Typography variant="h4">{field}</Typography>
    </Box>
  </Box>
  );
};

export default HeaderBox;
