/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type getNaicsCodesResponse = {
    status: getNaicsCodesResponse.status;
    body: Array<{
        naics_id: number;
        naics_sector: string;
    }>;
    message: string;
};

export namespace getNaicsCodesResponse {
    export enum status {
        SUCCESS = 'Success',
    }
}
