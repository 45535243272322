import { Suspense, FunctionComponent } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

// Local modules
import { routes, parsePathnameFromRoutePath, Route as RouteEnum, constructLinkProps } from 'src/modules/router';
import { LoadingBackdrop } from '@components';

const AppRoutes: FunctionComponent = () => {
	return (
		<Routes>
			{Object.values(routes).map((route, key) => {
				return (
					<Route
						key={key}
						path={parsePathnameFromRoutePath(route.route)}
						element={
							<Suspense fallback={<LoadingBackdrop />}>
								<route.component />
							</Suspense>
						}
					/>
				);
			})}

			{/* Redirect to the Home (Clients) page if the route does not exist without affecting go-back functionality */}
			<Route
				path=""
				element={
					<Navigate
						to={
							constructLinkProps<RouteEnum.Clients>(
								RouteEnum.Clients,
								undefined,
								{},
								{ isAdding: false, invoiceUrl: undefined },
								undefined
							).to
						}
					/>
				}
			/>
		</Routes>
	);
};

export default AppRoutes;
