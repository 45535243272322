import { TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';

// const useStyles = makeStyles((theme) => ({
//     root: {
//         '& > *': {
//             margin: '2px',
//             width: '25ch',
//         },
//     },
//     form: {
//         marginTop: 20,
//     },
//     textField: {
//         marginRight: 20,
//         minWidth: 100,
//     },
//     rightAlignItem: {//btn action alignment
//         marginLeft: "auto"
//     },
//     parentFlexRight: {//btn action alignment
//         display: "flex",
//         justifyContent: "flex-end"
//     },
//     parentFlexSplit: {//btn action alignment
//         display: "flex",
//         justifyContent: "space-between"
//     },
//     centerCard: {//btn action alignment
//         display: "flex",
//         justifyContent: "space-around"
//     },
// }));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#9dcbec',
        // backgroundColor: theme.palette.common.black,
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
export default StyledTableCell;