/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum AlertStatusEnum {
    OPEN = 'OPEN',
    RESOLVED = 'RESOLVED',
    IN_PROGRESS = 'IN_PROGRESS',
}
