import React, { useState } from 'react';
import { Box, Paper } from '@mui/material';
import { Divider } from '@mui/material';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import { SelectionMode } from '@react-pdf-viewer/selection-mode';
import packageJson from '../../../../../package.json';
import { pdfjs } from 'react-pdf';
import { ToolbarSlot } from '@react-pdf-viewer/toolbar';
import { Toast, Severity } from 'src/modules/components';
import { MoreActionsPopover } from '@react-pdf-viewer/toolbar';


/**
 * VerticalToolbarV2 is a component that renders a vertical toolbar for PDF navigation and interaction.
 *
 * @param {object} props - The properties object.
 * @param {ToolbarSlot} props.toolbarSlot - The slot containing toolbar items provided by the PDF viewer.
 * @param {(mode: SelectionMode) => void} props.onSelectionModeChange - Callback function to handle selection mode changes.
 * @returns {JSX.Element} The rendered vertical toolbar component.
 */
const VerticalToolbarV2 = ({
	toolbarSlot,
	onSelectionModeChange
}: {
	toolbarSlot: ToolbarSlot;
	onSelectionModeChange: (mode: SelectionMode) => void;
}) => {
	const handleSelectionModeChange = (mode: SelectionMode) => {
		onSelectionModeChange(mode); // Call the prop function
	};
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				height: '100%'
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				{toolbarSlot.ShowSearchPopover({})}
			</Box>
			<Divider sx={{ width: '100%', m: '20%', p: 0 }} />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'space-evenly',
					flex: 1,
				}}
			>
				<MoreActionsPopover toolbarSlot={toolbarSlot} />
			</Box>
			<Divider sx={{ width: '100%', m: '20%', p: 0 }} />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'space-evenly',
					flex: 1
				}}
			>
				{toolbarSlot.GoToPreviousPage({})}
				{toolbarSlot.CurrentPageLabel({})}
				{toolbarSlot.GoToNextPage({})}
			</Box>
			<Divider sx={{ width: '100%', m: '20%', p: 0 }} />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'space-evenly',
					flex: 1
				}}
			>
				{toolbarSlot.ZoomIn({})}
				{toolbarSlot.CurrentScale({})}
				{toolbarSlot.ZoomOut({})}
			</Box>
			<Divider sx={{ width: '100%', m: '20%', p: 0 }} />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'space-evenly',
					flex: 1
				}}
			>
				<div style={{ marginBottom: '5%' }} onClick={() => handleSelectionModeChange(SelectionMode.Text)}>
					{toolbarSlot.SwitchSelectionMode({ mode: SelectionMode.Text })}
				</div>
				<div onClick={() => handleSelectionModeChange(SelectionMode.Hand)}>
					{toolbarSlot.SwitchSelectionMode({ mode: SelectionMode.Hand })}
				</div>
			</Box>
			<Divider sx={{ width: '100%', m: '20%', p: 0 }} />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'space-evenly',
					flex: 1
				}}
			>
				{toolbarSlot.Print({})}
				{toolbarSlot.Download({})}
			</Box>
		</Box>
	);
};

interface Props {
	/** The URL of the PDF to be viewed, encoded as a Base64 string. */
	invoiceUrl: string;
	/** Custom styles for the toolbar container. */
	toolbarStyle?: React.CSSProperties;
	/** Custom styles for the PDF viewer content area. */
	contentStyle?: React.CSSProperties;
}

/**
 * PdfViewerV2 is a component for displaying a PDF document with a customizable toolbar.
 * It allows for basic PDF interactions like zooming, page navigation, selection mode toggling, and more.
 *
 * @param {Props} props - The properties object.
 * @param {string} props.invoiceUrl - The URL of the PDF to be viewed, encoded as a Base64 string.
 * @param {React.CSSProperties} [props.toolbarStyle] - Custom styles for the toolbar container.
 * @param {React.CSSProperties} [props.contentStyle] - Custom styles for the PDF viewer content area.
 * @returns {JSX.Element} The rendered PDF viewer component.
 */
const PdfViewerV2: React.FC<Props> = ({ invoiceUrl, contentStyle, toolbarStyle }) => {
	const toolbarPluginInstance = toolbarPlugin();
	const { Toolbar } = toolbarPluginInstance;
	const [selectionMode, setSelectionMode] = useState(SelectionMode.Text);
	const [isToastOpen, setIsToastOpen] = useState(false);
	const [toastMessage, setToastMessage] = useState('');
	const [toastSeverity, setToastSeverity] = useState<Severity>('error');

	const base64toBlob = (data: string) => {
		// Cut the prefix `data:application/pdf;base64` from the raw base 64
		// const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);
		const bytes = atob(data);
		let length = bytes.length;
		let out = new Uint8Array(length);

		while (length--) {
			out[length] = bytes.charCodeAt(length);
		}
		return new Blob([out], { type: 'application/pdf' });
	};
	const handleSelectionModeChange = (mode: SelectionMode) => {
		setSelectionMode(mode); // Update state to trigger re-render
	};
	// NOTE: see README for more info on how pdfjs-dist version is determined
	const pdfjsVersion = packageJson.dependencies['pdfjs-dist'].replace('^', '');
	if (pdfjsVersion !== pdfjs.version) {
		console.warn(`pdfjsVersion: ${pdfjsVersion} !== pdfjs.version: ${pdfjs.version}:: this may cause pdf viewer to fail.`);
	}

	const blob = base64toBlob(invoiceUrl);
	const url = URL.createObjectURL(blob);
	return (
		<>
			<Toast
				message={toastMessage}
				severity={toastSeverity}
				isOpen={isToastOpen}
				onClose={() => setIsToastOpen(false)}
				id="toast-pdf-viewer"
			/>
			{/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js"> */}
			<Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}>
				<Paper
					sx={{
						display: 'flex',
						flexDirection: 'row',
						padding: '1%',
						paddingLeft: 0,
						backgroundColor: '#F5F5F5',
						borderColor: '#E5E5E5',
						borderWidth: '1px',
						borderStyle: 'solid'
					}}
				>
					<Box
						sx={{
							...toolbarStyle,
							padding: '1%',
							backgroundColor: '#F5F5F5'
						}}
					>
						<Toolbar>
							{toolbarSlot => (
								<VerticalToolbarV2
									toolbarSlot={toolbarSlot}
									onSelectionModeChange={handleSelectionModeChange}
								/>
							)}
						</Toolbar>
					</Box>
					<Box
						id={`pdfViewer-${url}`}
						className="rpv-core__viewer"
						style={{
							...contentStyle,
							minHeight: '400px',
							flex: 1,
							borderColor: '#E5E5E5',
							borderWidth: '1px',
							borderStyle: 'solid'
						}}
					>
						{<Viewer fileUrl={url} plugins={[toolbarPluginInstance]} />}
					</Box>
				</Paper>
			</Worker>
		</>
	);
};
export default PdfViewerV2